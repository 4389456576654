import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { projectUrl } from '../../../Helpers';

import './circular-item.scss';

/**
 * Circular item
 *
 * @class CircularItem
 * @extends {Component}
 */
class CircularItem extends Component {
  /**
   * on data
   *
   * @param {*} data
   * @returns
   * @memberof CircularItem
   */
  onData(data) {
    if (!data) return false;

    const date = data.split('-');
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    return <p className="date">
        <span className="day">{date[2]}</span>
        <span className="month">{months[parseInt(date[1]) - 1]}</span>
        <span className="year">{date[0]}</span>
      </p>;
  }

  buildDownloadURL() {
    if (this.props.item.download.url)
      return projectUrl + this.props.item.download.url;
    return '#';
  }

  /**
   * render
   *
   * @returns
   * @memberof CircularItem
   */
  render() {
    return (
      <Fragment>
        {this.props.item &&
          <Fragment>
            <div className="data">{this.onData(this.props.item.data)}</div>

            <div className="info">
              <p className="title">{this.props.item.titulo}</p>
              <p className="description">{this.props.item.resumo}</p>
            </div>

            {this.props.item.download &&
              <a className="download" href={this.buildDownloadURL()} download>
                <span className="icon icon-download"></span>

                <span className="info">
                  <span className="text">fazer download</span>
                  <span className="size">{this.props.item.download.size}</span>
                  <span className="type">{this.props.item.download.extension}</span>
                </span>
                
                <img className="bg" src={process.env.PUBLIC_URL + '/images/bg-circular.svg'} alt="bg" />
              </a>}

            {this.props.item.link &&
              <Link to={this.props.item.link} className="link">
                <span className="icon icon-plus"></span>
                <span className="text">veja mais</span>
              </Link>}
            </Fragment>}
      </Fragment>
    )
  }
}

CircularItem.propTypes = {
  item: PropTypes.any,
}

export default CircularItem;