import React, { Component } from 'react';
import PropTypes from 'prop-types';


import dateFns from 'date-fns';
import axios from 'axios';

import { baseUrl, projectUrl } from './../../../Helpers';

import './social-networks-circulars.scss';

/**
 * Social Networks Circulars
 *
 * @class SocialNetworksCirculars
 * @extends {Component}
 */
class SocialNetworksCirculars extends Component {
  /**
   * Creates an instance of SocialNetworksCirculars.
   * 
   * @param {*} props
   * @memberof SocialNetworksCirculars
   */
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  /**
   * component did mount
   *
   * @memberof SocialNetworksCirculars
   */
  componentDidMount() {
    axios({
      url: baseUrl + 'circulares/?limit=4',
      method: 'GET',
    }).then(res => {
      if (res.data) {
        this.setState({
          items: res.data.results,
        });
      }
    }).catch(err => {
      console.error(err);
    });
  }

  /**
   * get data
   *
   * @returns
   * @memberof SliderBasicItem
   */
  getData(dateItem) {
    const date = dateFns.parse(dateItem);
    const format = 'DD.MM.YY';

    return dateFns.format(date, format);
  }

  componentWillUnmount() {
    return true;
  }

  // should
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.items !== this.state.items) {
      return true;
    }

    return false;
  }

  /**
   * render
   *
   * @returns
   * @memberof SocialNetworksCirculars
   */
  render() {
    return (
      <div className="social-networks--circulars">
        <h2 className="title">Circulares</h2>

        <ul className="circulars">
          {Array.isArray(this.state.items) && this.state.items.map((item, index) => 
            <li className="circulars--item" key={index} data-type={item.segmento}>
              <p className="info">
                <span className="date">{this.getData(item.data)}</span>
                <span className="description">{item.titulo}</span>
              </p>
              <a className="link icon icon-download" href={projectUrl + item.download.url} download>fazer download</a>
            </li>)}
        </ul>
      </div>
    )
  }
}

SocialNetworksCirculars.propTypes = {
  any: PropTypes.any,
}

export default SocialNetworksCirculars;