import React from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './quick-access.scss';

const QuickAccess = (props:any) => {
  const settings = {
    speed: 500,
    infinite: false,
    slidesToShow: 8.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  return (
    <div className="quick-access">
      <h3 className="quick-access-title">Acesso Rápido</h3>

      <Slider {...settings}>
        {props.lista.map((listaItem:any, index:any) => {
          if(listaItem.tipo_link === "externo"){
            return (
              <a href={listaItem.link_externo} target="_blank" className='slick-slide-link' key={index} rel="noopener noreferrer">
                <div className='slick-slide-link-image'>
                  <img src={process.env.REACT_APP_API_URL + listaItem.imagem.original.src} alt={listaItem.imagem.original.alt} />
                </div>

                <p>{listaItem.titulo}</p>
              </a>
            )
          }

          else {
            return (
              <Link to={props.lista[index].link_interno.url.slice(0, -1)} className='slick-slide-link' key={index}>
                <div className='slick-slide-link-image'>
                  <img src={process.env.REACT_APP_API_URL + listaItem.imagem.original.src} alt={listaItem.imagem.original.alt} />
                </div>

                <p>{listaItem.titulo}</p>
              </Link>
            )
          }
        })}
      </Slider>
    </div>
  )
}

export default QuickAccess;