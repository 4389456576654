import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, } from 'react-router-dom';

import Content from '../Content';
import NotFound from '../../components/NotFound';
import Search from '../../components/Search';

import './main.css';

/**
 * Main
 *
 * @class Main
 * @extends {Component}
 */
class Main extends Component {
  /**
   * Creates an instance of Main.
   * @param {*} props
   * @memberof Main
   */
  constructor(props) {
    super(props);

    this.state = {
      route: {},
      currentRoute: {},
    };
  }

  /**
   * component did update
   *
   * @param {*} prevProps
   * @memberof Main
   */
  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location || prevProps.routes !== this.props.routes) {
      this.getComponent();
    }
  }

  /**
   * find route
   * @param {*} location 
   * @param {*} routes 
   */
  findRoute(location, routes) {
    if (!Array.isArray(routes)) return false;

    return routes.find(item => {
      if (item.meta.slug === location.pathname.substring(1)) {
        this.setState({
          currentRoute: item,
        });

        return true;
      }

      return false;
    });
  }

  /**
   * get component
   *
   * @memberof Main
   */
  getComponent() {
    if (this.props.location.pathname === '/') {
      this.props.history.replace('/home');
    } else {
      this.setState({
        route: this.findRoute(this.props.location, this.props.routes.items)
      }, () => {
        if (this.state.currentRoute) {
          this.props.onGetPage(this.state.currentRoute);
        }
      });
    }
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof Main
   */
  render() {
    return (
      <div className="main">
        {Array.isArray(this.props.routes.items) &&
          <Route render={() => (
            <Switch>
              <Route path='/area-restrita' exact render={()=> {
                window.location.replace('http://antigo.csa.com.br');
                return null;
              }} />

              {this.props.routes.items.map((route, index) => {
                // valid token
                return <Route
                  exact
                  key={index}
                  path={`/${route.meta.slug}`}
                  render={() => (<Content
                    resize={this.props.resize}
                    page={this.props.page}
                    currentRoute={this.state.currentRoute} />)} />;
                  // {logged === false ? <Redirect to="/" /> : <Route />} 
                })
              }

              <Route path="/search" render={() => (<Search {...this.props} />)} />
              <Route path="*" component={NotFound} />
            </Switch>
          )} />}
      </div>
    )
  }
}

Main.propTypes = {
  location: PropTypes.any,
  onGetPage: PropTypes.func,
  resize: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  routes: PropTypes.any.isRequired,
}

export default withRouter(Main);