import React from 'react';
import { GalleryImageProps } from 'components/Gallery/types';

import { image } from 'Helpers';

import './fullscreen-gallery.scss';

type FullscreenGalleryProps = {
  images: Array<GalleryImageProps>;
  index: number;
  onClose: () => void;
  onChange?: (index: number) => void;
};

function FullscreenGallery(props: FullscreenGalleryProps) {
  return (
    <div className='fullscreen-gallery'>
      <button onClick={ props.onClose } className='fullscreen-gallery--close'>
        <i className='icon icon-plus'></i>
      </button>

      <div className='fullscreen-gallery--images'>
        { image(props.images[props.index].legenda, props.images[props.index].foto) }
      </div>

      <div>
        <ul className='fullscreen-gallery--thumbnails'>
          {
            props.images.map((item, index) => {
              return (
                <li key={ index }
                  className={`fullscreen-gallery--thumbnails--item ${ props.index === index ? 'active' : '' }`}
                  onClick={ () => props.onChange && props.onChange(index) }
                >
                  { image(item.legenda, item.foto) }
                </li>
              );
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default FullscreenGallery;