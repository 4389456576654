import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { image } from './../../../Helpers';

import './type-eight.css';

/**
 * Type eight
 *
 * @class TypeEight
 * @extends {Component}
 */
class TypeEight extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof TypeEight
   */
  render() {
    return (
      <div className="history-type-eight x-large">
        <div className="wrapper">
          {image(this.props.titulo, this.props.imagem)}

          {this.props.getInfo &&
            <div className="content">
              {this.props.getInfo(this.props.titulo, this.props.descricao)}
            </div>}
        </div>
      </div>
    )
  }
}

TypeEight.propTypes = {
  titulo: PropTypes.string.isRequired,
  imagem: PropTypes.object.isRequired,
  getInfo: PropTypes.func.isRequired,
  descricao: PropTypes.string,
}

export default TypeEight;