import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import { baseUrl } from './../../Helpers';

import SocialNetworksCirculars from './SocialNetworksCirculars';

import './social-networks.scss';

/**
 * Social Networks
 *
 * @class SocialNetworks
 * @extends {Component}
 */
class SocialNetworks extends Component {
  /**
   * Creates an instance of SocialNetworks.
   * 
   * @param {*} props
   * @memberof SocialNetworks
   */
  constructor(props) {
    super(props)

    this.state = {
      count: 0,
      items: [],
      loading: true,
      countItems: 10,
      next: '',
    };

    this.onCallback = this.onCallback.bind(this);
  }

  /**
   * component did mount
   *
   * @memberof SocialNetworks
   */
  componentDidMount() {
    this.mounted = true;
    return this.getItems(`${baseUrl}redesocial/post/?limit=20&offset=0`);
  }

  /**
   * component will unmount
   *
   * @memberof SocialNetworks
   */
  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * get items
   *
   * @param {*} url
   * @returns
   * @memberof SocialNetworks
   */
  getItems(url) {
    if (!url) return false;

    axios({
      url: url,
      method: 'GET',
    }).then(res => {
      if (res.data && this.mounted) {
        this.setState({
          count: res.data.count,
          items: [...this.state.items, ...res.data.results],
          next: res.data.next,
          loading: false,
        });
      }
    });
  }

  /**
   * on callback
   *
   * @param {*} check
   * @memberof SocialNetworks
   */
  onCallback(check) {
    if (check === true) {
      if (!this.state.next === false && this.state.next !== '') {
        this.setState({
          loading: true,
        }, () => this.getItems(this.state.next));
      }
    }
  }

  /**
   * render
   *
   * @returns
   * @memberof SocialNetworks
   */
  render() {
    return (
      <div className="social-networks">
        <SocialNetworksCirculars />

        <div className="social-networks-social-media">
            <h2>Redes Sociais</h2>

            <div className='social-networks-social-media-links'>
              <span>Acompanhe o CSA-Leblon nas redes sociais</span>

              <a href='https://www.tiktok.com/@csaleblonoficial'><img src='/images/icons/tiktok.svg' alt="" />@csaleblonoficial</a>
              <a href='https://www.facebook.com/csaleblonoficial'><img src='/images/icons/facebook2.svg' alt="" />@csaleblonoficial</a>
              <a href='https://www.instagram.com/csaleblonoficial'><img src='/images/icons/instagram.svg' alt="" />@csaleblonoficial</a>
              <a href='https://www.youtube.com/channel/UC2aK9-YP-8BHAFADvLLI_Hw/feed'><img src='/images/icons/youtube.svg' alt="" />@csaleblonoficial</a>
            </div>
        </div>
      </div>
    )
  }
}

SocialNetworks.propTypes = {
  any: PropTypes.any,
}

export default SocialNetworks;