import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';

import './list-collapse.scss';

class ListCollapse extends Component {
  /**
   * component did mount
   *
   * @memberof ListCollapse
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);
  }

  /**
   * toggle
   *
   * @param {*} index
   * @returns
   * @memberof ListCollapse
   */
  toggle(index) {
    if (Number.isInteger(index) === false) return false;

    const item = this.element.querySelectorAll(`.list-collapse--item:nth-child(${index + 1})`)[0];

    if (item instanceof Object) {
      item.setAttribute('data-open', item.getAttribute('data-open') === 'false');
    }
  }

  /**
   * render
   *
   * @returns
   * @memberof ListCollapse
   */
  render() {
    return (
      <div className="list-collapse">
        <div className="wrapper">
          <h1 className="title-main default">
            {this.props.titulo && <span>{this.props.titulo}</span>}
          </h1>

          {this.props.lista &&
            <ul className="list-collapse--list">
              {this.props.lista.map((item, index) => 
                <li className="list-collapse--item" key={index} data-open="false">
                  <p className="header" onClick={e => this.toggle(index)}>
                    <span className="btn">
                      <span className="icon icon-plus"></span>
                      <span className="icon icon-minus"></span>
                    </span>
                    <span className="title">{item.titulo}</span>
                  </p>

                  <div className="body" dangerouslySetInnerHTML={{__html: item.descricao}} />
                </li>)}
            </ul>}
        </div>
      </div>
    )
  }
}

ListCollapse.propTypes = {
  any: PropTypes.any,
}

export default ListCollapse;