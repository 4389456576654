import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import anime from 'animejs';
import ListCardGalleryControls from './ListCardGalleryControls';
import SwipeTouch from './../../SwipeTouch';
import { image } from './../../../Helpers';

import Modal from 'components/Modal';

import './list-card-gallery.css';

/**
 * List card gallery
 *
 * @class ListCardGallery
 * @extends {Component}
 */
class ListCardGallery extends Component {
  /**
   * Creates an instance of ListCardGallery.
   * 
   * @param {*} props
   * @memberof ListCardGallery
   */
  constructor(props) {
    super(props);

    this.state = {
      current: null,
      index: 0,
      direction: 'prev',
      last: null,
      modalActive: false,
      galleryItemActiveIndex: 0
    };

    this.setGalleryItem = this.setGalleryItem.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  /**
   * component did mount
   *
   * @memberof ListCardGallery
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);

    this.setState({
      current: this.props.galeria[0],
    }, () => this.swipeElement(this.element));
  }

  /**
   * animate
   * @param {any} type
   * @memberof Webdoor
   */
  animate(type) {
    const target = this.element.querySelector('.list-card-gallery--item[data-current="true"]');

    if (target instanceof Object) {
      const options = {
        targets: target,
        loop: false,
        elasticity: 0,
        easing: 'easeInQuad',
        left: [{
          value: 0,
          duration: 0,
        }],
        opacity: [{
          value: 0,
          duration: 0,
        }, {
          value: 1,
          duration: 400,
        }]
      };

      anime(options);
    }
  }

  /**
   * set current item
   * 
   * @param {any} type 
   * @memberof Webdoor
   */
  setCurrentItem(type) {
    this.setState((prevState) => {
      const current = this.props.galeria[this.state.index];

      return {
        last: prevState.current,
        current: current,
      };
    }, () => this.animate(type));
  }

  /**
   * next prev
   * 
   * @param {*} index
   * @param {*} type
   */
  nextPrev(index, type) {
    switch (type) {
      case 'prev':
        return (index - 1) < 0 ? 0 : index - 1;
      case 'next':
      default:
        return (index + 1) > (this.props.galeria.length - 1) ? (this.props.galeria.length - 1) : index + 1;
    }
  }

  /**
   * set gallery item
   * 
   * @param {*} index
   * @param {*} type
   */
  setGalleryItem(index, type) {
    this.setState({
      index: this.nextPrev(index, type),
      direction: type,
      // galleryItemActiveIndex: index
    }, () => this.setCurrentItem(type));
  }

  /**
   * swipe element
   * @param {*} webdoor
   */
  swipeElement(webdoor) {
    if (webdoor instanceof Object) {
      new SwipeTouch(webdoor, (event) => {
        if (event === 'left') {
          this.setGalleryItem(this.state.index, 'next');
        } else if (event === 'right') {
          this.setGalleryItem(this.state.index, 'prev');
        }
      });
    }
  }

  handleModal = (itemIndexValue) => {
    this.setState({
      modalActive: true,
      galleryItemActiveIndex: itemIndexValue
    });
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof ListCardGallery
   */
  render() {
    const url = "https://api-csa.homolog.sirius.com.br";
    const galleryProps = this.props.galeria;
    const currentGalleryItemModalImage = galleryProps[this.state.galleryItemActiveIndex].foto_ampliada?.large;
    // console.log(currentGalleryItemModalImage);

    return (
      <>
        <div className="list-card-gallery">
          {galleryProps &&
            <ul className="list-card-gallery--list">
              {galleryProps.map((item, index) =>
                // Por algum motivo, está aplicando a classe em todos os elementos, ao invés de somente no item clicado.
                <li className="list-card-gallery--item"
                  data-current={item === this.state.current}
                  data-last={item === this.state.last}
                  key={index}
                  onClick={() => {
                    this.handleModal(index);
                  }}
                  style={{cursor: currentGalleryItemModalImage?.src !== undefined ? "grab" : ''}}
                >
                    {image(item.legenda, item.foto)}

                    <div className="list-card-gallery--item--info">
                      <p className="description">{item.legenda}</p>
                    </div>
                </li>)}
            </ul>}

            {/* this.setState({galleryItemActiveIndex: this.state.index}) */}

          <ListCardGalleryControls
            current={this.state.current}
            change={this.setGalleryItem}
            gallery={galleryProps}
            index={this.state.index} />
        </div>

        {/**
          1) Se o item foi clicado, então o modal será exibido.

          2) Eu armazeno o index do item clicado em um estado e então usá-lo para criar o modal.
          O index também será usado para identificar qual item do array "galleryProps" será utilizado.

          3) O valor de galleryItemActiveIndex só muda apenas ao clicar no outro item. Ou seja:

          - Cliquei no item 1, agora o valor do state "galleryItemActiveIndex" é referente ao item 1.
          - Ao trocar de item do carousel, o valor do state continua sendo referente ao item 1 e só muda ao clicar no item 2.
          - Assim, eu teria que atualizar o galleryItemActiveIndex, ao trocar de item no carousel.
         */}

        {this.state.modalActive === true && currentGalleryItemModalImage?.src !== undefined ? (
          <Modal
            onClose={() => {
              this.setState({
                modalActive: false
              });
            }}

            height={currentGalleryItemModalImage?.height}
            width={currentGalleryItemModalImage?.width}
            src={`${url + currentGalleryItemModalImage?.src}`}
            alt={currentGalleryItemModalImage?.alt}
            key={this.state.galleryItemActiveIndex}
          />
        ) : ''}
      </>
    )
  }
}

ListCardGallery.propTypes = {
  galeria: PropTypes.array.isRequired,
}

export default ListCardGallery;