import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './list-card-gallery-controls.css';

/**
 * List card gallery controls
 *
 * @class ListCardGalleryControls
 * @extends {Component}
 */
class ListCardGalleryControls extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof ListCardGalleryControls
   */
  render() {
    return (
      <div className="list-card-gallery-controls">
        <button className="control left" 
          onClick={() => this.props.change(this.props.index, 'prev')}
          disabled={this.props.index === 0}>
          <i className="icon icon-arrow-left"></i>
        </button>

        <button className="control right"
          onClick={() => this.props.change(this.props.index, 'next')}
          disabled={this.props.index === (this.props.gallery.length - 1)}>
          <i className="icon icon-arrow-right"></i>
        </button>
      </div>
    )
  }
}

ListCardGalleryControls.propTypes = {
  current: PropTypes.object,
  change: PropTypes.func.isRequired,
  gallery: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
}

export default ListCardGalleryControls;