import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { baseUrl } from '../../../Helpers';

import NewsItem from '../NewsItem';

/**
 * News list
 *
 * @class NewsList
 * @extends {Component}
 */
class NewsList extends Component {
  /**
   * Creates an instance of News.
   * 
   * @param {*} props
   * @memberof News
   */
  constructor(props) {
    super(props);

    this.state = {
      data_page: {},
      data_filters: [],
      data_news: [],
    };
  }

  /**
   * component did mount
   *
   * @memberof NewsList
   */
  componentDidMount() {
    this.mounted = true;
    this.onLoad(this.props.state.page);
  }

  /**
   * component will un mount
   *
   * @memberof NewsList
   */
  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * on load
   *
   * @param {*} page
   * @returns
   * @memberof NewsList
   */
  onLoad(page) {
    if (page instanceof Object === false) {
      return false;
    }
    else {
      this.setState({
        data_page: page
      })
    } 

    const newsRequest = axios({ method: 'GET', url: `${baseUrl}pages/?child_of=${page.id}&type=paginas.Noticia&fields=data,categoria,descricao,thumbnail&order=-first_published_at` })
    const filtersRequest = axios({ method: 'GET', url: `${baseUrl}paginas/categorias-noticia` })

    axios.all([newsRequest, filtersRequest]).then(
      axios.spread((...responses) => {
        const news = responses[0];
        const filters = responses[1];

        if (news.data) {
          this.setState({
            data_news: news.data.items
          });
        }

        if (filters.data) {
          this.setState({
            data_filters: filters.data.results
          });
        }
      })
    )
  }

  /**
   * set item active
   * 
   * 
   */
  setActive(id) {
    const items = document.querySelectorAll(".filter--item");
    const filterNewsRequest = axios({ method: 'GET', url: `${baseUrl}pages/?child_of=${this.state.data_page.id}&type=paginas.Noticia&fields=data,categoria,descricao,thumbnail&order=-first_published_at&categoria=${id}` });

    axios.all([filterNewsRequest]).then(
      axios.spread((...responses) => {
        const filterNews = responses[0];

        if (filterNews.data) {
          this.setState({
            data_news: filterNews.data.items
          });
        }
      })
    )

    items.forEach((item) => {
      item.classList.remove('active');

      if (item.getAttribute('data-type') === `${id}`) {
        item.classList.add('active');
      }
    })
  }

  /**
   * load all news
   * 
   * 
   */
  loadAll() {
    const items = document.querySelectorAll(".filter--item");
    const filterNewsRequest = axios({ method: 'GET', url: `${baseUrl}pages/?child_of=${this.state.data_page.id}&type=paginas.Noticia&fields=data,categoria,descricao,thumbnail&order=-first_published_at` })

    axios.all([filterNewsRequest]).then(
      axios.spread((...responses) => {
        const filterNews = responses[0];

        if (filterNews.data) {
          this.setState({
            data_news: filterNews.data.items
          });
        }
      })
    )

    items.forEach((item) => item.classList.remove('active'))
    items[0].classList.add('active');
  }

  /**
   * render
   *
   * @returns
   * @memberof NewsList
   */
  render() {
    return (
      <>
        <ul className="filter--list">
          <li className="filter--item active" onClick={() => this.loadAll()}>Todas as notícias</li>
          {Array.isArray(this.state.data_filters) && this.state.data_filters.map((item, index) => 
            <li className="filter--item" data-type={item.id} key={index} onClick={() => this.setActive(item.id)}>
              {item.nome}
            </li>)}
        </ul>
        <ul className="news--list">
          {Array.isArray(this.state.data_news) && this.state.data_news.map((item, index) => 
            <li className="news--item" data-type={item.categoria.id} key={index}>
              <NewsItem item={item} />
            </li>)}
        </ul>
      </>
    )
  }
}

NewsList.propTypes = {
  any: PropTypes.any,
}

export default NewsList;