import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { image } from './../../../Helpers';

import './three-image-item.css';

/**
 * Three image item
 *
 * @class ThreeImageItem
 * @extends {Component}
 */
class ThreeImageItem extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof ThreeImageItem
   */
  render() {
    return (
      <Fragment>
        {this.props.item && <div className="three-image-item">
          {image(this.props.item.title, this.props.item.image)}

          <div className="three-image-item--info">
            <svg width='100%' height='100%' viewBox="0 0 100 100" preserveAspectRatio="none">
              <polygon points={`30,20 100,0 100,100, 0,100`} />
            </svg>

            <p className="text">{this.props.item.title}</p>
          </div>
        </div>}
      </Fragment>
    )
  }
}

ThreeImageItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default ThreeImageItem;