import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './news-item.scss';

/**
 * News item
 *
 * @class NewsItem
 * @extends {Component}
 */
class NewsItem extends Component {
  /**
   * on data
   *
   * @param {*} data
   * @returns
   * @memberof NewsItem
   */
  onData(data) {
    if (!data) return false;

    const date = data.split('-');
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    return <p className="date">
        <span className="day">{date[2]}</span>
        <span className="month">{months[parseInt(date[1]) - 1]}</span>
        <span className="year">{date[0].slice(2)}</span>
      </p>;
  }

  /**
   * replace url
   *
   * @returns
   * @memberof NewsItem
   */
  replaceUrl() {
    const url = this.props.item.meta.html_url.slice(0, -1).split('/');
    const path = url[url.length - 2];
    const getNameUrl = url[url.length - 1];

    return `${path}/${getNameUrl}`;
    // return getNameUrl;
  }

  /**
   * url temp
   */
  projectUrl = process.env.REACT_APP_API_URL

  /**
   * render
   *
   * @returns
   * @memberof NewsItem
   */
  render() {
    return (
      <Fragment>
        {this.props.item &&
          <Link to={this.replaceUrl()} className='item-link'>
            <div className='item-image'>
              <img src={this.props.item.thumbnail ? this.projectUrl + this.props.item.thumbnail.url : "../images/no-news-image.png"} alt="Imagem Thumbnail" /> 
            </div>
            
            <div className="data">{this.onData(this.props.item.data)}</div>

            <div className="info">
              <p className="title">
                <span className="">{this.props.item.title}</span>
              </p>
            </div>
          </Link>}
      </Fragment>
    )
  }
}

NewsItem.propTypes = {
  item: PropTypes.any,
}

export default NewsItem;