import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './quote.css';

/**
 * Quote
 *
 * @class Quote
 * @extends {Component}
 */
class Quote extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof Quote
   */
  render() {
    return (
      <div className="quote">
        <div className="wrapper">
          <div className="quote--bg">
            <i className="icon icon-logo"></i>
          </div>

          <div className="quote--info">
            <h1 className="text">{'"Nunca o prazer em aprender deveria ser inteiramente abandonado."'}</h1>
            <p className="author">
              <span><strong>Santo Agostinho</strong>, Cidade de Deus 19,19</span>
            </p>
          </div>

        </div>
      </div>
    )
  }
}

Quote.propTypes = {
  any: PropTypes.any,
}

export default Quote;