import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';

import axios from 'axios';

import CepField from './components/CepField';

import { formUrl } from '../../Helpers';
import Select from './../Select';

import './forms.css';
import CnpjField from './components/CnpjField';
import PhoneField from './components/PhoneField';

/**
 * Forms
 *
 * @class Forms
 * @extends {Component}
 */
class Forms extends Component {
  /**
   * Creates an instance of Forms.
   * 
   * @param {*} props
   * @memberof Forms
   */
  constructor(props) {
    super(props);

    this.state = {
      form: {
        actions: [],
        method: '',
      },
      hiddenFields: ['disciplina', 'cepom'],
      formValid: true,
      success: '',
    };

    this.changeChoice = this.changeChoice.bind(this);

    /** @type {Array<React.RefObject>} */
    this.inputRefs = [];
  }

  /**
   * component did mount
   *
   * @memberof Forms
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);

    this.loadForm();
  }

  /**
   * component did update
   *
   * @memberof Forms
   */
  componentDidUpdate(prevProps) {
    if (prevProps.tipo !== this.props.tipo && prevProps.tipo !== '') {
      this.loadForm();
    }
  }

  /**
   * check form inputs
   *
   * @param {*} form
   * @returns
   * @memberof Forms
   */
  checkFormInputs(form) {
    if (!form instanceof Object) return false;
    
    let checkForm = true;

    form.querySelectorAll('[name]').forEach(item => {
      if (item.validity.valid === false || item.value === '') {
        checkForm = false;
      }
    });

    return checkForm;
  }

  /**
   * check value
   *
   * @param {*} event
   * @param {*} type
   * @memberof Forms
   */
  checkValue(event, type) {
    if (!event) return false;

    let regex = null;

    switch (type) {
      case 'email':
        regex = new RegExp('^[a-z0-9]+(\\.[_a-z0-9]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,15})$', 'i');
        break;

      case 'text':
        regex = new RegExp('^[A-zÀ-ú _@./#&+-]+$');
        break;

      case 'phone':
        regex = new RegExp('^\\(?[0-9]{2}\\)?[-. ]?([0-9]{4})[-.]?([0-9]{3,})$' ,'g');
        break;

      case 'cnpj':
        //eslint-disable-next-line
        regex = new RegExp('^([0-9]{2}).([0-9]{3}).([0-9]{3})\/([0-9]{4})-([0-9]{2})$' ,'g');
        break;

      case 'cep':
        regex = new RegExp('^([0-9]{5})-([0-9]{3})$' ,'g');
        break;

      default:

      //eslint-disable-next-line
      case 'normal':
        regex = null;
    }
    
    this.checkValueSetAttribute(event.target, regex !== null ? !regex.test(event.target.value) : null);
  }

  /**
   * check value set attribute
   *
   * @param {*} element
   * @param {*} check
   * @returns
   * @memberof Forms
   */
  checkValueSetAttribute(element, check) {
    if (!element instanceof Object) return false;

    if (element.value.length === 0 || element.value === '') {
      element.setAttribute('data-empty', true);
    } else {
      element.setAttribute('data-empty', false);
    }

    element.parentNode.setAttribute('data-error', check);
  }

  /**
   * change choice
   *
   * @param {*} event
   * @returns
   * @memberof Forms
   */
  changeChoice(event) {
    if (!event || event instanceof Object === false) return false;

    const element = event.target.parentNode;

    if (event.target.name === 'area_atuacao') {
      if (event.target.value === 'pedagogico') {
        this.setState(prevState => ({
          ...prevState,
          hiddenFields: [],
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          hiddenFields: ['disciplina'],
        }));
      }
    } else if (event.target.name === 'tipo_fornecedor') {
      if (event.target.value === 'servicos') {
        this.setState(prevState => ({
          ...prevState,
          hiddenFields: [],
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          hiddenFields: ['cepom'],
        }));
      }
    }

    if (element instanceof Object) {
      element.setAttribute('data-error', '');
    }
  }

  /**
   * factory actions
   *
   * @param {*} data
   * @returns
   * @memberof Forms
   */
  factoryActions(data) {
    if (!data) return false;

    const formInputs = [];
    const properties = data.actions.POST;

    if (properties instanceof Object) {
      for (let key in properties) {
        const property = properties[key];

        if (property instanceof Object) {
          let input = {};

          if (property.choices && property.choices.length) {
            input = {
              label: property.label,
              name: key,
              required: property.required,
              choices: property.choices,
              type: 'choices',
            }
          } else {
            input = {
              label: property.label,
              name: key,
              max_length: property.maxLength || property.max_length,  
              required: property.required,
              pattern: property.pattern,
              type: property.type,
            }
          }
          
          formInputs.push(input);
        }
      }

      this.setForm(formInputs);
    }
  }

  /**
   * get input
   *
   * @param {*} item
   * @returns
   * @memberof Forms
   */
  getInput(item) {
    if (!item instanceof Object) return false;

    return this.getInputType(item, item.max_length);
  }

  /**
   * get input type
   *
   * @param {*} item
   * @param {*} maxLength
   * @returns
   * @memberof Forms
   */
  getInputType(item, maxLength) {
    if (!item instanceof Object) return false;

    switch (item.type) {
      default:
      case 'string':
        if (maxLength) {
          if (item.name.startsWith('telefone')) {
            return <PhoneField { ...item } onChange={(e) => this.checkValue(e, 'phone')} />
          }

          if (item.name === 'cnpj') {
            return <CnpjField { ...item } onChange={(e) => this.checkValue(e, 'cnpj')} />
          }
          
          if (item.name === 'cep') {
            return <CepField { ...item} onChange={(e) => this.checkValue(e, 'cep')} />
          }

          if (item.name === 'estado') {
            const states = [
              { value: 'AC', display_name: 'AC' },
              { value: 'AL', display_name: 'AL' },
              { value: 'AM', display_name: 'AM' },
              { value: 'AP', display_name: 'AP' },
              { value: 'BA', display_name: 'BA' },
              { value: 'CE', display_name: 'CE' },
              { value: 'DF', display_name: 'DF' },
              { value: 'ES', display_name: 'ES' },
              { value: 'GO', display_name: 'GO' },
              { value: 'MA', display_name: 'MA' },
              { value: 'MG', display_name: 'MG' },
              { value: 'MS', display_name: 'MS' },
              { value: 'MT', display_name: 'MT' },
              { value: 'PA', display_name: 'PA' },
              { value: 'PB', display_name: 'PB' },
              { value: 'PE', display_name: 'PE' },
              { value: 'PI', display_name: 'PI' },
              { value: 'PR', display_name: 'PR' },
              { value: 'RJ', display_name: 'RJ' },
              { value: 'RN', display_name: 'RN' },
              { value: 'RO', display_name: 'RO' },
              { value: 'RR', display_name: 'RR' },
              { value: 'RS', display_name: 'RS' },
              { value: 'SC', display_name: 'SC' },
              { value: 'SE', display_name: 'SE' },
              { value: 'SP', display_name: 'SP' },
              { value: 'TO', display_name: 'TO' }];
            
            return <Select
              label='Estado'
              value=''
              name={item.name}
              required={item.required}
              onToggle={this.changeChoice}
              choices={states}
              />;
          }

          if (item.name === 'email') {
            return <input
              name={item.name}
              type="email"
              required={item.required}
              onChange={(e) => this.checkValue(e, 'email')} />;
          }

          return <input
            type="text"
            data-empty="true"
            name={item.name}
            pattern="^[A-zÀ-ú _@./#&+-]+$"
            required={item.required}
            maxLength={maxLength}
            onChange={(e) => this.checkValue(e, 'text')} />;
        }

        if (item.name === 'assunto') {
          return <input
          type="text"
          data-empty="true"
          name={item.name}
          pattern="^[A-Za-z -]+$"
          required={item.required}
          onChange={(e) => this.checkValue(e, 'text')} />;
        }

        return <textarea
          data-empty="true"
          name={item.name}
          required={item.required}
          onChange={(e) => this.checkValue(e, 'normal')}></textarea>;

      case 'file upload':
          return <label className="file">
            <span className="icon icon-link"></span>
            <span className="file-name"></span>
            <input
              name={item.name === 'currículo' ? 'curriculo' : item.name}
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              type="file"
              required={item.required}
              onChange={(e) => this.inputLoadFile(e)} />
          </label>;

      case 'email':
        return <input
          name={item.name}
          type="email"
          required={item.required}
          onChange={(e) => this.checkValue(e, 'email')} />;

      case 'choices':
        if (item.choices) {
          if (item.name === 'disciplina') item.required = true;

          return <Select
            name={item.name}
            required={item.required}
            label={item.label}
            value=""
            onToggle={this.changeChoice}
            choices={item.choices} />;
        }

        return false;
    }
  }

  getInputRef(name) {
    return this.inputRefs.find((i) => i.current && i.current.control.name === name);
  }

  /**
   * input load file
   *
   * @param {*} event
   * @returns
   * @memberof Forms
   */
  inputLoadFile(event) {
    if (!event instanceof Object) return false;

    const input = event.target.parentNode;
    const label = input.querySelector('.file-name');

    if (event.target.files[0].size > 1e6) {
      label.innerHTML = 'O arquivo excede o limite permitido';
      
      input.parentNode.setAttribute('data-error', true);
    } else {
      label.innerHTML = event.target.files[0].name;

      input.setAttribute('data-active', true);
      input.parentNode.setAttribute('data-error', false);
    }
  }

  /**
   * load form
   *
   * @memberof Forms
   */
  loadForm() {
    axios({
      url: `${this.props.tipo}/`,
      baseURL: formUrl,
      method: 'OPTIONS',
    }).then((e) => {
      if (e.data && e.status === 200) {
        this.factoryActions(e.data);
      } else {
        console.error('Form error!');
      }
    });
  }

  /**
   * send info
   *
   * @param {*} type
   * @returns
   * @memberof Forms
   */
  sendEventSubmit(event) {
    event.preventDefault();

    this.sendEvent(this.checkFormInputs(this.element.querySelector('form')));
  }

  /**
   * send event
   *
   * @param {*} check
   * @memberof Forms
   */
  sendEvent(check) {
    const form = this.element.querySelector('form');

    this.setState({
      formValid: check,
    }, () => {
      if (this.state.formValid === true) {
        const formData = new FormData(form);
        formData.set('telefone', this.element.querySelector('form input[name="telefone"]').value);

        axios({
          url: `${this.props.tipo}/`,
          baseURL: formUrl,
          method: this.state.form.method,
          data: formData
        }).then((e) => {
          if (e.data && (e.status === 201 || e.status === 200)) {
            form.querySelectorAll('[name]').forEach(item => {
              item.value = '';
              item.setAttribute('data-empty', true);
            });

            if (this.props.tipo === 'faleconosco') {
              this.setState({
                success: 'Sua mensagem foi enviada com sucesso!',
              });
            }

            if (this.props.tipo === 'trabalheconosco') {
              this.setState({
                success: 'Seu cadastro foi realizado com sucesso!',
              }, () => {
                const clearTexts = document.querySelector('.trabalhe-conosco');

                if (clearTexts instanceof Object) {
                  clearTexts.classList.add('success');
                }
              });
            }
          } else {
            console.error('Form error!', e);
          }
        });
      } else {
        const inputs = Array.from(form.querySelectorAll('[name]'));

        if (inputs.length > 0) {
          inputs.map(item => item.parentNode.setAttribute('data-error', item.validity.valid === false));
        }
      }
    });
  }

  /**
   * set form
   *
   * @param {*} method
   * @param {*} formInputs
   * @returns
   * @memberof Forms
   */
  setForm(formInputs) {
    if (!formInputs) return false;

    this.setState({
      form: {
        actions: formInputs,
        method: 'POST',
      }
    });
  }

  /**
   * render
   *
   * @returns
   * @memberof Forms
   */
  render() {
    return (
      <div className="forms" data-type={this.props.tipo} data-success={this.state.success !== '' ? true : false}>
        <div className="wrapper">
          <form className={`forms--form ${this.props.tipo}`} autoComplete="off" noValidate>
            {this.state.form.actions &&
              <div className="forms--content">
                {this.state.form.actions.map((item, index) => {
                  if (item.label !== 'ID' && !this.state.hiddenFields.includes(item.name)) {
                    return (
                      <label className="input-control" key={index}>
                        { this.getInput(item) }

                        { item.type !== 'choice' &&
                          <label className="label">
                            <span>{item.required ? '*' : ''}</span>{item.label}
                          </label> }
                      </label>
                    );
                  }

                  return false;
                })}
              </div>}

            <div className="forms--content--error" style={{display: this.state.formValid === true ? 'none' : 'inline-block'}}>
              <p className="text">Por favor verifique os campos.</p>
            </div>
            <button className="btn-triangle" onClick={(e) => this.sendEventSubmit(e)}>enviar</button>
          </form>

          <div className="forms--success">
            <p className="text">{this.state.success}</p>
          </div>

          {this.props.tipo === 'faleconosco' &&
            <div className="contact--container">
              <div className="contact">
                <p className="contact--title">Colégio Santo Agostinho Leblon</p>
              
                <ul className="contact--list">
                  <li className="contact--list--item">
                    <a className="text" href="https://goo.gl/maps/6q7PMNyPUYT7KxgY8" target="_blank" rel="noopener noreferrer">
                      <i className="icon icon-pointer"></i>
                      Rua Cupertino Durão, 75 - Leblon<br />
                      CEP 22441-030 - Rio de Janeiro - RJ
                    </a>
                  </li>
                  <li className="contact--list--item">
                    <a className="text" href="https://goo.gl/maps/qbZghLWpLPSjNuwk7" target="_blank" rel="noopener noreferrer">
                      <i className="icon icon-pointer"></i>
                      Rua José Linhares, 88 - Leblon<br/>
                      CEP 22430-220 - Rio de Janeiro - RJ
                    </a>
                  </li>
                  <li className="contact--list--item">
                    <p className="text">
                      <i className="icon icon-phone"></i>
                      <span><strong>Telefone:</strong> (21) 3206-7850</span>
                    </p>
                  </li>
                </ul>
              </div>

              <div className="contact">
                <p className="contact--title">Trabalhe conosco</p>
              
                <ul className="contact--list">
                  <li className="contact--list--item">
                    <Link className="text" to="/trabalhe-conosco">
                      Envie seu currículo e venha trabalhar conosco
                    </Link>
                  </li>
                </ul>
              </div>
          </div>}
        </div>
      </div>
    )
  }
}

Forms.propTypes = {
  tipo: PropTypes.string.isRequired,
}

export default Forms;