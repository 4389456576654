import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import dateFns from 'date-fns';

import { setToken, getToken, projectUrl, baseUrl, getWidthHeightDoc, loginUrl } from './../Helpers';

/**
 * Main context
 */
const MainContext = createContext({
  state: {
    menu: [],
    logged: false,
    submenu: [],
    page: {},
    resize: {
      height: getWidthHeightDoc().height,
      width: getWidthHeightDoc().width 
    },
    routes: [],
    user: {},
  },
  login: () => {},
  logout: () => {},
  setUser: () => {},
  onGetPage: () => {},
});

/**
 * Main Provider
 *
 * @class MainProvider
 * @extends {Component}
 */
class MainProvider extends Component {
  /**
   * Creates an instance of MainProvider.
   * 
   * @param {*} props
   * @memberof MainProvider
   */
  constructor(props) {
    super(props);

    this.state = {
      menu: [],
      logged: false,
      page: {},
      submenu: [],
      resize: {
        height: getWidthHeightDoc().height,
        width: getWidthHeightDoc().width 
      },
      routes: [],
      topPosition: 0,
    };

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setUser = this.setUser.bind(this);
    this.onGetPage = this.onGetPage.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onScrollWindow = this.onScrollWindow.bind(this);
  }

  /**
   * component did mount
   *
   * @memberof MainProvider
   */
  componentDidMount() {
    this.mounted = true;

    this.onGetMenu();
    this.onGetAllRoutes();
    this.isAuth();
    
    window.addEventListener('resize', () => this.onResize(), false);
    window.addEventListener('scroll', e => this.onScrollWindow(e), false);
  }

  componentDidUpdate(prevPros) {
    if (prevPros.location !== this.props.location) {
      this.handleLocationChange();
    }
  }


  /**
   * component unmount
   *
   * @memberof MainProvider
   */
  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', () => this.onResize);
  }

  /**
   * should component update
   *
   * @param {*} prevProps
   * @param {*} prevStates
   * @returns
   * @memberof MainProvider
   */
  shouldComponentUpdate(prevProps, prevStates) {
    if (prevStates !== this.state || prevProps !== this.props) {
      return true;
    }

    return false;
  }

  isAuth() {
    const token = getToken();

    if (token !== null){
      axios({
        url: loginUrl,
        method: 'POST',
        headers: {
          'Authorization': `bearer ${token}`,
        }
      })
      .then(e => console.log(e))
      .catch(() => this.logout());
    }
  }

  login(data) {
    if (data instanceof Object === false) return false;

    if (data.id && data.password) {
      //feiradolivro / csa@2019
      axios.post(loginUrl, data)
        .then(res => {
          if (res.status === 200) {
            setToken(res.data.token);

            if (this.props.setUser) {
              this.props.setUser(res.data.profile);
            }
          }
        })
        .catch(e => console.log(e));
    }
  }

  logout() {
    this.setState({
      logged: false,
      user: {},
    }, () => setToken(null));
  }

  /**
   * add home to menu
   *
   * @param {*} menu
   * @param {*} home
   * @returns
   * @memberof MainProvider
   */
  addHomeToMenu(menu, home) {
    if (!home) return menu;

    const menuMain = menu;
    menuMain.items.push(home);

    return menuMain;
  }

  /**
   * create home
   *
   * @param {*} data
   * @returns
   * @memberof MainProvider
   */
  createMenu(menu, home) {
    if (!menu) return false;

    this.setState(() => {
      return { 
        menu: this.addHomeToMenu(menu, home),
      };
    }, () => {
      this.handleLocationChange();
    });
  }

  /**
   * find route parent
   *
   * @param {*} routes
   * @param {*} name
   * @returns
   * @memberof MainProvider
   */
  findRouteParent(routes, name) {
    if (!Array.isArray(routes) || !name) return false;

    for (let route of routes) {
      if (route.meta) {
        const routesItem = this.splitUrl(route);
        
        if (routesItem.length === 2) {
          if (routesItem[1] === name) {
            return route;
          }
        } else if (routesItem.length === 1) {
          if (routesItem[0] === name) return route;
        }
      }
    }
  }

  /**
   * handle location change
   *
   * @memberof MainProvider
   */
  handleLocationChange() {
    window.scrollTo(0, 0);
  }

  /**
   * join routes
   *
   * @param {*} data
   * @returns
   * @memberof MainProvider
   */
  joinRoutes(data) {
    if (!data) return false;

    const items = data;

    for (let key in data.items) {
      const route = this.splitUrl(data.items[key]);

      if (Array.isArray(route)) {
        let url = '';

        for (let keyUrl in route) {
          if (route[keyUrl] !== '') {
            url += `${route[keyUrl]}/`;
          }
        }
        
        items.items[key].meta.slug = url.substring(0, url.length - 1);
      }
    }

    return items;
  }

  /**
   * join routes three level
   *
   * @param {*} routes
   * @returns
   * @memberof MainProvider
   */
  joinRoutesThreeLevel(routes) {
    if (!Array.isArray(routes) || !routes) return false;

    const routesMain = this.state.routes;

    for (let key in routesMain.items) {
      const route = routesMain.items[key];
      route.meta.submenuThree = [];

      for (let routeLevelThree of routes) {
        if (route.meta.slug === routeLevelThree.parent.meta.slug && routeLevelThree.parent.meta.type !== 'paginas.Noticias') {
          route.meta.submenuThree.push(routeLevelThree.route);
        }
      }

      if (route.meta.submenuThree.length <= 1) {
        //delete route.meta.submenuThree;
        route.meta.submenuThree = [];
      }

      routesMain.items[key] = route;
    }

    this.joinRoutesThreeLevelChild(routesMain, routes);
  }

  /**
   * join routes three level child
   *
   * @param {*} routesJoin
   * @param {*} routesItemsLevel
   * @returns
   * @memberof MainProvider
   */
  joinRoutesThreeLevelChild(routesJoin, routesItemsLevel) {
    if (!routesJoin instanceof Object || !routesJoin || !Array.isArray(routesItemsLevel) || !routesItemsLevel) return false;

    const routes = routesJoin;

    for (let route of routesItemsLevel) {
      for (let routeKey in routes.items) {
        const item = routes.items[routeKey];

        if (item.meta.slug === route.route.meta.slug) {
          routes.items[routeKey].meta.submenuThree = route.parent.meta.submenuThree;
        }
      }
    }

    this.setState({
      routes: routes,
    });
  }

  /**
   * set login
   *
   * @param {*} value
   * @memberof MainProvider
   */
  setLogin(value) {
    this.setState({
      logged: value
    });
  }

  /**
   * set user
   *
   * @param {*} data
   * @returns
   * @memberof MainProvider
   */
  setUser(data) {
    if (data instanceof Object === false) return false;

    return this.setState({
      user: data,
    });
  }

  /**
   * on set menu
   *
   * @memberof MainProvider
   */
  onGetMenu() {
    axios({
      url: 'pages/?type=paginas.PaginaBase&fields=*&navegacao=primaria&child_of=3',
      baseURL: baseUrl,
      method: 'GET',
      withCredentials: false,
    })
    .then(res => res.data)
    .then(data => {
      if (this.mounted === true) {
        this.onGetHome()
          .then(home => {
            this.createMenu(data, home);
          });

        this.onGetSubmenu();
      }
    });
  }

  /**
   * on get submenu
   *
   * @memberof HeaderSubmenu
   */
  onGetSubmenu() {
    axios({
      url: 'pages/?type=paginas.PaginaBase&fields=navegacao&navegacao=secundaria',
      baseURL: baseUrl,
      method: 'GET',
    }).then(res => {
      if (res.data) {
        if (res.data.items.length > 0) {
          return res.data.items;
        }
      }

      return false;
    }).then(items => {
      if (!items) return false;

      this.setState({
        submenu: items,
      });
    });
  }

  /**
   * on get all routes
   *
   * @memberof MainProvider
   */
  onGetAllRoutes() {
    axios({
      url: 'pages/?type=paginas.PaginaBase&fields=navegacao&limit=9999',
      baseURL: baseUrl,
      method: 'GET',
    }).then(res => {
      if (res.data) {
        this.setState({
          routes: this.joinRoutes(res.data),
        });

        this.threeMenuTabs(res.data.items);
      }

      return false;
    });
  }

  /**
   * on get home
   *
   * @returns
   * @memberof MainProvider
   */
  onGetHome() {
    return axios({
      url: 'pages/?type=paginas.PaginaBase&fields=navegacao&slug=home',
      baseURL: baseUrl,
      method: 'GET',
      withCredentials: false,
    }).then(res => {
      if (res.data) {
        return res.data.items[0];
      }

      return false;
    });
  }

  /**
   * on get page
   *
   * @param {*} location
   * @returns
   * @memberof MainProvider
   */
  onGetPage(location) {
    if (!location || !location.meta) return false;
    axios({
      url: location.meta.detail_url,
      method: 'GET',
    }).then(res => {
      const data = res.data;
      console.log(data);

      if (location.meta.slug === 'home' || location.meta.slug === '') {
        data.corpo.splice(2, 0, { type: 'citacao' });
        data.corpo.push({ type: 'social_networks' });
      }

      if (location.meta.slug === 'novos-alunos/calendario' || data.meta.slug === 'calendario') {
        data.corpo.push({ 
          type: 'calendario',
          'value': {
            url: `${projectUrl}/api/v2/calendario/`,
          }
        });
      }

      if (location.meta.type === 'paginas.Noticia') {
        const parent = this.state.routes.items.filter(item => item.meta.type === 'paginas.Noticias' && location.meta.slug.startsWith(item.meta.slug));
        if (parent.length > 0) {
          return this.onGetPageNews(data, parent, location);
        }
      }
      
      return this.setState({
        page: data,
      });
    }).catch(err => {
      console.error(err);
    });
  }

  /**
   * on get page news
   *
   * @param {*} data
   * @param {*} parent
   * @returns
   * @memberof MainProvider
   */
  onGetPageNews(data, parent, location) {
    if (data instanceof Object === false || location instanceof Object === false) return false;

    return axios({
      url: parent[0].meta.detail_url,
      method: 'GET',
    }).then(res => {
      if (res.data) {
        const page = res.data;

        delete page.corpo;
        delete page.id;
        delete page.meta;
        delete page.navegacao;
        delete page.retranca_para;

        if (page instanceof Object) {
          const title = {
            type: "titulo_mais_corpo",
            value: {
              corpo: `<p><span class="name">${location.title}</span>
                <span class="date">${this.replaceData(data.data)}</span>
                </p>`,
              titulo: ""
            }
          };

          data.corpo.unshift(title);

          return this.setState({
            page: {...data, ...page },
          });
        }

        return false;
      }
    });
  }

  /**
   * on resize
   *
   * @param {*} e
   * @memberof MainProvider
   */
  onResize() {
    this.setState({
      resize: {
        height: getWidthHeightDoc().height,
        width: getWidthHeightDoc().width,
      }
    });
  }

  /**
   * on scroll window
   *
   * @param {*} event
   * @memberof MainProvider
   */
  onScrollWindow(event) {
    if (event instanceof Object) {
      const top = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);

      this.setState({
        topPosition: top,
      });
    }
  }

  /**
   * replace data
   *
   * @param {*} data
   * @returns
   * @memberof MainProvider
   */
  replaceData(data) {
    if (data) {
      const date = dateFns.parse(data.replace(/-/g, '/'));
      return dateFns.format(date, 'DD-MM-YYYY');
    }

    return false;
  }

  /**
   * split url
   *
   * @param {*} route
   * @returns
   * @memberof MainProvider
   */
  splitUrl(route) {
    if (!route || route.meta.slug === 'home') return false;

    if (route.meta) {
      const url = route.meta.html_url.substring(0, route.meta.html_url.length - 1);
      const arrayUrl = url.replace(projectUrl + '/', '').split('/');

      return arrayUrl.length > 0 ? arrayUrl : false;
    }

    return false;
  }

  /**
   * three menu tabs
   *
   * @param {*} routes
   * @returns
   * @memberof MainProvider
   */
  threeMenuTabs(routes) {
    if (!Array.isArray(routes) || !routes) return false;

    const submenuThree = [];

    for (const route of routes) {
      if (route.meta) {
        const routesItem = this.splitUrl(route);

        if (Array.isArray(routesItem)) {
          if (routesItem.length === 3) {
            const parent = this.findRouteParent(routes, routesItem[1]);
            submenuThree.push({ parent: parent, route: route });
          } else if (routesItem.length === 2 && routesItem[0] === 'pastoral') {
            const parent = this.findRouteParent(routes, routesItem[0]);
            submenuThree.push({ parent: parent, route: route });
          }
        }
      }
    }

    this.joinRoutesThreeLevel(submenuThree);
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof MainProvider
   */
  render() {
    return (
      <MainContext.Provider
        value={
          {
            state: this.state,
            location: this.props.location,
            login: this.login,
            logout: this.logout,
            onGetPage: this.onGetPage,
            setUser: this.setUser,
          }
        }
      >
        {this.props.children}
      </MainContext.Provider>
    )
  }
}


MainProvider.propTypes = {
  location: PropTypes.any,
}

export {
  MainContext,
  MainProvider,
};