import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import HeaderItem from './../HeaderItem';
import HeaderTop from './../HeaderTop';
import SearchMenu from './../../../components/SearchMenu';

import './header-menu.css';

/**
 * Header menu
 *
 * @class HeaderMenu
 * @extends {Component}
 */
class HeaderMenu extends Component {
  /**
   * Creates an instance of HeaderMenu.
   * @param {*} props
   * @memberof HeaderMenu
   */
  constructor(props) {
    super(props);

    this.state = {
      mobile: null,
    };

    this.onSetMobile = this.onSetMobile.bind(this);
  }

  /**
   * on set mobile
   *
   * @param {*} item
   * @memberof HeaderMenu
   */
  onSetMobile(item) {
    this.setState(prevState => {
      return { mobile: prevState.mobile === item ? null : item };
    });
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof HeaderMenu
   */
  render() {
    return (
      <Fragment>
        {this.props.mobile === true && <div className="header-menu--mobile">
          <SearchMenu {...this.props} />

          <ul className="header-menu--list">
            {Array.isArray(this.props.menu) && this.props.menu.map((item, key) =>
              <li className="header-item" key={key}>
                <HeaderItem item={item} mobile={this.props.mobile} openMobile={this.state.mobile} onSetMobile={this.onSetMobile} />
              </li>)}
          </ul>
          
          <HeaderTop submenu={this.props.submenu} resize={this.props.resize} />
        </div>}
        
        {this.props.mobile === false && <div className="header-menu">
          {this.props.menu.map((block, key) => 
            <ul className="header-menu--list" key={key}>
              {block.map((item, keyItem) =>
                <li className="header-item" key={keyItem}>
                  <HeaderItem item={item} mobile={this.props.mobile} position={key === 0 ? 'left':'right'}/>
                </li>)}
            </ul>
          )}
        </div>}
      </Fragment>
    )
  }
}

HeaderMenu.propTypes = {
  menu: PropTypes.array.isRequired,
  submenu: PropTypes.array.isRequired,
  mobile: PropTypes.bool,
  resize: PropTypes.object,
  onSearch: PropTypes.any,
}

export default HeaderMenu;