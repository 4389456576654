import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactPlayer from 'react-player';

import './video.scss';

/**
 * Video
 *
 * @class Video
 * @extends {Component}
 */
class Video extends Component {
  /**
   * render
   *
   * @returns
   * @memberof Video
   */
  render() {
    return (
      <div className="video">
        <div className="wrapper">
          {this.props.url &&
            <ReactPlayer url={this.props.url} />}
        </div>
      </div>
    );
  }
}

Video.propTypes = {
  value: PropTypes.object,
};

export default Video;