import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import './highlights-slider-controls.css';

/**
 * Highlights slider controls
 *
 * @class HighlightsSliderControls
 * @extends {Component}
 */
class HighlightsSliderControls extends Component {

  /**
   * render
   *
   * @returns JSX
   * @memberof HighlightsSliderControls
   */
  render() {
    return (
      <Fragment>
        <button className="highlights-slider-controls prev" onClick={() => this.props.nextPrev('prev')}>
          <span className="icon icon-arrow-left"></span>
        </button>
        <button className="highlights-slider-controls next" onClick={() => this.props.nextPrev('next')}>
          <span className="icon icon-arrow-right"></span>
        </button>
      </Fragment>
    )
  }
}

HighlightsSliderControls.propTypes = {
  nextPrev: PropTypes.func.isRequired,
}

export default HighlightsSliderControls;