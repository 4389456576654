import Calendar from '../../components/Calendar';
// import CircularMenu from 'components/NewCircularsMenu';
import Circulars from 'components/Circulars';
import Coordination from './../../components/Coordination';
import Documents from './../../components/Documents';
import Forms from './../../components/Forms';
import FormsCSA from './../../components/FormsCSA';
import HighlightsSlider from './../../components/HighlightsSlider';
import History from './../../components/History';
import Image from './../../components/Image';
import ImageText from './../../components/ImageText';
import List from './../../components/List';
import ListCards from './../../components/ListCards';
import ListCardThumbnail from './../../components/ListCardThumbnail';
import ListCollapse from '../../components/List/ListCollapse';
import ListWhereWe from '../../components/ListWhereWe';
import MenuThreeLevel from './../../components/MenuThreeLevel';
import Quote from './../../components/Quote';
import SummaryPicture from '../../components/SummaryPicture';
import SocialNetworks from '../../components/SocialNetworks';
import Tab from './../../components/Tab';
import TextBody from './../../components/TextBody';
import TitlePage from './../../components/TitlePage';
import ThreeImages from './../../components/ThreeImages';
import Webdoor from './../../components/Webdoor';
import Video from '../../components/Video';
import SimpleGallery from 'components/Gallery';
import NewsHighlightsNew from 'components/NewsHighlightNew';
import QuickAccess from 'components/QuickAccess';
import News from 'components/News';
import PastoralHighlight from 'components/PastoralHighlight';

export const TYPE_COMPONENTS = [
  {
    type: 'accordion',
    component: ListCollapse
  }, {
    type: 'calendario',
    component: Calendar
  }, {
    type: 'circulares',
    component: Circulars
  }, {
    type: 'formulario',
    component: Forms
  }, {
    type: 'formulario_csa',
    component: FormsCSA,
  }, {
    type: 'imagem',
    component: Image
  }, {
    type: 'lista_onde_estamos',
    component: ListWhereWe
  }, {
    type: 'imagem_e_texto',
    component: ImageText
  }, {
    type: 'documentos',
    component: Documents
  }, {
    type: 'coordenacao',
    component: Coordination
  }, {
    type: 'titulo_imagem_resumo',
    component: SummaryPicture
  }, {
    type: 'submenuThree',
    component: MenuThreeLevel
  }, {
    type: 'lista_card_icone_titulo_thumbnail',
    component: ListCardThumbnail
  }, {
    type: 'lista_card_com_galeria_de_fotos',
    component: ListCards
  }, {
    type: 'lista',
    component: List
  }, {
    type: 'titulo_mais_corpo',
    component: TextBody
  }, {
    type: 'tres_imagens',
    component: ThreeImages
  }, {
    type: 'header',
    component: TitlePage
  }, {
    type: 'destaques_editoriais',
    component: HighlightsSlider
  }, {
    type: 'historia_1',
    component: History
  }, {
    type: 'historia_2',
    component: History
  }, {
    type: 'historia_3',
    component: History
  }, {
    type: 'historia_4',
    component: History
  }, {
    type: 'historia_5',
    component: History
  }, {
    type: 'historia_6',
    component: History
  }, {
    type: 'historia_7',
    component: History
  }, {
    type: 'historia_8',
    component: History
  }, {
    type: 'educacao',
    component: Tab
  }, {
    type: 'lista_webdoor',
    component: Webdoor
  }, {
    type: 'citacao',
    component: Quote
  }, {
    type: 'social_networks',
    component: SocialNetworks
  }, {
    type: 'noticias',
    component: News
  }, {
    type: 'youtube',
    component: Video
  }, {
    type: 'galeria_simples',
    component: SimpleGallery
  }, {
    type: 'news_highlight',
    component: NewsHighlightsNew
  }, {
    type: 'acesso_rapido',
    component: QuickAccess
  }, {
    type: 'escola_pastoral',
    component: PastoralHighlight
  },
];