import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ListIcon from './ListIcon';
import ListSimple from './ListSimple';

import './list.css';

/**
 * List
 *
 * @class List
 * @extends {Component}
 */
class List extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof List
   */
  render() {
    return (
      <div className="list">
        <div className="wrapper">
          {this.props.titulo &&
            <h1 className="title-main default">
              <span>{this.props.titulo}</span>
            </h1>}

          {this.props.tipo === 'simples' && <ListSimple lista={this.props.lista} />}

          {this.props.tipo === 'icone' && <ListIcon lista={this.props.lista} />}
        </div>
      </div>
    )
  }
}

List.propTypes = {
  tipo: PropTypes.string.isRequired,
  lista: PropTypes.array.isRequired,
  titulo: PropTypes.string,
}

export default List;