import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import { projectUrl } from "./../../Helpers";

import TabItem from "./TabItem";

import './tab.css';

/**
 * Tab
 *
 * @class Tab
 * @extends {Component}
 */
class Tab extends Component {
  /**
   * Creates an instance of Tab.
   *
   * @param {*} props
   * @memberof Tab
   */
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      items: [],
    };
  }

  /**
   * component did mount
   *
   * @memberof Tab
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);

    this.refactoryItems(this.props);
  }

  /**
   * create item
   *
   * @param {*} key
   * @returns
   * @memberof Tab
   */
  createItem(key) {
    if (!key) return false;

    const index = key.replace("titulo_", "");

    if (index) {
      const description = this.props[`descricao_${index}`];
      const image = !this.props[`imagem_${index}`]
        ? this.props[`imagem_1`]
        : this.props[`imagem_${index}`];
      const link = this.props[`link_interno_${index}`];
      const title = this.props[`titulo_${index}`];

      return { description, image, link, title, keyName: index };
    }

    return false;
  }

  /**
   * get image
   *
   * @returns JSX
   * @memberof Tab
   */
  getImage() {
    if (this.state.items.length > 0) {
      const item = this.state.items[this.state.current];

      return (
        <img src={projectUrl + item.image?.large.src} alt="" />
      );
    }

    return false;
  }

  /**
   * order sort by key
   *
   * @param {*} arrayItems
   * @param {*} key
   * @returns
   * @memberof Tab
   */
  orderSortByKey(arrayItems, key) {
    if (!Array.isArray(arrayItems)) return false;

    return arrayItems.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      }

      return 0;
    });
  }

  /**
   * refactory items
   *
   * @param {*} keys
   * @returns
   * @memberof Tab
   */
  refactoryItems(keys) {
    if (!keys instanceof Object) return false;

    const items = [];

    for (let key in keys) {
      if (key.search("titulo") !== -1) {
        items.push(this.createItem(key));
      }
    }

    this.refactoryOrderItems(items);
  }

  /**
   * refactory order items
   *
   * @param {*} items
   * @returns
   * @memberof Tab
   */
  refactoryOrderItems(items) {
    if (!Array.isArray(items)) return false;

    const itemsTemp = this.orderSortByKey(
      items.filter((item) => item.keyName !== "default"),
      "keyName"
    );
    const orderItems = items.filter((item) => item.keyName === "default");

    for (let item of itemsTemp) {
      if (item) orderItems.push(item);
    }

    this.setState({
      items: Array.isArray(orderItems) ? orderItems : [],
    });
  }

  /**
   * on change
   *
   * @param {*} event
   * @param {*} index
   * @returns
   * @memberof Tab
   */
  onChange(event, index) {
    if (isNaN(parseInt(index, 10)) || this.state.current === index)
      return false;

    this.setState({
      current: index,
    });
  }

  /**
   * split title
   *
   * @param {*} title
   */
  splitTitle(title) {
    if (!title) return false;

    const arrayText = title.toLowerCase().split(" ");

    return (
      <Fragment>
        {arrayText.map((item, key) => (
          <span className={key === 0 ? "in" : "out"} key={key}>
            {item}&nbsp;
          </span>
        ))}
      </Fragment>
    );
  }

  /**
   * render
   *
   * @returns
   * @memberof Tab
   */
  render() {
    return (
      <>
        <div className="tab">
          <div className="wrapper">
            <div className="tab--info">
              <ul
                className="tab--select"
                data-default={this.state.current === 0}
              >
                {this.state.items?.map((item, index) => (
                  <li
                    className={`tab--select--item ${item.keyName}`}
                    data-active={index === this.state.current}
                    key={index}
                  >
                    <button
                      className="item"
                      onClick={(e) => this.onChange(e, index)}
                    >
                      {this.splitTitle(item.title)}
                    </button>
                  </li>
                ))}
              </ul>
              <div className="tab--items">
                {this.state.items?.map((item, index) => (
                  <TabItem
                    {...item}
                    active={this.state.current === index}
                    index={index}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Tab.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Tab;
