import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';

import './list-simple.css';

/**
 * List simple
 *
 * @class ListSimple
 * @extends {Component}
 */
class ListSimple extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof ListSimple
   */
  render() {
    return (
      <div className="list-simple">
        {this.props.lista &&
          <ul className="list-simple--list">
            {this.props.lista.map((item, index) => 
              <li className="list-simple--item" key={index}>
                <Fade duration={700} top>
                  <p className="list-simple--item--info">
                    <span className="title">{item.titulo}</span>
                    <span className="description">{item.resumo}</span>
                  </p>
                </Fade>
              </li>)}
          </ul>}
      </div>
    )
  }
}

ListSimple.propTypes = {
  lista: PropTypes.array.isRequired,
}

export default ListSimple;