import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';

import { image } from './../../../Helpers';
import LinkComponent from '../../LinkComponent';

import './list-card-thumbnail-item.css';

/**
 * List card thumbnail item
 *
 * @class ListCardThumbnailItem
 * @extends {Component}
 */
class ListCardThumbnailItem extends Component {
  /**
   * check link
   *
   * @returns
   * @memberof ListCardThumbnailItem
   */
  checkLink() {
    if (this.props.item.link_interno) {
      return <LinkComponent className="list-card-thumbnail-item" item={this.props.item}>{this.contentItem()}</LinkComponent>;
    }

    return <div className="list-card-thumbnail-item">{this.contentItem()}</div>
  }

  /**
   * content item
   *
   * @returns
   * @memberof ListCardThumbnailItem
   */
  contentItem() {
    return <Fragment>
      {this.props.item &&
        <Fragment>
          <div className="list-card-thumbnail-item--info">
            {this.props.item.icone instanceof Object === true &&
              <p className="info">
                {this.props.item.icone.thumbnail 
                  && image(this.props.item.icone.thumbnail.alt, this.props.item.icone)}
                <span className="title">{this.textSplit(this.props.item.titulo)}</span>
              </p>}
          </div>
          <div className="list-card-thumbnail-item--image">
            {this.props.item.thumbnail instanceof Object === true &&
              image(this.props.item.thumbnail.large.alt, this.props.item.thumbnail)}
          </div>
        </Fragment>}
      </Fragment>;
  }

  /**
   * text split
   *
   * @param {*} text
   * @returns
   * @memberof ListCardThumbnailItem
   */
  textSplit(text) {
    const textS = text.toLowerCase();
    const lastWords = textS.replace('clube agostiniano ', '');

    return <Fragment>
        <span>{textS.replace(lastWords, '')}</span>
        <strong>{lastWords}</strong>
      </Fragment>;
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof ListCardThumbnailItem
   */
  render() {
    return (
      <Fade left={this.props.left} right={!this.props.left} duration={700}>
        <Fragment>{this.checkLink()}</Fragment>
      </Fade>
    )
  }
}

ListCardThumbnailItem.propTypes = {
  item: PropTypes.object.isRequired,
  left: PropTypes.bool,
}

export default ListCardThumbnailItem;