import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';

import './list-where-we-item.css';

/**
 * List where we item
 *
 * @class ListWhereWeItem
 * @extends {Component}
 */
class ListWhereWeItem extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof ListWhereWeItem
   */
  render() {
    return (
      <Fade bottom>
        <div className="list-where-we-item">
          <div className="list-where-we-item--title">
            <div className="flag" data-country={this.props.nome_pais.toLowerCase()}>
              <span></span>
            </div>

            <p className="country-name">{this.props.nome_pais}</p>
          </div>

          {this.props.lista &&
            <Fade bottom cascade duration={700}>
              <ul className="list-where-we-item--list">
                {this.props.lista.map((item, index) => <li className="list-where-we-item--list--item" key={index}>
                  <p className="info">
                    <span className="unity">{item.unidade}</span>
                    <span className="city">{item.cidade}</span>
                    <span className="state">{item.estado}</span>
                  </p>
                </li>)}
              </ul>
            </Fade>}
        </div>
      </Fade>
    )
  }
}

ListWhereWeItem.propTypes = {
  nome_pais: PropTypes.string.isRequired,
  lista: PropTypes.array.isRequired,
}

export default ListWhereWeItem;