import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DownloadFile from '../../DownloadFile';

import './documents-item.css';

/**
 * Document item
 *
 * @class DocumentItem
 * @extends {Component}
 */
class DocumentItem extends Component {



  /**
   * render
   *
   * @returns JSX
   * @memberof DocumentItem
   */
  render() {
    return (
      <DownloadFile className="documents-item" name={this.props.title} url={this.props.url} extension={this.props.extension}>
        <div className="documents-item--icon">
          <span className="icon icon-download"></span>
        </div>

        <p className="documents-item--info">
          <span className="name">{this.props.title}</span>
          <span className="size">{this.props.size}</span>
          <span className="extension">{this.props.extension}</span>
        </p>
      </DownloadFile>
    )
  }
}

DocumentItem.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
  extension: PropTypes.string,
}

export default DocumentItem