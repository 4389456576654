import React, { useState, Fragment } from "react";

import Header from "Layout/Header";
import Footer from "Layout/Footer";
import Loader from "components/Loader";
import Main from "Layout/Main";

import SearchMenu from "components/SearchMenu";

type HomePageProps = {
  context: any;
  location: any;
};

export default function HomePage(props: HomePageProps) {
  const [search, setSearch] = useState(false);

  function onSearchActive() {
    setSearch(!search);
  }

  return (
    <Fragment>
      <Loader location={props.location} />

      <Header
        location={props.location}
        menu={props.context.state.menu}
        submenu={props.context.state.submenu}
        onSearch={onSearchActive}
        resize={props.context.state.resize}
        top={props.context.state.topPosition}
      />

      <Main
        location={props.location}
        menu={props.context.state.menu}
        page={props.context.state.page}
        resize={props.context.state.resize}
        routes={props.context.state.routes}
        onGetPage={props.context.onGetPage}
      />

      <Footer routes={props.context.state.routes} />

      <div className="app--search-component" data-active={search}>
        <SearchMenu search={search} onSearch={onSearchActive} />
      </div>
    </Fragment>
  );
}
