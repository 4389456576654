import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MasonryGrid from '../MansoryGrid';
import ListWhereWeItem from './ListWhereWeItem';

import './list-where-we.css';

/**
 * List where we
 *
 * @class ListWhereWe
 * @extends {Component}
 */
class ListWhereWe extends Component {
  /**
   * Creates an instance of ListWhereWe.
   * 
   * @param {*} props
   * @memberof ListWhereWe
   */
  constructor(props) {
    super(props);

    this.state = {
      items: []
    };
  }

  /**
   * component did mount
   *
   * @memberof ListWhereWe
   */
  componentDidMount() {
    this.refactoryItems(this.props);
  }

  /**
   * refactory items
   *
   * @param {*} props
   * @returns
   * @memberof ListWhereWe
   */
  refactoryItems(props) {
    if (!props) return false;

    const items = [];

    for (let key in props) {
      if (!isNaN(key) && parseInt(key, 10) >= 0) {
        items.push(props[key]);
      }
    }

    this.setState({
      items
    });
  }

  /**
   * render
   *
   * @returns
   * @memberof ListWhereWe
   */
  render() {
    return (
      <div className="list-where-we">
        <div className="wrapper">
          <MasonryGrid brakePoints={[640, 768, 1024]} {...this.props}>
            {this.state.items.map((item, index) => <div className="list-where-we--item" key={index}>
              <ListWhereWeItem {...item} />
              </div>)}
					</MasonryGrid>
        </div>
      </div>
    )
  }
}

ListWhereWe.propTypes = {
  any: PropTypes.any,
}

export default ListWhereWe;