import React, { useEffect, useState, } from 'react';
import Book, { BookProps } from './Book';
import $ from 'jquery';

import './bookshelf.scss';
import './fontawesome/fontawesome.css';

type BookshelfProps = {
  data: any;
  setTitle: (value: string) => void;
};

function Bookshelf({data, setTitle}: BookshelfProps) {
  const [ books, setBooks ] = useState<Array<BookProps>>([]);
  const [ scriptLoaded, setScriptLoaded ] = useState(false);
  const [ firstBook, setFirstBook ] = useState(0);

  const [ startX, setStartX ] = useState<number|null>(null);
  // eslint-disable-next-line
  const [ startY, setStartY ] = useState<number|null>(null);

  useEffect(() => {
    setTitle('Estante Virtual');
  }, [setTitle]);

  useEffect(() => {
    function setupFlipbookScript() {
      const script = document.createElement('script');
      
      //@ts-ignore
      window.jQuery = $;
      script.async = false;
      script.src = process.env.PUBLIC_URL + '/vendor/flipbook/flipbook.min.js';
      script.type = 'text/javascript';

      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
    }

    if (scriptLoaded === false) {
      setupFlipbookScript();
    }
  }, [ scriptLoaded ]);

  useEffect(() => {
    if (data?.results) {
      // clean up old flipbook elements
      const flipbookOverlay = document.getElementsByClassName('flipbook-overlay');
      for (let i = 0; i < flipbookOverlay.length; ++i) {
        flipbookOverlay[i].remove();
      }

      setBooks(data.results.slice(firstBook, firstBook + 3));
    }
  }, [ data, firstBook ]);

  function nextPage() {
    if (firstBook + 1 < data.results.length) {
      if (firstBook + 1 > data.results.length - 3) return;

      setFirstBook(firstBook + 1);
    }
  }

  function prevPage() {
    if (firstBook - 1 > -1) {
      setFirstBook(last => last - 1);
    }
  }

  function handleSwipe(e: React.TouchEvent<HTMLDivElement>) {
    if (e.type === 'touchstart' && e.changedTouches.length) {
      setStartX(e.changedTouches[0].pageX);
      setStartY(e.changedTouches[0].pageY);
    }

    if (e.type === 'touchmove' && e.changedTouches.length) {
      const diffX = e.changedTouches[0].pageX - (startX || 0);
      // const diffY = e.changedTouches[0].pageY - (startY || 0);

      const THRESHOLD = 100;
      if (Math.abs(diffX) > THRESHOLD) {
        if (diffX < 0) nextPage(); else prevPage();
      }
    }
  }

  return (
    <div className='Bookshelf'>
      <div className='Bookshelf--shelf' onTouchStart={ handleSwipe } onTouchEnd={ handleSwipe } onTouchMove={ handleSwipe }>

        {
          data?.results?.length > 3 &&
          <div className='pagination prev-page' onClick={ () => prevPage() }>
            <i className='icon icon-arrow-left' />
          </div>
        }

        {
          books.map((item, index) => {
            return (
              <Book key={ index } { ...item } />
            );
          })
        }

        {
          data?.results?.length > 3 &&
          <div className='pagination next-page' onClick={ () => nextPage() }>
            <i className='icon icon-arrow-right' />
          </div>
        }
      </div>
    </div>
  )
}

export default Bookshelf;