import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';

import './flipbook.css';
import './book.scss';

export type BookProps = {
  id: number;
  title: string;
  file: string;
  thumbnail: string;
};

function Book(props: BookProps) {
  const [ done, setDone ] = useState(false);
  const element = useRef(null);

  useEffect(() => {
    function setupBook() {
      if (element.current !== null) {
        try {
          // @ts-ignore
          $(element.current).flipBook({
            pdfUrl: props.file,
            lightBox: true,
            lightBoxBackground: 'rgba(220, 225, 229, 1)',
            assets: {
              flipMp3: process.env.PUBLIC_URL + '/vendor/flipbook/mp3/turnPage.mp3',
            },
          });

          setDone(true);
        } catch (e) {
          console.warn(e);
        }
      }
    }

    if (done === false && props.file) {
      setupBook();
    }

    return () => {
      if (done) {
        setDone(false);
      }
    }
  }, [ props.file, done ]);

  return (
    <div
      className='Book sample'
      id={ `book-${ props.id }` }
      ref={ element }
      title={ props.title }
    >
      <img className='Book--thumbnail' src={ props.thumbnail }
        alt={`Capa do livro ${ props.title }`} />
    </div>
  );
}

export default Book;