import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './footer.css';

/**
 * Footer
 *
 * @class Footer
 * @extends {Component}
 */
class Footer extends Component {
  /**
   * footer links
   *
   * @param {*} routes
   * @returns
   * @memberof Footer
   */
  footerLinks(routes) {
    if (routes instanceof Object === false) return false;

    if (routes.items) {
      const items = [];
      
      routes.items.map((item, index) => {
        if (item.navegacao === 'rodape') {
          return items.push(<Link className="link" to={'/' + item.meta.slug} key={index}>{item.title}</Link>);
        }

        return false;
      });

      return items;
    }

    return false;
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof Footer
   */
  render() {
    return (
      <footer className="footer">
        <div className="wrapper">
          <div className="footer--top">
            <div className="footer--address">
              <a className="to" href="https://goo.gl/maps/qbZghLWpLPSjNuwk7" target="_blank" rel="noopener noreferrer">
                <span className="icon icon-pointer"></span>
                <span className="text">
                  <span>Colégio Santo Agostinho</span>
                  <span>Rua José Linhares, 88 - Leblon - Rio de Janeiro</span>
                  <span>CEP: 22430-220</span>
                </span>
              </a>

              <a className="to" href="https://goo.gl/maps/6q7PMNyPUYT7KxgY8" target="_blank" rel="noopener noreferrer">
                <span className="icon icon-pointer"></span>
                <span className="text">
                  <span>Entrada Social</span>
                  <span>Rua Cupertino Durão, 75 - Leblon - Rio de Janeiro</span>
                  <span>CEP: 22441-030</span>
                </span>
              </a>

              <div className="footer--contact">
                <p className="link">
                  <span className="icon icon-phone"></span>
                  <span className="text">{'+55 (21) 3206-7850'}</span>
                </p>

                <a className="link" href="mailto:csa@csa.com.br" rel="noopener noreferrer">
                  <span className="icon icon-mail"></span>
                  <span className="text">{'csa@csa.com.br'}</span>
                </a>
              </div>
            </div>

            <div className="footer--logo">
              <span className="icon icon-logo"></span>
              <span className="icon icon-logo-text"></span>
            </div>

            <div className="footer--they">
              {this.props.routes instanceof Object && this.footerLinks(this.props.routes) }
            </div>
          </div>

          <div className="footer--bottom">
            <div className="footer--networks">
              <a className="link" href="https://www.facebook.com/csaleblon/" target="_blank" rel="noopener noreferrer">
                <span className="icon icon-facebook"></span>
              </a>
              <a className="link" href="https://www.instagram.com/csaleblon_rj/" target="_blank" rel="noopener noreferrer">
                <span className="icon icon-instagram"></span>
              </a>
              <a className="link" href="https://www.linkedin.com/school/csa-leblon/" target="_blank" rel="noopener noreferrer">
                <span className="icon icon-linkedin"></span>
              </a>
              <a className="link" href="https://www.youtube.com/channel/UC2aK9-YP-8BHAFADvLLI_Hw/feed" target="_blank" rel="noopener noreferrer">
                <span className="icon icon-youtube"></span>
              </a>
            </div>

            <div className="footer--copy">
              <p className="text">{'Copyright 2018 © - Todos os direitos reservados ao Colégio Santo Agostinho'}</p>
            </div>

            <a href="https://www.sirius.com.br/" target="_blank" rel="noopener noreferrer" className="footer--sirius">
              <img src={process.env.PUBLIC_URL + '/images/logo-sirius.png'} alt="Sirius" />
            </a>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  any: PropTypes.any,
}

export default Footer;