import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './header-top.css';

/**
 * Header Top
 *
 * @class HeaderTop
 * @extends {Component}
 */
class HeaderTop extends Component {
  /**
   * update
   *
   * @param {*} nextProps
   * @returns
   * @memberof HeaderTop
   */
  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.props) {
      return true;
    }

    return false;
  }



  /**
   * render
   *
   * @returns JSX
   * @memberof HeaderTop
   */
  render() {
    return (
      <div className="header-top" data-mobile={this.props.resize.width < 960}>
        <div className="wrapper">
          <div className="header-top--ango">
            <a className="link" href="https://www.agustinosrecoletos.com" target="_blank" rel="noopener noreferrer">
              <span className="icon icon-logo"></span>
              <span className="icon icon-logo-text"></span>
            </a>
          </div>

          <div className="header-top--ango" style={{ marginLeft: 32, marginTop: 1.5 }}>
            <a className="link" href="https://www.educarnet.org/encuentros-educar?lang=pt" target="_blank" rel="noopener noreferrer">
              <img src={ process.env.PUBLIC_URL + '/images/educar-h30.png' } alt='Logo da Rede Educativa Internacional Agostiniana Recoleta' />
            </a>
          </div>

          <div className="header-top--ango" style={{ marginLeft: 32, marginTop: 1.5 }}>
            <a className="link" href="https://recoletosstv.org/" target="_blank" rel="noopener noreferrer">
              <img src={ process.env.PUBLIC_URL + '/images/stv-h30.png' } alt='Logo da Província Santo Tomás de Vilanova' />
            </a>
          </div>

          {/* <MainContext.Consumer>
            {(context) => <HeaderLogin
                login={context.login}
                logout={context.logout}
                user={context.state.user}
                logged={context.state.logged}
                setUser={context.setUser}
                resize={context.resize} />}
          </MainContext.Consumer> */}

          {this.props.submenu &&
            <ul className="header-top--list">
              <li className="header-top--item">
                <a className='link' href='/capelavirtual'>Capela Virtual</a>
              </li>

              { /* FIXME: Remove as soon as the new restricted area is ready */ }
              <li className="header-top--item">
                <a className="link" href='http://antigo.csa.com.br/login_form'>Área restrita</a>
              </li>

              {this.props.submenu.map((item, key) => 
                <li className="header-top--item" key={key}>
                  <Link className="link" to={'/' + item.meta.slug}>{item.title}</Link>
                </li>)}
            </ul>}

          <button className="header-top--search" onClick={() => this.props.onSearch()}>
            <span className="icon icon-search"></span>
          </button>
        </div>
      </div>
    )
  }
}

HeaderTop.propTypes = {
  submenu: PropTypes.array.isRequired,
  resize: PropTypes.object.isRequired,
  onSearch: PropTypes.func,
}

export default HeaderTop;