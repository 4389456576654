import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Picture from '../Picture';

import { reactoryImagesHeader } from './../../Helpers';

import './title-page.css';

/**
 * Title page
 *
 * @class TitlePage
 * @extends {Component}
 */
class TitlePage extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof TitlePage
   */
  render() {
    return (
      <div className="title-page">
        <Picture
          className="title-page--background"
          images={reactoryImagesHeader(this.props.images)}
          alt={this.props.title} />

        {this.props.title &&
          <h1 className="title">{this.props.title}</h1>}
      </div>
    )
  }
}

TitlePage.propTypes = {
  images: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default TitlePage;