import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';

import { image } from './../../../Helpers';

import './coordination-item.css';

/**
 * coordination item
 *
 * @class CoordinationItem
 * @extends {Component}
 */
class CoordinationItem extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof CoordinationItem
   */
  render() {
    return (
      <div className="coordination-item">
        <Fade right cascade duration={600}>
          <div className="coordination-item--info">
          <h2 className="name">{this.props.nome}</h2>
          <p className="function">{this.props.funcao}</p>
          {this.props.descricao && <p className="description" dangerouslySetInnerHTML={{__html: this.props.descricao}} />}
          </div>
        </Fade>
    
        <Fade duration={650} bottom>
          <div className="coordination-item--image">
            {image(this.props.imagem.title, this.props.imagem)}
          </div>
        </Fade>
      </div>
    )
  }
}

CoordinationItem.propTypes = {
  nome: PropTypes.string.isRequired,
  funcao: PropTypes.string.isRequired,
  descricao: PropTypes.string,
  imagem: PropTypes.object.isRequired,
}

export default CoordinationItem;