import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';

import './menu-three-level.css';
import Select from '../Select';

/**
 * Menu three
 *
 * @class MenuThreeLevel
 * @extends {Component}
 */
class MenuThreeLevel extends Component {
  /**
   * Creates an instance of MenuThreeLevel.
   * 
   * @param {*} props
   * @memberof MenuThreeLevel
   */
  constructor(props) {
    super(props);

    this.onToggleMobile = this.onToggleMobile.bind(this);
  }

  /**
   * component did mount
   *
   * @memberof MenuThreeLevel
   */
  componentDidMount() {
    this.checkRouteParent();
  }

  /**
   * component did update
   *
   * @param {*} prevProps
   * @memberof MenuThreeLevel
   */
  componentDidUpdate(prevProps) {
    if (prevProps.currentRoute !== this.props.currentRoute) {
      this.checkRouteParent();
    }
  }

  /**
   * check route parent
   *
   * @returns
   * @memberof MenuThreeLevel
   */
  checkRouteParent() {
    if (!this.props.currentRoute || this.props.items.length === 0 || !this.props.items) return false;

    const routes = this.props.currentRoute.slug.split('/');
    
    if (routes.length === 1) {
      // User got to starting page, we then redirect  to first submenu item
      if (this.props.items[0].meta.slug.startsWith(routes[0])) 
        this.props.history.replace('/' + this.props.items[0].meta.slug);
      return;
    }

    if (routes.length === 2 && this.props.items.length >= 3) {
      this.props.history.replace('/' + this.props.items[0].meta.slug);
    }
  }

  /**
   * check current route
   *
   * @param {*} item
   * @returns
   * @memberof MenuThreeLevel
   */
  checkCurrentRoute(item) {
    if (this.props.currentRoute.slug === item.meta.slug) {
      return <p className="link active">{item.title}</p>;
    }

    return <Link className="link" to={'/' + item.meta.slug}>{item.title}</Link>;
  }

  /**
   * on toggle mobile
   *
   * @param {*} event
   * @memberof MenuThreeLevel
   */
  onToggleMobile(event) {
    if (event instanceof Object) {
      this.props.history.push(`/${event.target.value}`);
    }
  }

  /**
   * title
   *
   * @param {*} slug
   * @param {*} items
   * @returns
   * @memberof MenuThreeLevel
   */
  title(slug, items) {
    if (!slug || !Array.isArray(items)) return false;

    for (const item of items) {
      if (item.meta.slug === slug) {
        return item.title;
      }
    }
  }
  
  /**
   * render
   *
   * @returns JSX
   * @memberof MenuThreeLevel
   */
  render() {
    if (this.props.items.length === 0) return <></>;

    const items = this.props.items.map(item => {
      return {
        value: item.meta.slug,
        display_name: item.title,
      };
    });

    let verifyURL = () => {
      let currentPage = this.props.location.pathname;

      if(currentPage.includes('a-vida')){
        return 'A vida';
      }

      else if(currentPage.includes('linha-do-tempo')){
        return 'Linha do tempo';
      }

      else if(currentPage.includes('lugares-importantes')){
        return 'Lugares importantes';
      }

      else if(currentPage.includes('pessoas-importantes')){
        return 'Pessoas importantes';
      }

      else if(currentPage.includes('obras')){
        return 'Obras';
      }

      else if(currentPage.includes('visao-geral')){
        return 'Visão geral';
      }

      else if(currentPage.includes('circulares')){
        return 'Circulares';
      }

      else if(currentPage.includes('material-escolar')){
        return 'Material escolar';
      }

      else if(currentPage.includes('clubes-agostinianos')){
        return 'Clubes agostinianos';
      }

      else if(currentPage.includes('noticias')){
        return 'Notícias';
      }

      return 'A vida';
    }

    return (
      <div className="menu-three-level">
        <div className="wrapper">
          {this.props.items &&
            <ul className="menu-three-level--list">
              {this.props.items.map((item, index) =>
                <li className="menu-three-level--item" key={index}>
                  {this.checkCurrentRoute(item)}
                </li>)}
            </ul>}

          <div className="menu-three-level--mobile">
            <Select text={items[0].display_name} value={verifyURL()} values={items} required={false} onToggle={this.onToggleMobile} />
          </div>

          <h1 className="menu-three-level--title">{this.title(this.props.currentRoute.slug, this.props.items)}</h1>
        </div>
      </div>
    )
  }
}

MenuThreeLevel.propTypes = {
  currentRoute: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
}

export default withRouter(MenuThreeLevel);