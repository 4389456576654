import React from 'react';
import { Link } from 'react-router-dom';

import './menu.css';


type MenuProps = {
  baseLocation: string
};

export default function Menu(props: MenuProps) {
  return (
    <nav className="main-menu">
      <ul>
        <li className="main-menu--item" title='Evangelho do dia'>
          <Link to={ props.baseLocation + '/evangelhos'}>
            <div>
              <img className="main-menu--item--icon" alt='Bíblia'
                src={process.env.PUBLIC_URL + '/images/capelavirtual/biblia.png'} />
              <span>Evangelho do dia</span>
            </div>
          </Link>
        </li>

        <li className="main-menu--item" title='Orações'>
          <Link to={ props.baseLocation + '/oracoes'}>
            <div>
              <img className="main-menu--item--icon" alt='Mãos Orantes'
                src={process.env.PUBLIC_URL + '/images/capelavirtual/maos_orantes.png'} />
              <span>Orações</span>
            </div>
          </Link>
        </li>

        <li className="main-menu--item" title='Acenda uma vela'>
          <Link to={ props.baseLocation + '/velas'}>
            <div>
              <img className="main-menu--item--icon" alt='Acenda uma vela'
                src={process.env.PUBLIC_URL + '/images/capelavirtual/vela.png'} />
              <span>Acenda uma vela</span>
            </div>
          </Link>
        </li>

        <li className="main-menu--item" title='Estante Virtual'>
          <Link to={ props.baseLocation + '/estante'}>
            <div>
              <img className="main-menu--item--icon" alt='Estante Virtual'
                src={process.env.PUBLIC_URL + '/images/capelavirtual/livros.png'} />
              <span>Estante Virtual</span>
            </div>
          </Link>
        </li>

        <li className="main-menu--item" title='Ordem Agostiniana Recoleta'>
          <a href='https://www.agustinosrecoletos.com/'>
            <div>
              <img className="main-menu--item--icon" alt='Ordem Agostiniana Recoleta'
                src={process.env.PUBLIC_URL + '/images/capelavirtual/logo_ordem.png'} />
              <span>Ordem Agostiniana Recoleta</span>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  );
}