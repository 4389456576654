import React, { Component } from 'react';
import PropTypes from 'prop-types';

import dateFns from 'date-fns';

import CalendarDay from '../../CalendarDay';

import './calendar-month-days.scss';

/**
 * Calendar Month Days
 *
 * @class CalendarMonthDays
 * @extends {Component}
 */
class CalendarMonthDays extends Component {
  // should component
  shouldComponentUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories || prevProps.events !== this.props.events) {
      return true;
    }

    return false;
  }

  /**
   * render days
   *
   * @returns
   * @memberof CalendarMonthDays
   */
  renderDays() {
    const monthStart = dateFns.startOfMonth(this.props.date);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart, { weekStartsOn: 0 });
    const endDate = dateFns.endOfWeek(monthEnd);

    const rows = [];

    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        days.push(<CalendarDay categories={this.props.categories} date={day} year={this.props.year} events={this.props.events} monthStart={monthStart} key={i} />);

        day = dateFns.addDays(day, 1);
      }

      rows.push(<div className="calendar--month--rows" key={day}>{days}</div>);
      
      days = [];
    }

    return rows;
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof CalendarMonthDays
   */
  render() {
    return (
      <div className="calendar--month--days">{this.renderDays()}</div>
    )
  }
}

CalendarMonthDays.propTypes = {
  date: PropTypes.any,
  categories: PropTypes.array,
}

export default CalendarMonthDays;