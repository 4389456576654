import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import { MainContext, MainProvider } from './providers/MainProvider';
import VirtualChapel from 'routes/VirtualChapel';
import HomePage from 'routes/HomePage';

import './app.css';
import Axios from 'axios';

/**
 * App
 *
 * @class App
 * @extends {Component}
 */
class App extends Component {
  /**
   * Creates an instance of App.
   * 
   * @param {*} props
   * @memberof App
   */
  constructor(props) {
    super(props);

    this.state = {
      search: false,
    };

    this.onSearchActive = this.onSearchActive.bind(this);

    /* setup axios to add trailing slash to all URLs */
    Axios.interceptors.request.use((config) => {
      if (config.method === 'post' && config.url[config.url.length - 1] !== '/') {
        config.url += '/';
      }

      return config;
    })
  }

  /**
   * search
   */
  onSearchActive() {
    this.setState(prevState => {
      return {
        search: !prevState.search,
      };
    });
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof App
   */
  render() {
    return (
      <MainProvider { ...this.props }>
        <MainContext.Consumer>
          {(context) => {
            return (
              <Switch>
                <Route path='/capelavirtual' component={VirtualChapel} />
                <Route path="*" render={() => <HomePage location={ this.props.location } context={ context } />} />
              </Switch>
            );
          }}
        </MainContext.Consumer>
      </MainProvider>
    );
  }
}

export default withRouter(App);
