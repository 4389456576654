import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { image } from './../../../Helpers';

import './type-five.css';

/**
 * Type Five
 *
 * @class TypeFive
 * @extends {Component}
 */
class TypeFive extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof TypeFive
   */
  render() {
    return (
      <div className="history-type-five">
        <div className="wrapper">
          <div className="history-type-five--item">
            {image(this.props.titulo_1, this.props.imagem_1)}

            {this.props.getInfo &&
              <div className="content">
                {this.props.getInfo(this.props.titulo_1, this.props.descricao_1)}
              </div>}
          </div>

          <div className="history-type-five--item big">
            {image(this.props.titulo_2, this.props.imagem_2)}  

            {this.props.getInfo &&
              <div className="content">
                {this.props.getInfo(this.props.titulo_2, this.props.descricao_2)}
              </div>}
          </div>
        </div>
      </div>
    )
  }
}

TypeFive.propTypes = {
  titulo_1: PropTypes.string.isRequired,
  titulo_2: PropTypes.string.isRequired,
  imagem_1: PropTypes.object.isRequired,
  imagem_2: PropTypes.object.isRequired,
  getInfo: PropTypes.func.isRequired,
  descricao_1: PropTypes.string,
  descricao_2: PropTypes.string,
}

export default TypeFive;