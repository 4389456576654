import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { image } from './../../../Helpers';

import './type-three.css';

/**
 * Type three
 *
 * @class TypeThree
 * @extends {Component}
 */
class TypeThree extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof TypeThree
   */
  render() {
    return (
      <div className="history-type-three">
        <div className="wrapper">
          <div className="history-type-three--group">
            <div className="history-type-three--item">
              {image(this.props.titulo_1, this.props.imagem_1)}

              {this.props.getInfo &&
                <div className="content">
                  {this.props.getInfo(this.props.titulo_1, this.props.descricao_1)}
                </div>}
            </div>
            <div className="history-type-three--item">
              {this.props.getInfo &&
                <div className="content">
                  {this.props.getInfo(this.props.titulo_2, this.props.descricao_2)}
                </div>}
            </div>
          </div>
          <div className="history-type-three--item big">
            {image(this.props.titulo_3, this.props.imagem_3)}

            {this.props.getInfo &&
              <div className="content">
                {this.props.getInfo(this.props.titulo_3, this.props.descricao_3)}
              </div>}
          </div>
        </div>
      </div>
    )
  }
}

TypeThree.propTypes = {
  titulo_1: PropTypes.string.isRequired,
  imagem_1: PropTypes.object,
  titulo_2: PropTypes.string.isRequired,
  titulo_3: PropTypes.string.isRequired,
  imagem_3: PropTypes.object.isRequired,
  getInfo: PropTypes.func.isRequired,
  descricao_1: PropTypes.string,
  descricao_2: PropTypes.string,
  descricao_3: PropTypes.string,
}

export default TypeThree;