import React from "react";
import dateFns from "date-fns";
import "./pastoralHighlight.css";

const PastoralHighlight = (props: any) => {
  function getData(dateItem: string): string {
    const date = dateFns.parse(dateItem);
    const format = "DD.MM.YY";

    return dateFns.format(date, format);
  }

  const replaceUrl = (url: any) => {
    const updatedUrl = url.slice(0, -1).split("/").join("/");

    return `${updatedUrl}`;
  };

  console.log(props);

  return (
    <div className="pastoral-highlight">
      <div className="pastoral-highlight-banner">
        <img
          className="pastoral-highlight-banner-rectangle"
          src="/images/PastoralHighlight-rectangle.svg"
          alt=""
        />
        <picture>
          <source
            media="(min-width: 1440px)"
            srcSet={
              props.imagem
                ? process.env.REACT_APP_API_URL + props.imagem.large.src
                : ""
            }
          />
          <img
            src={
              props.imagem
                ? process.env.REACT_APP_API_URL + props.imagem.original.src
                : ""
            }

            alt={props.imagem.original.alt}
          />
        </picture>
        <div className="pastoral-highlight-banner-content">
          <h3>{props.titulo}</h3>
          <p>{props.descricao}</p>
          <a
            className="link btn-triangle"
            href={
              props.link_interno.url !== "/"
                ? replaceUrl(props.link_interno.url)
                : ""
            }
          >
            saiba mais
          </a>
        </div>
      </div>
      <div className="pastoral-highlight-news">
        <h3>Notícias da Pastoral</h3>
        <div className="pastoral-highlight-news-timeline">
          {props.noticias?.map((noticiasItem: any, index: any) => {
            return (
              <div
                className="pastoral-highlight-news-timeline-content"
                key={index}
              >
                <span className="pastoral-highlight-news-timeline-content-date">
                  {getData(noticiasItem.data)}
                </span>
                <span>{noticiasItem.title}</span>
                <a href={replaceUrl(noticiasItem.url)}>saiba mais</a>
              </div>
            );
          })}
        </div>

        <div className="pastoral-highlight-news-virtual">
          <h3>{props.label_capela_virtual}</h3>
          <a className="link btn-triangle" href="/capelavirtual">
            saiba mais
          </a>
        </div>
      </div>
    </div>
  );
};

export default PastoralHighlight;
