import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import axios from 'axios';

import { baseUrl, projectUrl } from './../../Helpers';

import TitlePage from '../TitlePage';

import './search.scss';

/**
 * Search
 *
 * @class Search
 * @extends {Component}
 */
class Search extends Component {
  /**
   * Creates an instance of Search.
   * 
   * @param {*} props
   * @memberof Search
   */
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  /**
   * component did mount
   *
   * @memberof Search
   */
  componentDidMount() {
    const search = localStorage.getItem('csa_search');

    if (search) {
      axios({
        url: `${baseUrl}pages/?search=${search}`,
        method: 'GET',
      }).then(res => {
        if (res.data) {
          const items = res.data.items.map(item => {
            item.meta.html_url = this.clearUlr(item.meta.html_url.replace(projectUrl, ''));
            return item;
          });

          this.setState({
            items: items,
          }, () => localStorage.setItem('csa_search', ''));
        }
      });
    }
  }

  /**
   * clear url
   *
   * @param {*} url
   * @returns
   * @memberof Search
   */
  clearUlr(url) {
    if (url[url.length -1] === '/') {
      return url.slice(0, -1);
    }

    return url;
  }

  /**
   * render
   *
   * @returns
   * @memberof Search
   */
  render() {
    const images = [
      {
        resolution: 1920,
        url: "/media/images/header_quem_somos.2e16d0ba.fill-1920x470.png",
      }, {
        resolution: 1024,
        url: "/media/images/header_quem_somos.2e16d0ba.fill-1024x192.png",
      }, {
        resolution: 768,
        url: "/media/images/header_quem_somos.2e16d0ba.fill-768x246.png",
      }, {
        resolution: 640,
        url: "/media/images/header_quem_somos.2e16d0ba.fill-640x246.png",
      }, {
        resolution: 480,
        url: "/media/images/header_quem_somos.2e16d0ba.fill-480x246.png",
      },
    ];

    return (
      <div className="search-page">
        <TitlePage title="Busca" images={images} />

        <div className="circulars">
          <div className="wrapper">
            <h1 className="search-page--title">Resultados</h1>

            {this.state.items.length > 0 && <ul className="circulars--list">
              {this.state.items.map((item, index) =>
                <li className="circulars--item" data-type={item.segmento} key={index}>
                  <NavLink className="info" to={item.meta.html_url}>
                    <p className="title">{item.title}</p>
                  </NavLink>
                </li>)}
            </ul>}

            {this.state.items.length === 0 && <p className="search-page--info">Nenhum resultado foi encontrado!</p>}
          </div>
        </div>
      </div>
    )
  }
}

Search.propTypes = {
  any: PropTypes.any,
}

export default Search;