import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';
import ListCardGallery from './../ListCardGallery';

import './list-card-item.css';

/**
 * List card item
 *
 * @class ListCardItem
 * @extends {Component}
 */
class ListCardItem extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof ListCardItem
   */
  render() {
    return (
      <Fade left={this.props.left} right={!this.props.left} duration={700}>
        {this.props.item && 
          <div className="list-card-item" data-left={this.props.left}>
            <div className="wrapper">
              <div className="list-card-item--info">
                <p className="title">{this.props.item.titulo}</p>
                <p className="subtitle">{this.props.item.subtitulo}</p>
                <p className="description">{this.props.item.resumo}</p>
              </div>

              {this.props.item.galeria && 
                <div className="list-card-item--gallery">
                  <ListCardGallery galeria={this.props.item.galeria} />
                </div>}
            </div>
          </div>}
      </Fade>
    )
  }
}

ListCardItem.propTypes = {
  item: PropTypes.object.isRequired,
  left: PropTypes.bool.isRequired,
}

export default ListCardItem;