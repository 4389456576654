import React, { useEffect, useState, } from 'react';
//@ts-ignore
import { Fade } from 'react-reveal';

import './prayers.scss';

type PrayersProps = {
  data: any,
  setTitle: (value: string) => void;
};

type PrayersListProps = {
  data: any;
  onSelect: any;
  scrollY: number;
};

type PrayerProps = {
  id: number;
  title: string;
  content: string;
  date: string;
  onClose: any;
};

function PrayersList(props: PrayersListProps) {
  return (
    <div className="PrayersList">
      <section>
        <ul className="prayers-list">
          { props.data?.results.map((data: any, index: number) => {
            return (
              <li onClick={ () => props.onSelect(data) } key={ index }>
                <h3>{ data.title }</h3>
                <p className="prayers-list--content" dangerouslySetInnerHTML={{ __html: data.content }} />
              </li>
            );
          }) }
        </ul>
      </section>
    </div>
  )
}

function PrayerShow(props: PrayerProps) {
  return (
    <Fade bottom>
      <section className="PrayerShow">
        <button className="PrayerShow--close" onClick={ () => props.onClose() }>
          Fechar oração
        </button>

        <h3>{ props.title }</h3>
        <p className="PrayerShow--content" dangerouslySetInnerHTML={{ __html: props.content }} />
      </section>
    </Fade>
  );
}

export default function Prayers({data, setTitle}: PrayersProps) {
  const [ currentPrayer, setCurrentPrayer ] = useState<PrayerProps|null>(null);
  const [ scrollY, setScrollY ] = useState(0);

  function onPrayerSelect(prayer: any) {
    setCurrentPrayer(prayer);
  }

  useEffect(() => {
    setTitle('Orações');
  }, [setTitle]);

  useEffect(() => {
    const parentElement: HTMLElement = document.getElementsByClassName('content')[0] as HTMLElement;

    parentElement.scrollTop = currentPrayer ? 0 : scrollY;
    parentElement.onscroll = (e: any) => {
      if (currentPrayer === null) {
        setScrollY(e.target.scrollTop);
      }
    }
  }, [ currentPrayer, scrollY ]);

  return (
    <div className="Prayers">
      {
        currentPrayer ?
        <PrayerShow { ...currentPrayer } onClose={ () => onPrayerSelect(null) } />
        :
        <PrayersList data={ data } onSelect={ onPrayerSelect } scrollY={ scrollY } />
      }
    </div>
  );
}