import React, { Component } from 'react';

/**
 * Header Icon Hamburguer
 *
 * @class HeaderIconHamburguer
 * @extends {Component}
 */
class HeaderIconHamburguer extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof HeaderIconHamburguer
   */
  render() {
    return (
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path className="line--1 line--debug" d="M0 40h62c13 0 6 28-4 18L35 35"></path>
        <path className="line--1" d="M0 40h62c13 0 6 28-4 18L35 35"></path>
        <path className="line--2 line--debug" d="M0 50h70"></path>
        <path className="line--2" d="M0 50h70"></path>
        <path className="line--3 line--debug" d="M0 60h62c13 0 6-28-4-18L35 65"></path>
        <path className="line--3" d="M0 60h62c13 0 6-28-4-18L35 65"></path>
      </svg>
    )
  }
}

export default HeaderIconHamburguer;