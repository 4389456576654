import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import imagesLoaded from 'imagesloaded';

/**
 * picture
 */
class Picture extends Component {
  /**
   * constructor
   * @param {*} props 
   */
  constructor(props) {
    super(props);

    this.handleSize = this.handleSize.bind(this);
  }

  /**
   * component did mount
   *
   * @memberof Picture
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);
    this.handleSize();
  }

  /**
   * loaded image
   * @param {*} image 
   */
  handleSize() {
    if (this.element instanceof Object && this.props.callback) {
      imagesLoaded(this.element, () => {
        setTimeout(() => {
          const height = this.element.offsetHeight;

          this.props.callback(height);
        }, 1000);
      });
    }
  }

  /**
   * render
   * @returns JSX
   */
  render() {
    return (
      <picture className={this.props.className} style={this.props.style}>
        {this.props.images.length > 0 && this.props.images.map((image, index) =>
          <source key={index} srcSet={process.env.PUBLIC_URL + image.url} media={image.media} />
        )}
        {this.props.images.length > 0 && 
          <img src={process.env.PUBLIC_URL + this.props.images[this.props.images.length - 1].url} alt={this.props.alt} />}
      </picture>
    );
  }
}

Picture.propTypes = {
  alt: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  callback: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.any,
};

export default Picture;
