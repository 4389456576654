import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CalendarCategories from './CalendarCategories';
import CalendarYear from './CalendarYear';

import axios from 'axios';

import './calendar.scss';

/**
 * Calendar
 *
 * @class Calendar
 * @extends {Component}
 */
class Calendar extends Component {
  /**
   * Creates an instance of Calendar.
   * 
   * @param {*} props
   * @memberof Calendar
   */
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      categories: [],
    };
  }

  /**
   * component did mount
   *
   * @memberof Calendar
   */
  componentDidMount() {
    this.onLoad(this.props.url);
  }

  /**
   * component did update
   *
   * @param {*} prevProps
   * @memberof Calendar
   */
  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.onLoad(this.props.url);
    }
  }

  /**
   * on load
   *
   * @param {*} url
   * @returns
   * @memberof Calendar
   */
  onLoad(url) {
    if (!url) return false;

    axios({
      type: 'GET',
      url: url + 'calendario',
    }).then(response => {
      if (response.data instanceof Object) {
        this.setState({
          data: response.data,
        }, () => {
          axios({
            type: 'GET',
            url: url + 'categoria/?limit=1000&offset=0',
          }).then(response => {
            if (response.data instanceof Object) {
              this.setState({
                categories: Array.from(response.data.results),
              });
            }
          });
        });
      }
    });
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof Calendar
   */
  render() {
    return (
      <div className="calendar">
        <div className="calendar--content">
          <div className="wrapper">
            <CalendarCategories categories={this.state.categories} />
            
            <CalendarYear url={this.props.url} year={this.state.data.ano} categories={this.state.categories} />
          </div>
        </div>
      </div>
    )
  }
}

Calendar.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string.isRequired,
}

export default Calendar;