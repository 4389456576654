import React from "react";
import Slider from "react-slick";
import { projectUrl } from "Helpers";

import { Link } from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./news-highlight.css";
import dateFns from "date-fns";

const NewsHighlightNew = (props: any) => {
  function getData(dateItem: string): string {
    const date = dateFns.parse(dateItem);
    const format = "DD-MM-YY";

    return dateFns.format(date, format);
  }

  const settings = {
    speed: 500,
    infinite: false,
    slidesToScroll: 1,
    swipe: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 1,
          dots: true,
          swipe: true,
        },
      },
    ],
  };

  /**
   * replace url
   *
   * @returns
   * @memberof NewsHighlightNew
   */
  const replaceUrl = (url: any) => {
    const oldUrl = url.slice(0, -1).split('/');
    const path = oldUrl[oldUrl.length - 2];
    const getNameUrl = oldUrl[oldUrl.length - 1];

    return `${path}/${getNameUrl}`;
  }

  return (
    <div className="news-highlight">
      <header className="news-highlight-header">
        <h3>Notícias</h3>
        <a href="/noticias">ver todas</a>
      </header>
      <div className="news-highlight-content">
        <Slider {...settings}>
          {props.noticias.map(
            (item: any, index: any) =>
              index < 3 && (
                <Link to={replaceUrl(item.url)} className="news-highlight-content-card" key={index}>
                  <picture>
                    <img
                      src={
                        item.imagem.original
                          ? projectUrl + item.imagem.original.src
                          : "/images/no-news-image.png"
                      }
                      alt={item.imagem.original?.alt}
                    />
                  </picture>
                  <span className="news-highlight-content-card-date">
                    {getData(item.data)}
                  </span>
                  <p className="news-highlight-content-card-text">{item.title}</p>
                </Link>
              )
          )}
        </Slider>
      </div>
    </div>
  );
};

export default NewsHighlightNew;
