import React from 'react';
import InputMask from 'react-input-mask';
import { FieldProps } from './Field';

interface CnpjFieldProps extends FieldProps {};

function CnpjField(props: CnpjFieldProps) {
  return (
    <InputMask
      name={ props.name }
      required={ props.required }
      type='tel'
      data-empty='true'
      maskChar=''
      mask='99.999.999/9999-99'
      onChange={(e: any) => props.onChange(e, 'cnpj')}
    />
  );
};

export default CnpjField;