import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Fade from 'react-reveal/Fade';
import anime from 'animejs';
import { image, getWidthHeightDoc, linkToAttr } from './../../Helpers';

import './image-text.css';

/**
 * Image text
 *
 * @class ImageText
 * @extends {Component}
 */
class ImageText extends Component {
  /**
   * Creates an instance of CardFundamental.
   * @param {*} props
   * @memberof CardFundamental
   */
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      widthSVG: 0,
    };
  }

  /**
   * component did mount
   *
   * @memberof CardFundamental
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);

    this.loaded();
  }

  /**
   * component did update
   *
   * @param {*} prevProps
   * @param {*} prevState
   * @memberof CardFundamental
   */
  componentDidUpdate(prevProps) {
    if (prevProps.resize.width !== this.props.resize.width) {
      this.loaded();
    }
  }

  /**
   * animate
   *
   * @memberof CardFundamental
   */
  animate() {
    if (this.state.widthSVG && this.state.height) {
      const path = this.element.querySelector('path');
      const d = this.typeSVG(this.typeExtract(this.props.tipo));
      const timeLine = anime.timeline();

      if (path instanceof Object === true) {
        const title = this.animateElementOpacity(this.element.querySelector('.title'));
        const description = this.animateElementOpacity(this.element.querySelector('.description'));
        const btn = this.animateElementOpacity(this.element.querySelector('.btn-triangle'));

        timeLine
          .add({
            targets: path,
            d: d,
            duration: 400,
            delay: 600,
            elasticity: 0,
            loop: false,
            easing: 'easeInCirc'
          });

        if (title instanceof Object) {
          timeLine.add(title);
        }

        if (description instanceof Object) {
          timeLine.add(description);
        }

        if (btn instanceof Object) {
          timeLine.add(btn);
        }
      }
    }
  }

  /**
   * naimate element opacity
   *
   * @param {*} element
   * @returns
   * @memberof ImageText
   */
  animateElementOpacity(element) {
    if (!element || element instanceof Object === false) return false;

    return {
      targets: element,
      duration: 300,
      opacity: [
        {
          value: 0,
          duration: 0
        }, {
          value: 1
        }
      ],
      elasticity: 0,
      easing: 'linear'
    };
  }

  /**
   * path base
   *
   * @param {*} type
   * @returns
   * @memberof CardFundamental
   */
  pathBase(type) {
    if (!type) return false;

    switch (type) {
      case 3:
        if (getWidthHeightDoc().width <= 480) {
          return <path d={`M0 0L${this.state.widthSVG} 0L${this.state.widthSVG} 0L${(this.state.widthSVG / 2) + (this.state.widthSVG * 0.1)} 0L0 0Z`} />;
        }

        return <path d={`M0 0H${(this.state.widthSVG / 2) + (this.state.widthSVG * 0.1)}L0 0L0 ${this.state.height}H0V0Z`} />;        
      case 2:
        if (getWidthHeightDoc().width <= 480) {
          return <path d={`M0 0H${this.state.widthSVG}L0 0L0 0V0Z`} transform={`translate(${this.state.widthSVG} -1) scale(-1 1)`}/>;
        }

        return <path d={`M0 0L0 ${this.state.height}L0 ${this.state.height}H0V0Z`} />;
      case 1:
      default:
        return <path d={`M0 0L0 ${this.state.height}L0 ${this.state.height}H0V0Z`} transform={`translate(${this.state.widthSVG} -1) scale(-1 1)`} />;
    }
  }

  /**
   * type svg
   *
   * @param {*} type
   * @returns
   * @memberof CardFundamental
   */
  typeSVG(type) {
    if (!type) return false;

    switch (type) {
      case 3:
        if (getWidthHeightDoc().width <= 480) {
          return `M0 0L${this.state.widthSVG} 0L${this.state.widthSVG} ${this.state.height - (this.state.height * 0.20)}L${(this.state.widthSVG / 2) + (this.state.widthSVG * 0.1)} ${this.state.height}L0 ${this.state.height - (this.state.height * 0.30)}Z`;
        }

        return `M0 0H${(this.state.widthSVG / 4) + (this.state.widthSVG * 0.25)}L${this.state.widthSVG} ${((this.state.height / 2) + (this.state.height / 6))}L${(this.state.widthSVG / 2) + (this.state.widthSVG * 0.2)} ${this.state.height}H0V0Z`;
      case 2:
        if (getWidthHeightDoc().width <= 480) {
          return `M0 0H${this.state.widthSVG + 40 }L${this.state.widthSVG - (this.state.widthSVG * 0.15)} ${this.state.height - (this.state.height * 0.2)}L0 ${this.state.height}V0Z`;
        }

        return `M0 0L${this.state.widthSVG - (this.state.widthSVG * 0.3)} ${this.state.height * 0.25}L${this.state.widthSVG} ${this.state.height}H0V0Z`;
      case 1:
      default:
        if (getWidthHeightDoc().width <= 480) {
          return `M0 0L${this.state.widthSVG - (this.state.widthSVG * 0.3)} ${this.state.height * 0.25}L${this.state.widthSVG} ${180}H0V0Z`;
        }

        return `M0 0L${this.state.widthSVG - (this.state.widthSVG * 0.3)} ${this.state.height * 0.25}L${this.state.widthSVG} ${this.state.height}H0V0Z`;
    }
  }

  /**
   * loaded
   *
   * @memberof CardFundamental
   */
  loaded() {
    const card = this.element.querySelector('.image-text--info');

    if (card instanceof Object) {
      const properties = this.props.resize.width <= 480 ? card.querySelector('.content').getBoundingClientRect() : card.getBoundingClientRect();

      this.setState(() => {
        return { height: properties.height, widthSVG: properties.width };
      }, () => this.animate());
    }
  }

  /**
   * type extract
   * 
   * @param {*} type 
   */
  typeExtract(type) {
    if (!type) return false;

    return parseInt(type.replace('tipo', ''));
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof ImageText
   */
  render() {
    return (
      <div className="image-text">
        <Fade duration={700}>
          <div className="wrapper">
            <div className="image-text--content">
              {this.props.imagem && image(this.props.titulo, this.props.imagem)}

              <div className="image-text--info" data-type={this.typeExtract(this.props.tipo)}>
                <svg height="100%" width="100%" preserveAspectRatio="none">
                  {this.pathBase(this.typeExtract(this.props.tipo))}
                </svg>
                
                <div className="content">
                  <p className="title">{this.props.titulo}</p>
                  <p className="description">{this.props.resumo}</p>
                  {this.props.link_interno &&
                    <Link className="btn-triangle" to={linkToAttr(this.props)}>{this.props.label}</Link>}

                  {this.props.link_externo &&
                    <a className="btn-triangle" href={this.props.link_externo.url} target="_blank" rel="noopener noreferrer">{this.props.label}</a>}
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  }
}

ImageText.propTypes = {
  link_interno: PropTypes.object,
  resumo: PropTypes.string,
  imagem: PropTypes.object.isRequired,
  tipo: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
}

export default ImageText;