import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import HeaderSubmenu from './../HeaderSubmenu';
import { baseUrl } from './../../../Helpers';

import axios from 'axios';
import anime from 'animejs';

import './header-item.css';

/**
 * Header Item
 *
 * @class HeaderItem
 * @extends {Component}
 */
class HeaderItem extends Component {
  /**
   * Creates an instance of HeaderSubmenu.
   * 
   * @param {*} props
   * @memberof HeaderSubmenu
   */
  constructor(props) {
    super(props);

    this.state = {
      submenuOpen: false,
      items: [],
    };
  }

  /**
   * component did mount
   *
   * @memberof HeaderSubmenu
   */
  componentDidMount() {
    this.mounted = true;
    this.element = ReactDOM.findDOMNode(this);
    this.getSubmenu();
  }

  /**
   * component will unmount
   *
   * @memberof HeaderItem
   */
  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * booms
   *
   * @param {*} item
   * @returns
   * @memberof HeaderItem
   */
  booms(item) {
    if (item instanceof Object === false) return false;
    
    if (this.state.items.length > 0) {
      const booms = ['quem-somos', 'educacao', 'novos-alunos', 'noticias', 'pastoral'];

      for (let boom of booms) {
        if (item.meta.slug === boom) {
          if (!item.mostra_sub_itens) {
            return '/' + item.meta.slug;
          }

          return this.state.items[0].meta.slug;
        }
      }
    }

    return '';
  }

  /**
   * get submenu
   *
   * @memberof HeaderSubmenu
   */
  getSubmenu() {
    return axios({
      url: `pages/?type=paginas.PaginaBase&fields=navegacao&navegacao=primaria&child_of=${this.props.item.id}`,
      baseURL: baseUrl,
      method: 'GET',
    }).then(res => {
      if (res.data && this.mounted === true) {
        if (res.data.items.length > 0) {
          return res.data.items;
        }
      }

      return false;
    }).then(items => this.getSubmenuRefactorySlug(items));
  }

  /**
   * get submenu refactory slug
   *
   * @param {*} items
   * @returns
   * @memberof HeaderItem
   */
  getSubmenuRefactorySlug(items) {
    if (!items) return false;

    const refactoryItems = items;

    for (let key in items) {
      refactoryItems[key].meta.slug = `/${this.props.item.meta.slug}/${items[key].meta.slug}`;
    }

    this.setState({
      items: refactoryItems,
    });
  }

  /**
   * on toogle submenu mobile
   *
   * @memberof HeaderItem
   */
  onToggleSubmenu() {
    this.setState((prevState) => {
      return {
        submenuOpen: !prevState.submenuOpen
      };
    }, () => {
      if (!this.props.mobile) return false;

      this.toggleAnimate(this.element.querySelector('.header-submenu'));
    });
  }

  /**
   * toggle animate
   *
   * @param {*} submenu
   * @returns
   * @memberof HeaderItem
   */
  toggleAnimate(submenu) {
    if (!submenu || !submenu instanceof Object) return false;

    if (submenu.children.length > 0) {
      const height = this.state.submenuOpen === true ? 
        submenu.children[0].getBoundingClientRect().height * submenu.children.length : 0;

      anime({
        targets: submenu,
        height: height,
        elasticity: 0.1,
        duration: 650
      });
    }
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof HeaderItem
   */
  render() {
    return (
      <Fragment>
        {this.state.items.length === 0 &&
          <NavLink className="link" strict to={'/' + this.props.item.meta.slug}>
            <span className="name">{this.props.item.title}</span>
            <span className="icon icon-arrow-right-l"></span>
          </NavLink>}
        
        {this.state.items.length > 0 && 
          <p className="link" data-active={this.props.openMobile === this.props.item}>
            <NavLink className="to" to={this.booms(this.props.item)}>
              <span className="name">{this.props.item.title}</span>
              { !this.props.item.mostra_sub_itens && <span className="icon icon-arrow-right-l"></span> }
            </NavLink>
            
            {
              this.props.item.mostra_sub_itens &&
              <span className="icon-mobile" onClick={() => this.props.onSetMobile(this.props.item)}>
                <span className="icon-plus-to-minus" data-active={this.props.openMobile === this.props.item}></span>
              </span>
            }
          </p>}
        
        {this.props.item.mostra_sub_itens && <HeaderSubmenu position={this.props.position} items={this.state.items} mobile={this.props.mobile} />}
      </Fragment>
    )
  }
}

HeaderItem.propTypes = {
  item: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired,
  position: PropTypes.string,
  onSetMobile: PropTypes.func,
}

export default HeaderItem;