import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LinkComponent from '../../LinkComponent';

import './highlights-item.css';

/**
 * Highlights item
 *
 * @class HighlightsItem
 * @extends {Component}
 */
class HighlightsItem extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof HighlightsItem
   */
  render() {
    return (
      <div className="highlights-item">
        <LinkComponent className="link item" item={this.props.item}>
          <span className="title">{this.props.item.titulo}</span>
          {this.props.item.descricao &&
            <span className="description">{this.props.item.descricao}</span>}
        </LinkComponent>
      </div>
    )
  }
}

HighlightsItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default HighlightsItem;