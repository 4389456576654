import React, { Component } from 'react';
import PropTypes from 'prop-types';

import dateFns from 'date-fns';
import pt from 'date-fns/locale/pt';

import './calendar-month-header.scss';

/**
 * Calendar month header
 *
 * @class CalendarMonthHeader
 * @extends {Component}
 */
class CalendarMonthHeader extends Component {
  /**
   * render days
   *
   * @returns JSX
   * @memberof CalendarMonthHeader
   */
  renderDays() {
    const dateFormat = "ddd";
    const days = [];
    let startDate = dateFns.startOfWeek(this.props.date, { weekStartsOn: 0 });

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="day" key={i} data-weekend={dateFns.isWeekend(dateFns.addDays(startDate, i))}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat, { locale: pt })}
        </div>
      );
    }

    return <div className="week">{days}</div>;
  }

  /**
   * render
   *
   * @returns
   * @memberof CalendarMonthHeader
   */
  render() {
    return (
      <div className="calendar--month--header">
        {this.renderDays()}
      </div>
    )
  }
}

CalendarMonthHeader.propTypes = {
  date: PropTypes.any,
}

export default CalendarMonthHeader;