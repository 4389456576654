import React from 'react';
import Content from './components/Content';
import Menu from './components/Menu';
//@ts-ignore
import { Helmet } from 'react-helmet';

import './styles.css';

export default function VirtualChapel(props: any) {
  const getBaseLocation = (): string => props.match.path;
  const getCurrentLocation = (): string => props.location.pathname;

  return (
    <section className="VirtualChapel">
      <Helmet>
        <title>CSA Leblon | Capela Virtual</title>
      </Helmet>

      <header className="header">
        <a href="https://www.csa.com.br">
          <img
            src={ process.env.PUBLIC_URL + '/images/capelavirtual/logo_csa.png' }
            alt='Águia de Hipona - Colégio Santo Agostinho' />
        </a>

        <div>
          <a href="https://www.csa.com.br">
            <h1>Colégio Santo Agostinho - Leblon</h1>
            <h3>Ordem dos Agostinianos Recoletos</h3>
          </a>
        </div>
      </header>
      
      <Content location={ getCurrentLocation() } />
      <Menu baseLocation={ getBaseLocation() } />
    </section>
  );
};
