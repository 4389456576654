import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import anime from 'animejs';

import './loader.css';

/**
 * Loader
 *
 * @class Loader
 * @extends {Component}
 */
class Loader extends Component {
  /**
   * Creates an instance of Loader.
   * @param {*} props
   * @memberof Loader
   */
  constructor(props) {
    super(props);

    this.state = {
      percent: 0,
      loading: false,
    };
  }

  /**
   * component did mount
   *
   * @memberof Loader
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);
    this.handleLocationChange();
  }

  /**
   * component did update
   *
   * @param {*} prevProps
   * @memberof Loader
   */
  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.handleLocationChange();
    }
  }

  /**
   * animate element
   *
   * @memberof Loader
   */
  animateElement() {
    if (this.element instanceof Object === false) return false;

    this.setState({
      percent: 100
    });

    anime({
      targets: this.element,
      loop: false,
      elasticity: 0,
      opacity: [{
        value: 1,
        duration: 0,
      }, {
        value: 0,
        duration: 400,
      }],
      delay: 0,
      easing: 'easeOutQuad',
      complete: () => {
        if (this.element instanceof Object) {
          this.element.classList.add('disabled');
        }

        this.setState({
          percent: 0,
          loading: false,
        });
      }
    });
  }

  /**
   * animate bar progress
   *
   * @memberof Loader
   */
  animateBarProgress() {
    const perfCheck = window.performance || false;

    if (perfCheck) {
      const perfData = window.performance.timing;

      const EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart);
      const time = parseInt((EstimatedTime/1000) % 60, 10) * 100;

      const start = 0;
      const end = 100;
      const duration = time;

      if (this.element instanceof Object) {
        this.element.classList.remove('disabled');
        this.element.removeAttribute('style');
      }
      
      this.animateValue(start, end, duration);
    }

    setTimeout(() => {
      if (this.state.loading) {
        this.setState({ loading: false });
      } else {
        this.animateElement();
      }
    }, 100);
  }

  /**
   * animate value
   *
   * @param {*} start
   * @param {*} end
   * @param {*} duration
   * @memberof Loader
   */
  animateValue(start, end, duration) {
    let current = start;

    const range = end - start;
    const increment = end > start ? 1 : -1;
    const stepTime = Math.abs(Math.floor(duration / range));
    
    const timer = setInterval(() => {
      current += increment;
    
      if (current === end) {
        clearInterval(timer);
    
        this.animateElement();
      } else {
        this.setState({
          percent: current
        });
      }
    }, stepTime);
  }

  /**
   * handle location change
   *
   * @param {*} location
   * @memberof Header
   */
  handleLocationChange() {
    this.setState({
      loading: true
    }, () => {
      this.animateBarProgress();
    });
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof Loader
   */
  render() {
    return (
      <div className="loader">
        <div className="spinner">
          <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="length" fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
          </svg>
          <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
          </svg>
          <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
          </svg>
          <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
          </svg>
        </div>
      </div>
    )
  }
}

Loader.propTypes = {
  location: PropTypes.any
}

export default Loader;