import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ListCardItem from './ListCardItem';

import './list-cards.css';

/**
 * List cards
 *
 * @class ListCards
 * @extends {Component}
 */
class ListCards extends Component {
  /**
   * Creates an instance of ListCards.
   *
   * @param {*} props
   * @memberof ListCards
   */
  constructor(props) {
    super(props);

    this.state = {
      items: []
    };
  }

  /**
   * component did mount
   *
   * @memberof ListCards
   */
  componentDidMount() {
    this.refactoryItems(this.props);
  }

  /**
   * check items
   *
   * @param {*} items
   * @returns
   * @memberof ListCards
   */
  checkItems(items) {
    if (!Array.isArray(items)) return false;

    this.setState({
      items
    });
  }

  /**
   * refactory
   *
   * @param {*} props
   * @returns
   * @memberof ListCards
   */
  refactoryItems(props) {
    if (!props) return false;

    const items = [];

    for (let key in props) {
      if (!isNaN(parseInt(key, 10))) {
        items.push(props[key]);
      }
    }

    this.checkItems(items);
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof ListCards
   */
  render() {
    return (
      <div className="list-cards">
        <ul className="list-cards--list">
          {this.state.items && this.state.items.map((item, index) => 
            <li className="list-cards--item" key={index}>
              <ListCardItem item={item} left={index % 2 === 0} />
            </li>)}
        </ul>
      </div>
    )
  }
}

ListCards.propTypes = {
  any: PropTypes.any,
}

export default ListCards;