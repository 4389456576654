import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import SwipeTouch from './../SwipeTouch';
import WebdoorControls from './WebdoorControls';
import WebdoorItem from './WebdoorItem';

import './webdoor.css';

/**
 * Webdoor
 * 
 * @class Webdoor
 * @extends {React.Component}
 */
class Webdoor extends React.Component {
  /**
   * Creates an instance of Webdoor.
   * @param {any} props 
   * @memberof Webdoor
   */
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      direction: 'prev',
      index: 0,
      current: 0,
      last: null,
      height: 0,
      width: 0,
      interval: null,
    };

    this.setWebdoorItem = this.setWebdoorItem.bind(this);
    this.setHeight = this.setHeight.bind(this);
  }

  /**
   * component did mount
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);

    this.setCurrentItem();
    this.swipeElement(this.element);
    
    if (!this.interval && this.props.transicao) {
      this.handleTransition();
    }
  }

  handleTransition() {
    this.interval = setInterval(() => {
      this.setWebdoorItem(this.state.index + 1, 'next');
    }, 5000);
  }

  /**
   * component did update
   *
   * @param {*} prevProps
   * @param {*} prevState
   * @memberof Webdoor
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lista !== this.props.lista) {
      this.setState({ current: this.props.lista[0] });
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  /**
   * set current item
   * 
   * @param {any} type 
   * @memberof Webdoor
   */
  setCurrentItem(type) {
    if (type === undefined) {
      return this.setState({ current: this.props.lista[0], });
    }

    this.setState((prevState) => {
      const current = this.props.lista[this.state.index];

      return {
        last: prevState.current,
        current: current,
      };
    });
  }

  /**
   * set webdoor item
   * @param {*} index
   * @param {*} type
   */
  setWebdoorItem(index, type) {
    this.setState({
      index: this.nextPrev(index, type),
      direction: type,
    }, () => this.setCurrentItem(type));
  }

  /**
   * next prev
   * @param {*} index
   * @param {*} type
   */
  nextPrev(index, type) {
    if (this.interval) {
      clearInterval(this.interval);
      this.handleTransition();
    }

    switch (type) {
      case 'prev':
        return index < 0 ? this.props.lista.length - 1 : index;
      case 'next':
      default:
        return index > (this.props.lista.length - 1) ? 0 : index;
    }
  }

  /**
   * set height
   *
   * @param {number} [height=0]
   * @memberof Webdoor
   */
  setHeight(height = 0) {
    this.setState({
      height
    });
  }

  /**
   * swipe element
   * @param {*} webdoor
   */
  swipeElement(webdoor) {
    if (webdoor instanceof Object) {
      const webSwipe = new SwipeTouch(webdoor, (event) => {
        if (event === 'left') {
          this.setWebdoorItem(this.state.index + 1, 'next');
        } else if (event === 'right') {
          this.setWebdoorItem(this.state.index - 1, 'prev');
        }
      });

      return webSwipe.isMobile();
    }
  }

  /**
   * render
   * @return JSX
   */
  render() {
    return (
      <section className="webdoor" data-direction={this.state.direction}>
        <div className="webdoor--content">
          {Array.isArray(this.props.lista) && this.props.lista.map((item, index) =>
            <WebdoorItem
              item={item}
              index={this.state.index}
              current={item === this.state.current}
              last={item === this.state.last}
              onSetHeight={this.setHeight}
              key={index} />)}
            
            <WebdoorControls
              index={this.state.index}
              change={this.setWebdoorItem} 
              items={this.props.lista} />
        </div>
      </section>
    );
  }
}

Webdoor.propTypes = {
  lista: PropTypes.any,
  resize: PropTypes.object.isRequired,
};

export default Webdoor;