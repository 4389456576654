import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';
import Picture from './../../Picture';
import LinkComponent from './../../LinkComponent';
import { refactoryImages } from './../../../Helpers';

import './webdoor-item.css';

/**
 * webdoor item
 * 
 * @class WebdoorItem
 * @extends {React.Component}
 */
class WebdoorItem extends Component {
  /**
   * Creates an instance of WebdoorItem.
   * 
   * @param {*} props
   * @memberof WebdoorItem
   */
  constructor(props) {
    super(props);

    this.state = {
      images: [],
    };
  }

  /**
   * component did mount
   *
   * @memberof WebdoorItem
   */
  componentDidMount() {
    this.images();
  }

  /**
   * images
   *
   * @memberof WebdoorItem
   */
  images() {
    this.setState({
      images: refactoryImages(this.props.item.imagem),
    });
  }

  /**
   * render
   * @return JSX
   */
  render() {
    return (
      <div
        className="webdoor--item"
        data-current={this.props.current}
        data-last={this.props.last}
        item={this.props.item}>
        <LinkComponent
          className="webdoor--item"
          data-current={this.props.current}
          data-last={this.props.last}
          item={this.props.item}>
          <Picture className="image" images={this.state.images} alt={this.props.item.titulo} />

          <div className="webdoor--info">
            <div className="link">
              {this.props.item && this.props.item.titulo &&
                <h1 className="title">
                  <Fade bottom when={this.props.current} delay={600}>
                    <span dangerouslySetInnerHTML={{__html: this.props.item.titulo}} />
                  </Fade>
                </h1>}
              {this.props.item && this.props.item.resumo &&
                <p className="description">
                  <Fade bottom when={this.props.current} delay={700}>
                    <span dangerouslySetInnerHTML={{__html:this.props.item.resumo}} />
                  </Fade>
                </p>}
            </div>
          </div>
        </LinkComponent>
      </div>
    );
  }
}

WebdoorItem.propTypes = {
  current: PropTypes.bool.isRequired,
  item: PropTypes.any.isRequired,
  last: PropTypes.bool.isRequired,
};

export default WebdoorItem;