import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';

import TitlePage from '../TitlePage';

import './not-found.scss';

/**
 * Not Found
 *
 * @class NotFound
 * @extends {Component}
 */
class NotFound extends Component {
  /**
   * Creates an instance of SearchMenu.
   * 
   * @param {*} props
   * @memberof SearchMenu
   */
  constructor(props) {
    super(props);

    this.state = {
      input: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * handle change
   *
   * @param {*} event
   * @memberof SearchMenu
   */
  handleChange(event) {
    this.setState({
      input: event.target.value,
    });
  }

  /**
   * on change
   *
   * @param {*} event
   * @returns
   * @memberof SearchMenu
   */
  onChange(event) {
    if (event.which === 13 || event.keyCode === 13) {
      return this.onSend();
    }

    return true;
  }

  /**
   * on send
   *
   * @returns
   * @memberof SearchMenu
   */
  onSend() {
    if (this.state.input !== '') {
      localStorage.setItem('csa_search', this.state.input);

      return this.props.history.push('/search?');
    }
  }

  /**
   * render
   *
   * @returns
   * @memberof NotFound
   */
  render() {
    const images = [
      {
        resolution: 1920,
        url: "/media/images/header_quem_somos.2e16d0ba.fill-1920x470.png",
      }, {
        resolution: 1024,
        url: "/media/images/header_quem_somos.2e16d0ba.fill-1024x192.png",
      }, {
        resolution: 768,
        url: "/media/images/header_quem_somos.2e16d0ba.fill-768x246.png",
      }, {
        resolution: 640,
        url: "/media/images/header_quem_somos.2e16d0ba.fill-640x246.png",
      }, {
        resolution: 480,
        url: "/media/images/header_quem_somos.2e16d0ba.fill-480x246.png",
      },
    ];

    return (
      <section className="content not-found">
        <TitlePage title="" images={images} />

        <div className="not-found--content">
          <div className="not-found--info">
            <h1 className="title">Página não encontrada</h1>
            <p className="description">A página que você procura pode não existir ou ter sido removida.<br/><br/>Tente usar a busca para encontrar o conteúdo que procura:</p>
            
            <form className="form">
              <label className="input-control input-icon-r">
                <input className="input-default" type="text" placeholder="Busca aqui"
                value={this.state.input}
                onChange={this.handleChange}
                onKeyPress={(e) => this.onChange(e)} />
                <span className="icon icon-search" onClick={() => this.onSend()}></span>
              </label>
            </form>
          
            <NavLink className="btn-triangle" to="/">Página inicial</NavLink>
          </div>
          
          <div className="not-found--image">
            <img src={process.env.PUBLIC_URL + '/images/not-found.svg'} alt="Not Found!" />
          </div>
        </div>
      </section>)
  }
}

NotFound.propTypes = {
  any: PropTypes.any,
}

export default withRouter(NotFound);