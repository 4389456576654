import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';

import './webdoor-controls.css';

/**
 * Webdoor controls
 *
 * @class WebdoorControls
 * @extends {Component}
 */
class WebdoorControls extends Component {
  /**
   * constructor
   * @param {*} props 
   */
  constructor(props) {
    super(props);

    this.buttonNextPrev = this.buttonNextPrev.bind(this);
  }

  /**
   * button next prev
   * @param {*} event 
   * @param {*} type 
   */
  buttonNextPrev(event, index) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    const type = index > this.props.index ? 'next' : 'prev';
    this.props.change(index, type);
  }

  /**
   * render
   * @returns JSX
   */
  render() {
    return (
      <Fade bottom cascade delay={600}>
        <div className="webdoor--controls">
          <div className="wrapper">
            {this.props.items.map((item, index) => <Fragment key={index}>
                <button onClick={(e) => this.buttonNextPrev(e, index)} data-active={this.props.index === index}>
                  <span className="icon-circle"></span>
                </button>
              </Fragment>)}
          </div>
        </div>
      </Fade>
    );
  }
}

WebdoorControls.propTypes = {
  change: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
};

export default WebdoorControls;