import React from 'react';

import './gallery-controls.scss';

type GalleryControlsProps = {
  index: number;
  legend?: string;
  max?: number;
  onChange: (index: number) => void;
};

function GalleryControls(props: GalleryControlsProps) {
  return (
    <div className='gallery--controls'>
      <button className='control left'
        onClick={ () => props.onChange(props.index - 1) }
        disabled={ props.index === 0 }
      >
        <i className="icon icon-arrow-left"></i>
      </button>

      <div className='gallery--item-info'>
        <span className='description'>{ props.legend }</span>
      </div>

      <button className='control right'
        onClick={ () => props.onChange(props.index + 1) }
        disabled={ props.index === (props.max ? (props.max - 1) : 0) }
      >
        <i className="icon icon-arrow-right"></i>
      </button>
    </div>
  );
}

export default GalleryControls;
