import React, { Component } from 'react';
import PropTypes from 'prop-types';

import dateFns from 'date-fns';

import './calendar-day.scss';

/**
 * calendar Day
 *
 * @class CalendarDay
 * @extends {Component}
 */
class CalendarDay extends Component {
  /**
   * element type
   *
   * @returns
   * @memberof CalendarDay
   */
  elementType() {
    const monthDifference = !dateFns.isSameMonth(dateFns.parse(this.props.date), this.props.monthStart);
    const today = dateFns.differenceInCalendarDays(this.props.date, dateFns.parse(Date.now()));

    if (monthDifference === true) {
      return 'day-out';
    }

    if (today === 0) {
      return "active";
    }

    return "";
  };

  /**
   * find event by day
   *
   * @param {*} events
   * @param {*} monthCurrent
   * @param {*} dayCurrent
   * @returns
   * @memberof CalendarDay
   */
  findEventByDay(events, monthCurrent, dayCurrent) {
    if (!events || events instanceof Object === false) return false;

    const items = Array.from(events).filter(event => {
      if (event instanceof Object) {
        const fixDate = event['data_inicio'].replace('-', '/');
        const date = new Date(fixDate);

        if (date) {
          const dateEvent = dateFns.parse(fixDate);
          const month = parseInt(dateFns.format(dateEvent, 'M'), 10);
          const day = parseInt(dateFns.format(dateEvent, 'D'), 10);
          const year = parseInt(dateFns.format(dateEvent, 'YYYY'), 10);

          if (!isNaN(month) && !isNaN(day) && year === this.props.year) {
            if (dayCurrent === day && monthCurrent === month && event.clone) {
              return event;
            }
          }
        }
      }

      return false;
    });

    return items;
  }

  /**
   * get category
   *
   * @param {*} id
   * @returns
   * @memberof CalendarDay
   */
  getCategory(id) {
    if (Number.isInteger(id) && this.props.categories instanceof Object) {
      const color = this.props.categories.filter(item => item.id === id);

      if (color.length >= 1 && color[0].hasOwnProperty('color')) {
        return color[0].color;
      }
    }

    return [{
      color: '#222',
    }];
  }

  /**
   * events date
   *
   * @param {*} items
   * @returns
   * @memberof CalendarDay
   */
  eventsDate(items) {
    if (items instanceof Object === false) return false;

    const day = dateFns.format(dateFns.parse(this.props.date), 'DD');
    const month = dateFns.format(dateFns.parse(this.props.date), 'MM');

    const events = this.findEventByDay(items, parseInt(month), parseInt(day));

    if (events instanceof Object && Array.isArray(events)) {
      return events.map((event, index) => <li
        className="calendar--day--events--item"
        data-date={event.data}
        data-type={event.categoria}
        key={index}>
          <div className="block" style={{backgroundColor: this.getCategory(event.categoria)}}></div>

          <p className="content">
            <span className="description">{event.descricao}</span>
          </p>
        </li>);
    }
  }

  /**
   * render
   *
   * @returns
   * @memberof CalendarDay
   */
  render() {
    return (
      <div className="calendar--day" data-type={this.elementType()} data-end={dateFns.isWeekend(this.props.date)}>
        <ul className="calendar--day--events">
          {this.eventsDate(this.props.events)}
        </ul>

        <p className="day">{dateFns.format(dateFns.parse(this.props.date), 'DD')}</p>
      </div>
    )
  }
}

CalendarDay.propTypes = {
  categories: PropTypes.array,
  date: PropTypes.object,
  events: PropTypes.any,
}

export default CalendarDay;