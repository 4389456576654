import React, { Component } from 'react';
import PropTypes from 'prop-types';

import dateFns from 'date-fns';
import pt from 'date-fns/locale/pt';

import CalendarMonthDays from './CalendarMonthDays';
import CalendarMonthHeader from './CalendarMonthHeader';

import './calendar-month.scss';

/**
 * Calendar month
 *
 * @class CalendarMonth
 * @extends {Component}
 */
class CalendarMonth extends Component {
  // should component
  shouldComponentUpdate(prevProps) {
    if (prevProps.events !== this.props.events) {
      return true;
    }

    return false;
  }

  /**
   * render
   *
   * @returns
   * @memberof CalendarMonth
   */
  render() {
    return (
      <div className="calendar--month">
        <h1 className="title">{dateFns.format(dateFns.parse(this.props.date), 'MMMM', { locale: pt })}</h1>
        <CalendarMonthHeader date={this.props.date} />
        
        <CalendarMonthDays year={this.props.year} categories={this.props.categories} date={this.props.date} events={this.props.events} />
      </div>
      );
  }
}

CalendarMonth.propTypes = {
  date: PropTypes.object,
  categories: PropTypes.array,
  events: PropTypes.any,
}

export default CalendarMonth;