import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import Select from '../Select';
import { formUrl } from '../../Helpers';

import './forms-csa.scss';

// form csa
class FormsCSA extends Component {
  // creates an instance of Forms.
  constructor(props) {
    super(props);
    
    this.state = {
      form: {
        actions: [],
        method: '',
      },
      formValid: true,
      success: '',
    };
    
    this.url = `${formUrl}interessados/`;

    this.changeChoice = this.changeChoice.bind(this);
  }

  // change choice
  changeChoice(event) {
    if (!event || event instanceof Object === false) return false;

    const element = event.target.parentNode;
   
    if (element instanceof Object) {
      element.setAttribute('data-error', '');
    }
  }

  // check form inputs
  checkFormInputs(form) {
    if (!form instanceof Object) return false;
    
    let checkForm = true;

    form.querySelectorAll('[name]').forEach(item => {
      if (item.validity.valid === false || item.value === '') {
        checkForm = false;
      }
    });

    return checkForm;
  }

  // check value
  checkValue(event, type) {
    if (!event) return false;

    let regex = null;

    switch (type) {
      case 'email':
        regex = new RegExp('^[a-z0-9]+(\\.[_a-z0-9]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,15})$', 'i');
      break;
      case 'text':
      default:
        regex = new RegExp(/[^\s]+/);
      break;
    }
    
    this.checkValueSetAttribute(event.target, regex !== null ? !regex.test(event.target.value) : null);
  }

  // check value set attribute
  checkValueSetAttribute(element, check) {
    if (!element instanceof Object) return false;

    if (element.value.length === 0 || element.value === '') {
      element.setAttribute('data-empty', true);
    } else {
      element.setAttribute('data-empty', false);
    }

    element.parentNode.setAttribute('data-error', check);
  }

  // component did mount
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);

    axios({
      url: this.url,
      method: 'OPTIONS',
    }).then((e) => {
      if (e.data && e.status === 200) {
        this.factoryActions(e.data);
      } else {
        console.error('Form error!');
      }
    });
  }

  // factory
  factoryActions(data) {
    if (!data) return false;

    const formInputs = [];
    const properties = data.actions.POST;

    if (properties instanceof Object) {
      for (let key in properties) {
        const property = properties[key];

        if (property instanceof Object) {
          let input = {};

          if (property.choices && property.choices.length) {
            input = {
              label: property.label,
              name: key,
              required: property.required,
              choices: property.choices,
              type: 'choices',
            }
          } else {
            input = {
              label: property.label,
              name: key,
              max_length: property.maxLength,
              required: property.required,
              pattern: property.pattern,
              type: property.type,
            }
          }
          
          formInputs.push(input);
        }
      }

      this.setForm(formInputs);
    }
  }

  // get input
  getInput(item) {
    if (!item instanceof Object) return false;

    return this.getInputType(item, item.max_length);
  }

  // get input type
  getInputType(item, maxLength) {
    if (!item instanceof Object) return false;
    
    switch (item.type) {
      default:
      case 'string':
        if (item.name === 'email') {
          return <input
            name={item.name}
            type="text"
            required={item.required}
            onChange={(e) => this.checkValue(e, 'email')} />;
        }

        return <input
            type="text"
            data-empty="true"
            name={item.name}
            required={item.required}
            maxLength={maxLength}
            onChange={(e) => this.checkValue(e, 'text')} />;

      case 'choices':
        if (item.choices) {
          return <Select
            name={item.name}
            required={item.required}
            text={item.label}
            value=""
            onToggle={this.changeChoice}
            values={item.choices}
            placeholder={"*"}
          />;
        }
    }
  }

  // set form
  setForm(formInputs) {
    if (!formInputs) return false;

    this.setState({
      form: {
        actions: formInputs,
        method: 'POST',
      }
    });
  }

  // send
  sendEventSubmit(event) {
    event.preventDefault();

    this.sendEvent(this.checkFormInputs(this.element.querySelector('form')));
  }

  // send event
  sendEvent(check) {
    const form = this.element.querySelector('form');

    this.setState({
      formValid: check,
    }, () => {
      if (this.state.formValid === true) {
        const formData = new FormData(form);

        axios({
          url: this.url,
          method: this.state.form.method,
          data: formData
        }).then(({ data, status }) => {
          if (data && (status === 201 || status === 200)) {
            form.querySelectorAll('[name]').forEach(item => {
              item.value = '';
              item.setAttribute('data-empty', true);
            });

            this.setState({
              success: 'Sua informação foi enviada com sucesso!',
            });
          } else {
            console.error('Form error!', data);
          }
        });
      } else {
        const inputs = Array.from(form.querySelectorAll('[name]'));

        if (inputs.length > 0) {
          inputs.map(item => item.parentNode.setAttribute('data-error', item.validity.valid === false));
        }
      }
    });
  }

  // render
  render() {
    return (
      <div className="forms" data-success={this.state.success !== '' ? true : false}>
        <div className="wrapper">
          <form className='forms--form csa' autoComplete="off" noValidate>
            {this.state.form.actions &&
              <div className="forms--content">
                {this.state.form.actions.map((item, index) => 
                  <label className="input-control" key={index}>
                    {this.getInput(item)}

                    {item.type !== 'choice' &&
                      <label className="label">
                        <span>{item.required ? '*' : ''}</span>{item.label}
                      </label>}
                  </label>)}
              </div>}

            <div className="forms--content--error" style={{display: this.state.formValid === true ? 'none' : 'inline-block'}}>
              <p className="text">Por favor verifique os campos.</p>
            </div>

            <div className="forms--content--send">
              <button className="btn-triangle" onClick={(e) => this.sendEventSubmit(e)}>enviar</button>
            </div>
          </form>

          <div className="forms--success">
            <p className="text">{this.state.success}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default FormsCSA;