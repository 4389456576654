import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ThreeImageItem from './ThreeImageItem';

import './three-images.css';

/**
 * Three images
 *
 * @class ThreeImages
 * @extends {Component}
 */
class ThreeImages extends Component {
  /**
   * Creates an instance of ThreeImages.
   * 
   * @param {*} props
   * @memberof ThreeImages
   */
  constructor(props) {
    super(props);

    this.state = {
      items: []
    };
  }

  /**
   * component did mount
   *
   * @memberof ThreeImages
   */
  componentDidMount() {
    this.searchItems(this.props);
  }

  /**
   * component did update
   *
   * @param {*} prevProps
   * @memberof ThreeImages
   */
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.searchItems(this.props);
    }
  }

  /**
   * should component update
   *
   * @param {*} nextProps
   * @returns
   * @memberof ThreeImages
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState.items !== this.state.items) {
      return true;
    }

    return false;
  }

  /**
   * create item
   *
   * @param {*} key
   * @returns
   * @memberof ThreeImages
   */
  createItem(key) {
    if (!key) return false;

    const item = {
      image: this.props[`imagem_${key}`],
      link: this.props[`link_interno_${key}`],
      title: this.props[`titulo_${key}`],
    };

    return item;
  }

  /**
   * refactory items
   *
   * @param {*} items
   * @returns
   * @memberof ThreeImages
   */
  refactoryItems(items) {
    if (!Array.isArray(items)) return false;

    const arrayItems = [];

    for (let keyName of items) {
      const item = this.createItem(keyName);

      if (item instanceof Object) {
        arrayItems.push(item);
      }
    }

    this.setState({
      items: arrayItems,
    });
  }

  /**
   * search items
   *
   * @param {*} values
   * @memberof ThreeImages
   */
  searchItems(values) {
    const items = [];

    for (let key in values) {
      if (key.search('titulo_') !== -1) {
        items.push(parseInt(key.replace('titulo_', ''), 10));
      }
    }

    this.refactoryItems(items.sort());
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof ThreeImages
   */
  render() {
    return (
      <div className="three-images">
        <ul className="three-images--list">
          {this.state.items.map((item, key) =>
            <li className="three-images--list--item" key={key}>
              <ThreeImageItem item={item} />
            </li>
          )}
        </ul>
      </div>
    )
  }
}

ThreeImages.propTypes = {
  value: PropTypes.any,
}

export default ThreeImages;