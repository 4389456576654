import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './mansory-grid.css';

/**
 * Mansory grid
 *
 * @class MasonryGrid
 * @extends {Component}
 */
class MasonryGrid extends Component {
 /**
  * Creates an instance of MasonryGrid.
  * 
  * @param {*} props
  * @memberof MasonryGrid
  */
 constructor(props){
    super(props);
    
		this.state = {
      columns: 1
    };
  }

  /**
   * component did mount
   *
   * @memberof MasonryGrid
   */
  componentDidMount() {
    this.onResize();
  }
  
 /**
  * component did update
  *
  * @param {*} prevProps
  * @memberof MasonryGrid
  */
 componentDidUpdate(prevProps) {
		if (prevProps.resize.width !== this.props.resize.width) {
      this.onResize();
    }
	}
  
 /**
  * get columns
  *
  * @param {*} w
  * @returns
  * @memberof MasonryGrid
  */
 getColumns(w) {
		return this.props.brakePoints.reduceRight((p, c, i) => {
			return c < w ? p : i;
		}, this.props.brakePoints.length) + 1;
	}
  
 /**
  * on resize
  *
  * @memberof MasonryGrid
  */
 onResize(){
    const columns = this.getColumns(this.refs.Masonry.offsetWidth);
    
		if(columns !== this.state.columns) {
			this.setState({ columns: columns });	
		}
	}
  
 /**
  * map children
  *
  * @returns
  * @memberof MasonryGrid
  */
 mapChildren(){
		let col = [];
    const numC = this.state.columns;
    
		for (let i = 0; i < numC; i++){
			col.push([]);
    }
    
		return this.props.children.reduce((p, c, i) => {
			p[i%numC].push(c);
			return p;
		}, col);
	}
  
 /**
  * render
  *
  * @returns JSX
  * @memberof MasonryGrid
  */
 render(){
		return (
			<div className="masonry" ref="Masonry">
				{this.mapChildren().map((col, ci) => <div className="column" key={ci}>
          {col.map((child, i) => {
            return <div key={i} >{child}</div>
          })}
        </div>)}
			</div>
		)
	}
}

MasonryGrid.propTypes = {
  resize: PropTypes.object.isRequired,
}

export default MasonryGrid;