import React, { Component } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

import { baseUrl } from "./../../Helpers";

import CircularItem from "./CircularItem";

import "./circulars.scss";

/**
 * Circulars
 *
 * @class Circulars
 * @extends {Component}
 */
class Circulars extends Component {
  /**
   * Creates an instance of Circulars.
   *
   * @param {*} props
   * @memberof Circulars
   */
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      array_filter: [{}],
      filters_segmento: [],
      current_segmento: "todos",
      controls: false,
      hasMore: true,
      length: 0,
      limit: 20,
      offset: 0,
      segment: "",
    };

    this.onLoad = this.onLoad.bind(this);
    this.onSegment = this.onSegment.bind(this);
  }

  /**
   * component did mount
   *
   * @memberof Circulars
   */
  componentDidMount() {
    this.setState({
      segment:
        this.props.segmento instanceof Object
          ? this.props.segmento.target.value
          : "",
    });

    this.onLoad();
  }

  /**
   * find controls
   *
   * @memberof Circulars
   */
  findControls() {
    if (this.props.segmento === "") {
      this.setState({
        controls: true,
      });
    }
  }

  /**
   * on load
   *
   * @returns
   * @memberof Circulars
   */
  onLoad() {
    if (!baseUrl) return false;

    const data = this.onLoadSegment();

    const circularsRequest = axios({ type: "GET", url: `${data.url}&segmento=${this.state.current_segmento}` });
    const filtersCircularsRequest = axios({ method: 'GET', url: `${baseUrl}circulares/` })

    axios.all([circularsRequest, filtersCircularsRequest]).then(
      axios.spread((...responses) => {
        const circulars = responses[0];
        const filtersCirculars = responses[1];

        if (circulars.data instanceof Object) {
          this.setState(
            (prevState) => {
              return {
                data: this.orderItems(
                  prevState.data.concat(circulars.data.results)
                ),
                length: circulars.data.count,
              };
            },
            () => {
              this.findControls();

              this.setState({
                hasMore: circulars.data.next ? true : false,
              });
            }
          );

          if (filtersCirculars.data) {
            let tempArray = filtersCirculars.data.results.map((item) => {
              return item?.segmento;
            });

            let reducedArray = [];
            tempArray.forEach((item) => {
              if (!reducedArray.includes(item)) {
                reducedArray.push(item);
              }
            })

            this.setState({
              array_filter: [
                {
                  id:1,
                  segment:"todos",
                  title:"Todos os Segmentos"
                },
                {
                  id:2,
                  segment:"fundamental_1",
                  title:"Ensino Fundamental 1"
                },
                {
                  id:3,
                  segment:"fundamental_2",
                  title:"Ensino Fundamental 2"
                },
                {
                  id:4,
                  segment:"ensino_medio",
                  title:"Ensino Médio"
                },
              ]
            })

            this.setState({
              filters_segmento: reducedArray,
            })

            let items = document.querySelectorAll(".filter--item");
            items.forEach((item) => {
              if (item.getAttribute('data-type') === `${this.state.current_segmento}`) {
                item.classList.add('active');
              }
            })
          }
        }
      })
    );
  }

  /**
   * on load segment
   *
   * @returns
   * @memberof Circulars
   */
  onLoadSegment(isFilter = false) {
    let url = "";

    const limit =
      this.state.offset + this.state.limit > this.state.length ? true : false;
    let limitCount = limit
      ? this.state.length
      : this.state.limit + this.state.offset;

    if (isFilter) {
      limitCount = 0;
    }

    this.setState({ offset: limitCount });

    if (isFilter) {
      limitCount = 20;
    }

    if (!this.state.controls && this.props.segmento) {
      url = `${baseUrl}circulares/?segmento=${this.props.segmento}&limit=${limitCount}&offset=${this.state.offset}`;
    } else if (!this.state.segment || this.state.segment === " ") {
      url = `${baseUrl}circulares/?limit=${limitCount}&offset=${this.state.offset}`;
    } else {
      url = `${baseUrl}circulares/?segmento=${this.state.segment}&limit=${limitCount}&offset=${this.state.offset}`;
    }
    return { url: url, limit: limitCount };
  }

  /**
   * on segment
   *
   * @param {*} segment
   * @memberof Circulars
   */
  onSegment(event) {
    if (event instanceof Object === false) return false;
    const segment = event.target.value;

    this.setState(
      {
        segment: segment,
        data: [],
        offset: 0,
      },
      () => this.onLoad()
    );
  }

  /**
   * order items
   *
   * @param {*} items
   * @returns
   * @memberof Circulars
   */
  orderItems(items) {
    if (items instanceof Object === false) return [];

    return items.sort(this.orderItemData);
  }

  /**
   * order item data
   *
   * @param {*} itemOne
   * @param {*} itemTwo
   * @returns
   * @memberof Circulars
   */
  orderItemData(itemOne, itemTwo) {
    const dateInit = new Date(itemOne.data.replace(/-/g, "/"));
    const dateEnd = new Date(itemTwo.data.replace(/-/g, "/"));

    if (dateInit > dateEnd) return -1;
    if (dateInit < dateEnd) return 1;

    return 0;
  }

  filterData(tags) {
    const data = this.onLoadSegment(true);

    data.url += "&tags=" + tags.join("&tags=");

    axios({
      type: "GET",
      url: data.url,
    }).then((response) => {
      if (response.data instanceof Object) {
        this.setState(
          (prevState) => {
            return {
              data: this.orderItems([].concat(response.data.results)),
              length: response.data.count,
            };
          },
          () => {
            this.findControls();

            this.setState({
              hasMore: response.data.next ? true : false,
            });
          }
        );
      }
    });
  }

  renderCircular() {
    let circulars = [];

    circulars = this.state.data;
    
    return circulars.map((item, index) => (
      <li
        className="circulars--item"
        data-active={item.segmento === this.props.segmento}
        data-type={item.segmento}
        key={index}
      >
        <CircularItem item={item} />
      </li>
    ));
  }

  /**
   * set item active
   * 
   * 
   */
  setActive(segment) {
    const items = document.querySelectorAll(".filter--item");
    const newFilterCircularsRequest = axios({ method: 'GET', url: `${baseUrl}circulares/?segmento=${segment}` });

    axios.all([newFilterCircularsRequest]).then(
      axios.spread((...responses) => {
        const filterCirculars = responses[0];

        if (filterCirculars.data) {
          this.setState({
            data: filterCirculars.data.results
          });
        }
      })
    )

    items.forEach((item) => {
      item.classList.remove('active');

      if (item.getAttribute('data-type') === `${segment}`) {
        item.classList.add('active');

        this.setState({
          current_segmento: segment
        })
      }
    })
  }

  /**
   * render
   *
   * @returns
   * @memberof Circulars
   */
  render() {
    return (
      <div className="circulars">
        <div className="wrapper">
          <div className="filter--list">
              {Array.isArray(this.state.array_filter) && this.state.array_filter.map((item, index) => 
                <div className="filter--item" key={index} data-type={item?.segment} onClick={() => this.setActive(item?.segment)}>
                  <span>{item?.title}</span>
                </div>)}
          </div>

          <ul className="circulars--list">
            <InfiniteScroll
              dataLength={this.state.data.length}
              next={this.onLoad}
              hasMore={this.state.hasMore}
              loader={<h4 className="circulars--loader">Carregando...</h4>}
            >
              {this.renderCircular()}
            </InfiniteScroll>
          </ul>
        </div>
      </div>
    );
  }
}

Circulars.propTypes = {
  segmento: PropTypes.string,
  titulo: PropTypes.string,
};

export default Circulars;
