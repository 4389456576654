import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './header-submenu.css';

/**
 * Header Submenu
 *
 * @class HeaderSubmenu
 * @extends {Component}
 */
class HeaderSubmenu extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof HeaderSubmenu
   */
  render() {
    return (
      <ul className={ `header-submenu ${this.props.position}` } data-mobile={this.props.mobile}>
        {Array.isArray(this.props.items) &&
          this.props.items.map((item, key) => <li className="header-submenu--item" key={key}>
            <NavLink className="submenu-link" to={item.meta.slug}>{item.title}</NavLink>
          </li>)}
      </ul>
    )
  }
}

HeaderSubmenu.propTypes = {
  items: PropTypes.any.isRequired,
  mobile: PropTypes.bool,
}

export default HeaderSubmenu;