import React, { ReactElement, useEffect, useState, useRef } from 'react';
import axios, { AxiosResponse } from 'axios';
//@ts-ignore
import Fade from 'react-reveal/Fade';
import { baseUrl } from 'Helpers';
import Home from '../Home';
import Gospel from '../Gospel';
import Prayers from '../Prayers';
import Candles from '../Candles';

import './content.scss';
import Bookshelf from '../Bookshelf';

type ContentProps = {
  location: string;
};

export default function Content(props: ContentProps) {
  const [ page, setPage ] = useState<AxiosResponse|null>(null);
  const [ title, setTitle ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const containerEl = useRef(null);

  async function getPage(location: string, page: number = 1) {
    setLoading(true);
  
    if (location.startsWith('/')) {
      location = location.substring(1);
    }

    try {
      const req = await axios({
        url: baseUrl + location,
        params: {
          page: page
        }
      });

      setPage(req.data);
    } catch (e) {
      setPage(null);
    } finally {
      setLoading(false);
    }
  }

  function handlePageChange(newPage: number){
    getPage(props.location, newPage);
  }

  function loadComponent(location: string): ReactElement {
      /* Since we only have three pages for now we can do it manually.
      In case this grows over time we can think of something fancier.
      */
    location = location.split('/').pop() || '';
    switch (location) {
      case 'evangelhos':
        return <Gospel data={ page } onPageChanged={ handlePageChange } setTitle={ setTitle } />
      case 'oracoes':
        return <Prayers data={ page } setTitle={ setTitle } />
      case 'velas':
        return <Candles data={ page } onPageChanged={ handlePageChange }
          location={ props.location } setTitle={ setTitle } />
      case 'estante':
        return <Bookshelf data={ page } setTitle={ setTitle } />
    }

    return <Home />;
  }

  useEffect(() => { getPage(props.location) }, [ props.location ]);

  useEffect(() => {
    loadComponent(props.location);

    if (containerEl.current) {
      const container = containerEl.current! as HTMLElement;
      container.scrollTop = 0;
    }
  }, [ page, loadComponent, props.location ]);

  return (
    <>
      <Fade spy={ props.location }>
        { title && <h1>{ title }</h1>}
        <main className='content' ref={ containerEl }>
          { loadComponent(props.location) }
          {
            loading &&
            <div className='loading'>
              <span>Carregando...</span>
            </div>
          }
        </main>
      </Fade>
    </>
  );
};