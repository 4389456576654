import React from 'react';
//@ts-ignore
import Fade from 'react-reveal/Fade';

export default function Home() {
  return (
    <div className='Home'>
      <Fade bottom>
        <p className='quote'>
          <span>
            "Maria era feliz porque ouviu a palavra de Deus e a pôs em prática; guardou mais a verdade de Cristo na sua mente do que o corpo de Cristo no seu seio".
          </span>
          <br />
          <small>Santo Agostinho (Sermões 25, 7-8)</small>
        </p>
      </Fade>
    </div>
  );
};