import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import axios from 'axios';
import { baseUrl, projectUrl } from './../../Helpers';


import './text-body.css';
import Title from '../Title';

/**
 * Text body
 *
 * @class TextBody
 * @extends {Component}
 */
class TextBody extends Component {
  /**
   * component did mount
   *
   * @memberof TextBody
   */
  componentDidMount() {
    this.mounted = true;
    this.element = ReactDOM.findDOMNode(this);

    this.linkRef();
  }

  /**
   * component will unmount
   *
   * @memberof TextBody
   */
  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * link recfactory
   *
   * @param {*} link
   * @param {*} page
   * @returns
   * @memberof TextBody
   */
  linkRecfafory(link, page) {
    if (!link instanceof Object) return false;

    axios({
      method: 'GET',
      url: `${baseUrl}pages/${page}`
    }).then((response) => {
      if (response.data) {
        if (this.mounted === true) {
          const slug = response.data.meta.slug;

          if (slug) {
            link.setAttribute('href', slug);
          }
        }
      }
    });
  }

  /**
   * link ref
   *
   * @memberof TextBody
   */
  linkRef() {
    const elements = this.element.querySelectorAll('a');

    if (elements.length > 0) {
      elements.forEach((item) => {
        const type = item.getAttribute('linktype');

        if (type === 'page') {
          this.linkRecfafory(item, item.getAttribute('id'));
        }
      });
    }
  }

  
  /**
   * Gambi Links docs
   *
   * @memberof TextBody
   */
  linksParser(data) {
    if (!data) return '';

    const parser = new DOMParser();

    let content = parser.parseFromString(data, "text/html");
    const links = content.getElementsByTagName('a');

    Array.from(links).forEach(item => {
      if (item.innerText.substr(item.innerText.length - 3) === 'pdf') {
        const href = item.getAttribute("href");

        item.setAttribute('download', '');
        item.href = projectUrl + href;
      } else if (item.getAttribute('href')[item.getAttribute('href').length - 1] === '/') {
        item.href = item.getAttribute('href').substring(0, item.getAttribute('href').length - 1);
      }
    });

    return content.body.innerHTML;
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof TextBody
   */
  render() {
    return (
      <div className="text-body">
        { this.props.titulo && <Title text={ this.props.titulo } /> }
        <div className="text-body--content" dangerouslySetInnerHTML={{__html: this.linksParser(this.props.corpo) }} />
      </div>
    )
  }
}

TextBody.propTypes = {
  corpo: PropTypes.string,
}

export default TextBody;