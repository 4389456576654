import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './tab-item.css';

/**
 * Tab item
 *
 * @class TabItem
 * @extends {Component}
 */
class TabItem extends Component {
  /**
   * checked url
   *
   * @param {*} url
   * @returns
   * @memberof TabItem
   */
  checkedUrl(url) {
    if (!url === true) return '/';

    return url.slice(-1) === '/' ? url.substring(0, url.length - 1) : url;
  }

  /**
   * split title
   * 
   * @param {*} title 
   */
  splitTitle(title) {
    if (!title) return false;

    const arrayText = title.split(' ');

    return <Fragment>
      {arrayText.map((item, key) => <span className={key > 1 || key === (arrayText.length - 1) ? 'last' : ''} key={key}>{item}&nbsp;</span>)}
      </Fragment>;
  }
  
  /**
   * render
   *
   * @returns JSX
   * @memberof TabItem
   */
  render() {
    return (
      <div className={`tab-item ${this.props.keyName}`} data-active={this.props.active} data-type={this.props.index}>
        <div className="info">
          {this.props.keyName !== 'default' ?
            <p className="title">{this.splitTitle(this.props.title)}</p> :
            <p className="title" dangerouslySetInnerHTML={{__html: this.props.title}} />}

          <p className="description">{this.props.description}</p>

          {this.props.link && this.props.link?.url &&
            <Link className="link btn-triangle" to={this.checkedUrl(this.props.link?.url)}>saiba mais</Link>}
        </div>
      </div>
    )
  }
}

TabItem.propTypes = {
  active: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  // image: PropTypes.object.isRequired,
  keyName: PropTypes.string.isRequired,
  link: PropTypes.object,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}

export default TabItem;