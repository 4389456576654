import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';
import DocumentItem from './DocumentItem';

import './documents.css';

/**
 * Documents
 *
 * @class Documents
 * @extends {Component}
 */
class Documents extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof Documents
   */
  render() {
    return (
      <div className="documents">
        <div className="wrapper">
          <h1 className="title-main">
            <span>{this.props.titulo}</span>
          </h1>

          {this.props.lista &&
            <ul className="documents--list">
              {this.props.lista.map((item, index) => 
                <Fade duration={700} top key={index}>
                  <li className="documents--item">
                    <DocumentItem {...item} />
                  </li>
                </Fade>)}
            </ul>}
        </div>
      </div>
    )
  }
}

Documents.propTypes = {
  lista: PropTypes.array.isRequired,
  titulo: PropTypes.string.isRequired,
}

export default Documents;