import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ListCardThumbnailItem from './ListCardThumbnailItem';

import './list-card-thumbnail.css';

/**
 * list card thumbnail
 *
 * @class ListCardThumbnail
 * @extends {Component}
 */
class ListCardThumbnail extends Component {
  /**
   * Creates an instance of ListCardThumbnail.
   * 
   * @param {*} props
   * @memberof ListCardThumbnail
   */
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  /**
   * component did mount
   *
   * @memberof ListCardThumbnail
   */
  componentDidMount() {
    this.recfactoryItems();
  }

  /**
   * recfactory items
   *
   * @memberof ListCardThumbnail
   */
  recfactoryItems() {
    const items = [];

    for (let key in this.props) {
      if (!isNaN(parseInt(key, 0))) {
        items.push(this.props[key]);
      }
    }

    this.setState({items});
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof ListCardThumbnail
   */
  render() {
    return (
      <div className="list-card-thumbnail">
        <div className="wrapper">
          <ul className="list-card-thumbnail--list">
            {this.state.items.map((item, index) => <li className="list-card-thumbnail--item" key={index}>
                <ListCardThumbnailItem item={item} left={index % 2 === 0} />
              </li>)}
          </ul>
        </div>
      </div>
    )
  }
}

ListCardThumbnail.propTypes = {
  any: PropTypes.any,
}

export default ListCardThumbnail;