import React, { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import { TYPE_COMPONENTS } from './components';

import './content.css';

/**
 * Content
 *
 * @class Content
 * @extends {Component}
 */
class Content extends Component {
  /**
   * Creates an instance of Content.
   * @param {*} props
   * @memberof Content
   */
  constructor(props) {
    super(props);

    this.components = [];

    this.state = {
      body: [],
      page: [],
    };
  }

  /**
   * component did mount
   *
   * @memberof Content
   */
  componentDidMount() {
    this.loadPage();

    this.element = ReactDOM.findDOMNode(this);
  }

  /**
   * component did update
   * 
   * @param {*} prevProps 
   * @param {*} prevState 
   */
  componentDidUpdate(prevProps) {
    if (prevProps.page.corpo !== this.props.page.corpo) {
      this.loadPage();
    }
  }

  /**
   * component will unmount
   *
   * @memberof Content
   */
  componentWillUnmount() {
    if (this.element.childNodes.length > 0) {
      for (let item of this.element.childNodes) {
        this.element.removeChild(item);
      }
    }
  }

  /**
   * create component
   * 
   * @param {*} component 
   * @param {*} data 
   * @param {*} index 
   */
  createComponent(component, data, index = 0) {
    if (component instanceof Object) {
      const { resize } = this.props;

      const element = createElement(component.component, {
        type: component.type,
        key: index,
        ...data,
        resize,
      });

      return element;
    }

    return false;
  }

  /**
   * create header
   *
   * @param {*} keys
   * @returns
   * @memberof Content
   */
  createHeader(keys) {
    const header = {
      type: 'header',
      value: {
        title: this.props.page.title,
        images: [],
      }
    };

    if (!Array.isArray(keys) || keys.length > 0) {
      const body = this.props.page.corpo;

      for (let key of keys) {
        const keyValue = this.props.page[key];

        if (keyValue) {
          header.value.images.push({ url: keyValue.url, resolution: keyValue.width });
        }
      }

      const submenuThree = this.createSubmenuThreeLevel();

      if (submenuThree instanceof Object && submenuThree.value.items.length) {
        header.value.title = this.props.page.meta.parent.title;
        body.unshift(submenuThree);
      }

      body.unshift(header);

      return body;
    }

    return this.props.page.corpo;
  }

  /**
   * create submenu three level
   *
   * @returns
   * @memberof Content
   */
  createSubmenuThreeLevel() {
    if (!this.props.currentRoute.meta.hasOwnProperty('submenuThree')) return false;

    const submenuThreeLevel = {
      type: 'submenuThree',
      value: {
        items: this.props.currentRoute.meta.submenuThree,
        currentRoute: this.props.currentRoute.meta,
      }
    };

    return submenuThreeLevel;
  }

  /**
   * load
   *
   * @param {*} page
   * @returns
   * @memberof Content
   */
  load(page) {
    if (!page instanceof Object) return false;

    document.title = `CSA Leblon | ${page.title}`;
    return this.loadHeader(page);
  }

  /**
   * load header
   *
   * @param {*} page
   * @returns
   * @memberof Content
   */
  loadHeader(page = {}) {
    const header = [];

    for (let key in page) {
      if (key.search('imagem_do_header_') !== -1) {
        header.push(key);
      }
    }

    if (header.length > 0) {
      return this.createHeader(header);
    }

    return page.corpo;
  }

  /**
   * load page
   *
   * @memberof Content
   */
  loadPage() {
    if (this.props.page.hasOwnProperty('corpo')) {
      this.components = [];
      const page = [];

      this.setState(() => {
        const body = this.load(this.props.page);

        return {
          body: body,
        };
      }, () => {
        for (let key in this.state.body) {
          const component = this.state.body[key];
          const newComponent = TYPE_COMPONENTS.filter((item) => item.type === component.type)[0];

          if (newComponent !== undefined) {
            page.push({ component: newComponent, data: component.value, key: key });
          }
        }

        this.setState({
          page: page,
        });
      });
    }
  }

  /**
   * router class
   *
   * @param {*} router
   * @returns
   * @memberof Content
   */
  routerClass(router) {
    if (!router || !router.hasOwnProperty('meta')) return 'content-main';

    return `content-main ${router.meta.slug.replace(/([/])/g, '_')}`;
  }

  buildPageMetadata() {
    try {
      const title = this.props.page.meta?.seo_title;
      const description = this.props.page.meta?.search_description;
      const htmlURL = this.props.page.meta?.html_url;

      const currentBody = this.props.page.corpo;
      var imageURL;

      for (let i = 0; i < currentBody?.length; ++i) {
        if (currentBody[i].type === 'imagem') {
          imageURL = process.env.REACT_APP_API_URL + currentBody[i].value.imagem.large.src;
          break;
        }
      }

      return (
        <Helmet>
          <meta name="title" content={ title } />
          <meta name="description" content={ description } />
          <meta property="og:title" content={ title } />
          <meta property="og:description" content={ description } />
          <meta property="og:site_name" content="CSA Leblon" />
          <meta property="og:url" content={ htmlURL } />
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={ imageURL } />
        </Helmet>
      );
    } catch (e) {
      console.warn(`Could not build page metadata: ${ e }`)
      return <></>
    }
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof Content
   */
  render() {
    return <section className={this.routerClass(this.props.currentRoute)}>
      { this.buildPageMetadata() }
      {this.state.page.map((item) => this.createComponent(item.component, item.data, item.key))}
    </section>
  }
}

Content.propTypes = {
  resize: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  currentRoute: PropTypes.object,
}

export default Content;
