import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { image } from './../../Helpers';
import TextOut from './../History/TextOut';

import './summary-picture.css';

/**
 * Summary picture
 *
 * @class SummaryPicture
 * @extends {Component}
 */
class SummaryPicture extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof SummaryPicture
   */
  render() {
    return (
      <div className="summary-picture" data-cor={this.props.cor} data-position={this.props.tipo}>
        <div className="wrapper">
          {this.props.titulo && <h1 className="title-main">
            <span>{this.props.titulo}</span>
          </h1>}

          <div className="summary-picture--info">
            {this.props.imagem && image(this.props.titulo, this.props.imagem)}
            <div className="summary">
              <TextOut resize={this.props.resize}>
                <p className="info">{this.props.resumo}</p>
              </TextOut>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SummaryPicture.propTypes = {
  cor: PropTypes.string.isRequired,
  resumo: PropTypes.string,
  imagem: PropTypes.object.isRequired,
  tipo: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
}

export default SummaryPicture;