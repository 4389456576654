import React, { useEffect, useState } from 'react';
import { image } from 'Helpers';

import FullscreenGallery from './FullscreenGallery';
import { GalleryImageProps } from 'components/Gallery/types';

import './gallery.scss';
import GalleryControls from './GalleryControls';

type SimpleGalleryProps = {
  galeria: Array<GalleryImageProps>;
  transicao: boolean;
};


function SimpleGallery(props: SimpleGalleryProps) {
  const [ currentImage, setCurrentImage ] = useState<GalleryImageProps|null>(null);
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const [ fullScreen, setFullscreen ] = useState(false);

  function goTo(newIndex: number) {
    if (newIndex > -1 && newIndex < props.galeria.length) {
      setCurrentIndex(newIndex);
    }
  }

  useEffect(() => {
    if (!currentImage && props.galeria.length) {
      setCurrentImage(props.galeria[0]);
    }
  }, [ currentImage, props.galeria ]);

  useEffect(() => {
    setCurrentImage(props.galeria[currentIndex]);
  }, [ currentIndex, props.galeria ]);

  function toggleFullscreen(index: number) {
    setFullscreen(!fullScreen);
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft': goTo(currentIndex - 1);
        break;
      case 'ArrowRight': goTo(currentIndex + 1);
        break;
      case 'Escape': setFullscreen(false);
        break;
      default:
        return;
    }
  }

  return (
    <div className='gallery' onKeyDown={ handleKeyDown } tabIndex={ 0 }>
      { fullScreen &&
        <FullscreenGallery
          images={ props.galeria }
          onClose={ () => setFullscreen(false) }
          onChange={ (index) => setCurrentIndex(index) }
          index={ currentIndex }
        />
      }

      <ul>
        {
          props.galeria.map((item, index) => {
            return (
              <li className='gallery--item'
                key={ index }
                data-current={ item === currentImage }
                data-last={ index === props.galeria.length - 1 }
                onClick={ () => toggleFullscreen(index) }
              >
                { image(item.legenda, item.foto) }
              </li>
            );
          }) 
        }
      </ul>
      
      {
        /* hide the gallery controls while on fullscreen so it
        doesnt create a confusing experience for users since the fullscreen controls
        will take precedence. */
        !fullScreen &&
        <GalleryControls
          index={ currentIndex }
          legend={ currentImage?.legenda }
          onChange={ goTo }
          max={ props.galeria.length }
        />
      }
    </div>
  );
}

export default SimpleGallery;