import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import HighlightsItem from './HighlightsItem';
import HighlightsSliderControls from './HighlightsSliderControls';

import './highlights-slider.css';

/**
 * Highlight slider
 *
 * @class HighlightsSlider
 * @extends {Component}
 */
class HighlightsSlider extends Component {
  /**
   * Creates an instance of HighlightsSlider.
   * 
   * @param {*} props
   * @memberof HighlightsSlider
   */
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      items: [],
      limit: 3,
      counter: 0
    };

    this.onLoaded = this.onLoaded.bind(this);
    this.nextPrev = this.nextPrev.bind(this);
  }

  /**
   * component did mount
   *
   * @memberof HighlightsSlider
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);
    this.properties = this.element.getBoundingClientRect();

    this.onLoaded();

    const items = this.props.lista.map((item, index) => {
      item.type = index;

      return item;
    });

    items.push(this.props.lista[2]);

    this.setState({
      items: items
    });
  }

  /**
   * component did update
   *
   * @param {*} prevProps
   * @memberof HighlightsSlider
   */
  componentDidUpdate(prevProps) {
    if (prevProps.resize !== this.props.resize) {
      this.onLoaded();
    }
  }

  /**
   * animation items
   *
   * @param {*} direction
   * @memberof HighlightsSlider
   */
  animationItems(direction) {
    const current = this.element.querySelector('.highlights-slider--item[data-current="true"]');
    const mobile = this.mobileType();

    if (mobile === true) {
      if (current instanceof Object) {
        if (current instanceof Object) {
          if (direction === 'next') {
            const previous = this.animationNextElement(current, 'next');
            this.animateItemsMobile(current, previous, direction);
          } else {
            const next = this.animationNextElement(current, 'prev');
            this.animateItemsMobile(current, next, direction);
          }
        }
      }
    } else { 
      if (current instanceof Object) {
        if (direction === 'next') {
          const next = this.animationNextElement(current, 'prev');
          const nextNext = this.animationNextElement(next, 'prev');
          const previous = this.animationNextElement(current, 'next');

          this.animateItems(current, previous, next, nextNext, direction);
        } else {
          const next = this.animationNextElement(current, direction);
          const nextNext = this.animationNextElement(next, direction);
          const previous = this.animationNextElement(nextNext, direction);

          this.animateItems(current, previous, next, nextNext, direction);
        }
      }
    }
  }

  /**
   * animate items
   *
   * @param {*} current
   * @param {*} prev
   * @param {*} next
   * @param {*} nextN
   * @param {*} direction
   * @memberof HighlightsSlider
   */
  animateItems(current, prev, next, nextN, direction) {
    if (direction === 'next') {
      this.animationItem(null, 'nextIn', current);
      this.animationItem(null, 'nextNext', next);
      this.animationItem(null, 'nextNextNext', nextN);
      this.animationItem(null, 'nextOut', prev);

      this.animationItem(this.state.counter + 1, 'nextIn');
      this.animationItem(this.nextItem(this.state.counter, 5) + 1, 'nextNext');
      this.animationItem(this.nextItem(this.nextItem(this.state.counter, 5), 5) + 1, 'nextNextNext');
      this.animationItem(this.prevItem(this.state.counter, 5) + 1, 'nextOut');
    } else {
      this.animationItem(null, 'prevIn', current);
      this.animationItem(null, 'prevNext', next);
      this.animationItem(null, 'prevNextNext', nextN);
      this.animationItem(null, 'prevOut', prev);

      this.animationItem(this.state.counter + 1, 'prevIn');
      this.animationItem(this.nextItem(this.state.counter, 5) + 1, 'prevNext');
      this.animationItem(this.nextItem(this.nextItem(this.state.counter, 5), 5) + 1, 'prevNextNext');
      this.animationItem(this.nextItem(this.nextItem(this.nextItem(this.state.counter, 5), 5) ,5) + 1, 'prevOut');
    }
  }

  /**
   * animate mobile
   *
   * @param {*} current
   * @param {*} prev
   * @param {*} direction
   * @memberof HighlightsSlider
   */
  animateItemsMobile(current, prev, direction) {
    if (direction === 'next') {
      this.animationItem(null, 'nextInMobile', current);
      this.animationItem(null, 'nextOutMobile', prev);

      this.animationItem(this.state.counter + 1, 'nextInMobile');
      this.animationItem(this.prevItem(this.state.counter, 5) + 1, 'nextOutMobile');
    } else {
      this.animationItem(null, 'prevInMobile', current);
      this.animationItem(null, 'prevOutMobile', prev);

      this.animationItem(this.state.counter + 1, 'prevInMobile');
      this.animationItem(this.nextItem(this.state.counter, 5) + 1, 'prevOutMobile');
    }
  }

  /**
   * add class name
   *
   * @param {*} counter
   * @param {*} animationClassName
   * @param {*} elementNode
   * @memberof HighlightsSlider
   */
  animationItem(counter, animationClassName, elementNode) {
    const element = elementNode instanceof Object ? 
      elementNode : this.element.querySelector(`.highlights-slider--bg-svg .svg:nth-child(${counter})`);

    if (element instanceof Object) {
      if (this.animationItemRemoveClassName(element));
        element.classList.add(animationClassName);
    }
  }

  /**
   * animation item remove class
   *
   * @param {*} element
   * @returns
   * @memberof HighlightsSlider
   */
  animationItemRemoveClassName(element) {
    if (!element instanceof Object && !element.classList) return false;

    return element.classList.remove('prevIn', 
      'prevNext', 
      'prevNextNext', 
      'prevOut', 
      'nextIn', 
      'nextNext', 
      'nextNextNext', 
      'nextOut',
      'nextInMobile',
      'nextOutMobile',
      'prevInMobile',
      'prevOutMobile');
  }

  /**
   * animation next element
   *
   * @param {*} element
   * @param {*} type
   * @returns
   * @memberof HighlightsSlider
   */
  animationNextElement(element, type) {
    if (!element instanceof Object) return false;

    if (type === 'prev') {
      return !element.nextElementSibling ? 
        this.element.querySelector('.highlights-slider--item:first-child') : 
        element.nextElementSibling;
    } else {
      return !element.previousElementSibling ? 
        this.element.querySelector('.highlights-slider--item:last-child') : 
        element.previousElementSibling;
    }
  }

  /**
   * counter mobile
   *
   * @param {*} count
   * @param {*} type
   * @returns
   * @memberof HighlightsSlider
   */
  counterMobile(count, type) {
    if (isNaN(count)) return 0;

    let newCount = count;
    
    if (type === 'prev') {
      newCount = (count - 1) < 0 ? 5 : --count; //mudar
    } else {
      newCount = (count + 1) > 5 ? 0 : ++count; //mudar
    }

    return newCount;
  }

  /**
   * create path
   *
   * @param {*} currentType
   * @returns
   * @memberof HighlightsSlider
   */
  createPath(currentType) {
    if (this.properties instanceof Object) {
      switch (currentType) {
        case 0:
          return 'M460,32H0v115l460,28V0z';
        case 1:
          return 'M460,32H0v143l460-28V0z';
        case 2:
          return 'M460,175l-460-28v-115L460,0V175z';
        case 3:
          return 'M0,175l460-28v-115L0,0V175z';
        case 4: 
          return 'M0,32h460v143l-460-28V32z';
        case 5:
          return 'M0,32h460v115l-460,28V32z';
        default:
          return 'M460,32H0v115l460,28V32z';
      }
    }
  }

  /**
   * mobile type
   *
   * @returns
   * @memberof HighlightsSlider
   */
  mobileType() {
    return this.props.resize.width <= 640 ? true : false;
  }

  /**
   * next item
   *
   * @param {*} number
   * @param {*} total
   * @returns
   * @memberof HighlightsSlider
   */
  nextItem(number, total) {
    return (number + 1) > total ? 0 : number + 1;
  }

  /**
   * next prev
   * 
   * @param {*} type 
   */
  nextPrev(type) {
    switch (type) {
      case 'prev':
        return this.setState((prevState) => {
          return {
            current: (prevState.current - 1) < 0 ? this.state.items.length - 1 : prevState.current - 1, //mudar items
            counter: this.counterMobile(prevState.counter, type),
          };
        }, () => this.animationItems('prev'));
      case 'next':
      default:
        return this.setState((prevState) => {
          return {
            current: (prevState.current + 1) > (this.state.items.length - 1) ? 0 : prevState.current + 1, //mudar items
            counter: this.counterMobile(prevState.counter, type),
          };
        }, () => this.animationItems('next'));
    }
  }

  /**
   * loaded
   *
   * @memberof HighlightsSlider
   */
  onLoaded() {
    const items = this.element.querySelectorAll('.highlights-slider--item');

    for (let item of items) {
      this.animationItemRemoveClassName(item);
    }

    this.setState({
      limit: this.props.resize.width >= 768 ? 3 : 1,
    });
  }

  /**
   * prev item
   *
   * @param {*} number
   * @param {*} total
   * @returns
   * @memberof HighlightsSlider
   */
  prevItem(number, total) { 
    return (number - 1) < 0 ? total : number - 1;
  }

  /**
   * svg items
   *
   * @returns
   * @memberof HighlightsSlider
   */
  svgItems() {
    const items = [];

    for (let i = 0; i <= 5; i++) {
      items.push(<li className="svg" data-type={i} key={i}>
        <svg height="100%" width="100%" viewBox="0 0 460 176" preserveAspectRatio="none">
          <path className="svg--bg" d={this.createPath(i)} />
        </svg>
      </li>);
    }

    return items;
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof HighlightsSlider
   */
  render() {
    return (
      <div className="highlights-slider">
        <div className="wrapper">
          {this.state.items &&
            <ul className="highlights-slider--list">
              {this.state.items.map((item, index) => 
                <li
                  className="highlights-slider--item"
                  data-current={this.state.current === index}
                  key={index}>
                  <HighlightsItem
                    counter={this.state.counter}
                    current={this.state.current}
                    index={index} 
                    item={item}
                    total={this.state.items.length - 1} />
                </li>)}
            </ul>}
          
          <ul className="highlights-slider--bg-svg">{this.svgItems()}</ul>

          <HighlightsSliderControls nextPrev={this.nextPrev} />
        </div>
      </div>
    )
  }
}

HighlightsSlider.propTypes = {
  lista: PropTypes.array.isRequired,
  resize: PropTypes.object.isRequired,
}

export default HighlightsSlider;