import React, { useState, useEffect, useRef } from 'react';

//@ts-ignore
import Fade from 'react-reveal/Fade';
import imagesLoaded from 'imagesloaded';
import { image as buildImage } from './../../Helpers';
import { ImageProps } from './types';

import './image.scss';

type BaseImageProps = {
  imagem: ImageProps;
  texto: string;
  full?: boolean;
  clickable?: boolean;
};

function Image ({imagem, texto, full, clickable}: BaseImageProps) {
  const [ loaded, setLoaded ] = useState(false);
  const [ fullScreen, setFullScreen ] = useState(false);
  const element = useRef<HTMLDivElement|null>(null);

  useEffect(() => {
    if (element.current) {
      imagesLoaded(element.current, () => {
        console.log(imagem.focal_point);
        setLoaded(true);
      });
    }
  }, [ element, imagem.focal_point ]);

  useEffect(() => {
    if (element.current) {
      if (fullScreen) {
        element.current.classList.add('fullscreen');
      } else {
        element.current.classList.remove('fullscreen');
      }
    }
  }, [ fullScreen ]);

  return (
    <div
      className={ `image-component${ fullScreen ? ' fullscreen' : '' }` }
      ref={ element }
      data-loaded={ loaded }
      data-full={ full }
      data-clickable={ clickable || true }>
        <Fade bottom>
          <>
            { element.current &&
              <div className="wrapper">
                { texto &&
                  <div className="title-main">
                    <span>{ texto }</span>
                  </div> }
                
                { imagem &&
                  <Fade when={ loaded }>
                    <div onClick={ () => setFullScreen(!fullScreen) }>
                      { buildImage(texto, imagem) }
                    </div>
                  </Fade>}
              </div>
            }
          </>
        </Fade>
    </div>
  );
}

export default Image;