import React, { Component } from 'react';
import PropTypes from 'prop-types';

import dateFns from 'date-fns';
import axios from 'axios';

import { findDateMonth } from './../../../Helpers';

import CalendarMonth from '../CalendarMonth';


import './calendar-year.scss';

/**
 * Calendar Year
 *
 * @class CalendarYear
 * @extends {Component}
 */
class CalendarYear extends Component {
  /**
   * Creates an instance of CalendarYear.
   * 
   * @param {*} props
   * @memberof CalendarYear
   */
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  /**
   * component did update
   *
   * @param {*} prevProps
   * @memberof CalendarYear
   */
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.searchEvents(this.props.year);
    }
  }

  // should component update
  shouldComponentUpdate(prevProps, prevState) {
    if (prevProps !== this.props || prevState.items !== this.state.items) {
      return true;
    }

    return false;
  }

  /**
   * clone events
   *
   * @param {*} events
   * @returns
   * @memberof CalendarYear
   */
  cloneEvents(events) {
    if (!events || events instanceof Object === false) return false;

    const items = [];

    events.map(item => {
      if (item instanceof Object) {
        const dateInit = dateFns.parse(new Date(item['data_inicio'].replace(/-/g, '/')));
        const dateEnd = dateFns.parse(new Date(item['data_fim'].replace(/-/g, '/')));

        const days = Math.abs(dateFns.differenceInDays(dateInit, dateEnd));

        if (!isNaN(days) && days >= 0) {
          for (let i = 0; i <= days; i++) {
            const date = dateFns.addDays(dateInit, (i));

            const newItem = {
              categoria: item.categoria,
              data_inicio: dateFns.format(date, 'YYYY/MM/DD'),
              descricao: item.descricao,
              clone: true,
            };

            items.push(newItem);
          }
        }

        items.push(item);
      }

      return item;
    });

    return items;
  }

  /**
   * search events
   *
   * @param {*} year
   * @returns
   * @memberof CalendarYear
   */
  searchEvents(year) {
    if (isNaN(year) || !year) return false;
    
    axios({
      type: 'GET',
      url: this.props.url + 'evento/?limit=1000&offset=0',
    }).then(response => {
      if (response.data instanceof Object) {
        this.setState({
          items: this.cloneEvents(response.data.results),
        });
      }
    });
  }

  /**
   * render month
   *
   * @param {*} year
   * @returns
   * @memberof CalendarYear
   */
  renderMonth(year) {
    if (!year || Number.isInteger(year) === false || !this.props.categories) return false;

    const date = new Date(`01/01/${year}`);
    const dateFormat = "MMMM";
    const months = [];
    let startDate = dateFns.parse(date);

    for (let i = 0; i < 12; i++) {
      const events = findDateMonth(this.state.items, i);

      months.push(
        <CalendarMonth
          categories={this.props.categories}
          date={dateFns.addMonths(startDate, i)}
          year={year}
          events={events}
          title={dateFns.format(dateFns.addMonths(startDate, i), dateFormat)}
          index={i}
          key={i} />
      );
    }

    return <div className="calendar--year--content">{months}</div>;
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof CalendarYear
   */
  render() {
    return (
      <div className="calendar--year">
        {this.renderMonth(this.props.year)}
      </div>
    )
  }
}

CalendarYear.propTypes = {
  year: PropTypes.number,
  categories: PropTypes.array,
}

export default CalendarYear;