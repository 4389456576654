import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import { projectUrl } from './../../../Helpers';

import './list-icon.css';

/**
 * List icon
 *
 * @class ListIcon
 * @extends {Component}
 */
class ListIcon extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof ListIcon
   */
  render() {
    return (
      <div className="list-icon">
        <ul className="list-icon--list">
          {this.props.lista.map((item, index) => 
            <li className="list-icon--item" key={index}>
              <Fade duration={700} top>
                <p className="list-icon--item--info">
                  {item.icone && 
                    <Zoom duration={500}>
                      {item.icone.large &&
                        <span className="icon">
                          <img className="image" src={projectUrl + item.icone.large.src} alt={item.titulo} />
                        </span>}
                    </Zoom>}
                  <span className="title">{item.titulo}</span>
                  <span className="description">{item.resumo}</span>
                </p>
              </Fade>
            </li>)}
        </ul>
      </div>
    )
  }
}

ListIcon.propTypes = {
  lista: PropTypes.array.isRequired,
}

export default ListIcon;