import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from 'Helpers';

import './candles.scss';

type CandlesProps = {
  data: any;
  onPageChanged: (page: number) => void;
  location: string;
  setTitle: (value: string) => void;
};

export default function Candles({data, onPageChanged, setTitle}: CandlesProps) {
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ intention, setIntention ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const [ page, setPage ] = useState(1);

  async function sendCandle() {
    // let location:any;

    // if (location?.startsWith('/')) {
    //   location = location?.substring(1);
    // }

    try {
      const req = await axios({
        url: baseUrl + 'capelavirtual/velas',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          email: email,
          name: name,
          intention: intention,
        }
      });

      if (req.status === 201) setSuccess(true);
    } catch (e) {
      console.error(e);
    }
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    sendCandle();
  }

  function handlePageChange(newPage: number) {
    setPage(newPage);
    onPageChanged(newPage);
  }

  useEffect(() => {
    setTitle('');
  }, [setTitle]);

  return (
    <div className="Candles">
      <form className="candle-form forms--form" onSubmit={ handleSubmit }>
        <h1>Acenda uma vela</h1>

        <div className="forms--content">
          {
            success ?
            <span>Seu pedido foi enviado com sucesso!</span>
            :
            <>
              <div className="input-control">
                <input type="text" name="name" id="name" placeholder="Nome da pessoa" required
                  onChange={ e => setName(e.target.value) } />
              </div>

              <div className="input-control">
                <input type="email" name="email" id="email" placeholder="Endereço de e-mail"
                  onChange={ e => setEmail(e.target.value) } />
              </div>

              <div className="input-control">
                <input type="text" name="intention" id="intention" placeholder="Intenção" required
                  onChange={ e => setIntention(e.target.value) } />
              </div>

              <button type="submit" className="btn-triangle">Acender vela</button>
            </>
          }
        </div>
      </form>

      <div className="lit-candles">
        <ul>
          { data?.results.map((data: any, index: number) => {
            return (
              <li key={ index }>
                <img
                  className="candle-icon"
                  src={process.env.PUBLIC_URL + '/images/capelavirtual/candle-anim.gif'}
                  alt='Animação de vela'
                />
                <span>{ data.name }</span>
              </li>
            );
          })}

          <div className='pagination'>
            {
              data?.previous &&
              <button onClick={ () => handlePageChange(page - 1) } className='btn btn-triangle-left'>Anterior</button>
            }

            {
              data?.next &&
              <button onClick={ () => handlePageChange(page + 1) } className='btn btn-triangle-right'>Próximo</button>
            }
          </div>
        </ul>
      </div>
    </div>
  )
}