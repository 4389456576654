import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import { withRouter } from "react-router";

import "./search-menu.css";

/**
 * Search menu
 *
 * @class SearchMenu
 * @extends {Component}
 */
class SearchMenu extends Component {
  /**
   * Creates an instance of SearchMenu.
   *
   * @param {*} props
   * @memberof SearchMenu
   */
  constructor(props) {
    super(props);

    this.state = {
      input: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * component did mount
   *
   * @memberof SearchMenu
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);
  }

  /**
   * component did update
   *
   * @param {*} prevProps
   * @memberof SearchMenu
   */
  componentDidUpdate(prevProps) {
    if (prevProps.search !== this.props.search && this.props.search === true) {
      this.focusInput();
    }
  }

  /**
   * focus input
   *
   * @memberof SearchMenu
   */
  focusInput() {
    const input = this.element.querySelector(".input-default");

    if (input instanceof Object) {
      input.focus();
    }
  }

  /**
   * handle change
   *
   * @param {*} event
   * @memberof SearchMenu
   */
  handleChange(event) {
    this.setState({
      input: event.target.value,
    });
  }

  /**
   * on change
   *
   * @param {*} event
   * @returns
   * @memberof SearchMenu
   */
  onChange(event) {
    if (event.which === 13 || event.keyCode === 13) {
      return this.onSend();
    }

    return true;
  }

  /**
   * on send
   *
   * @returns
   * @memberof SearchMenu
   */
  onSend() {
    if (this.state.input !== "") {
      localStorage.setItem("csa_search", this.state.input);

      return this.props.history.push("/search?");
    }
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof SearchMenu
   */
  render() {
    return (
      <form className="search-menu" onSubmit={() => this.onSend()}>
        <label className="input-control input-icon-r">
          <input
            className="input-default"
            type="text"
            placeholder="Buscar"
            value={this.state.input}
            onBlur={(e) => {
              const modal = document.querySelector(".input-control");
              if (modal && !modal.contains(e.relatedTarget)) {
                this.props.onSearch();
              }
            }}
            onChange={this.handleChange}
            onKeyPress={(e) => this.onChange(e)}
          />

          <button type="submit" className="submit-btn">
            <span className="icon icon-search"></span>
          </button>
        </label>
      </form>
    );
  }
}

SearchMenu.propTypes = {
  search: PropTypes.any,
  onSearch: PropTypes.func,
};

export default withRouter(SearchMenu);
