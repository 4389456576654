import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { image } from './../../../Helpers';

import './type-two.css';

/**
 * Type Two History
 *
 * @class TypeTwo
 * @extends {Component}
 */
class TypeTwo extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof TypeTwo
   */
  render() {
    return (
      <div className="history-type-two">
        <div className="wrapper">
          {image(this.props.titulo, this.props.imagem)}

          {this.props.getInfo &&
            <div className="content">
              {this.props.getInfo(this.props.titulo, this.props.descricao)}
            </div>}
        </div>
      </div>
    )
  }
}

TypeTwo.propTypes = {
  imagem: PropTypes.object.isRequired,
  titulo: PropTypes.string.isRequired,
  getInfo: PropTypes.func.isRequired,
  descricao: PropTypes.string,
}

export default TypeTwo;