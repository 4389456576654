import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { projectUrl } from './../../Helpers';

/**
 * Donwload file
 *
 * @class DownloadFile
 * @extends {Component}
 */
class DownloadFile extends Component {
  /**
   * Creates an instance of DownloadFile.
   * 
   * @param {*} props
   * @memberof DownloadFile
   */
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  /**
   * download file
   *
   * @returns
   * @memberof DownloadFile
   */
  downloadFile() {
    this.setState({
      active: true,
    }, () => 
      axios({
        method: 'GET',
        url: projectUrl + this.props.url,
        responseType: 'blob',
      }).then(response => {
        if (response.data) {
          return new Blob([response.data], { type: `application/${this.props.extension}` });
        }
      }).then(blob => {
        if (!blob || !blob instanceof Object) {
          return this.setState({
            active: false
          });
        }

        this.saveBlob(blob);
      })
    );
  }

  /**
   * save blob
   *
   * @param {*} blob
   * @returns
   * @memberof DownloadFile
   */
  saveBlob(blob) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);

      return this.setState({
        active: false
      });
    }
  
    const data = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = data;
    link.download = this.props.name + this.props.extension;
    link.click();

    setTimeout(() => {
      return this.setState({
        active: false
      }, () => window.URL.revokeObjectURL(data));
    }, 100);
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof DownloadFile
   */
  render() {
    return (
      <button
        data-active={this.state.active}
        className={this.props.className}
        onClick={() => this.downloadFile()}>
        {this.props.children}
      </button>
    )
  }
}

DownloadFile.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  extension: PropTypes.string.isRequired,
}

export default DownloadFile;