import React from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';

type CepFieldProps = {
  name: string;
  required: boolean;
  onChange: (...args: any[]) => void;
};

function CepField(props: CepFieldProps) {
  async function fetchCepData(cep: string) {
    cep = cep.replaceAll('-', '');
    const url = `https://viacep.com.br/ws/${cep}/json/`;
    const req = await axios.get(url);
    console.log(req.data);
    return req.data;
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const cepExp = new RegExp('^([0-9]{5})-([0-9]{3})$' ,'g');

    if(cepExp.test(e.target.value)) {
      fetchCepData(e.target.value).then((data) => {
        const addressField = document.getElementsByName('endereco')[0] as HTMLInputElement;
        const addressTypeField = document.getElementsByName('logradouro')[0] as HTMLInputElement;
        const districtField = document.getElementsByName('bairro')[0] as HTMLInputElement;

        if (addressField) {
          addressField.value = data.logradouro;
          addressField.setAttribute('data-empty', 'false');
        }

        if (addressTypeField) {
          addressTypeField.value = data.logradouro.split(' ')[0].toLowerCase();
          console.log(data.logradouro.split(' ')[0].toLowerCase());
          addressTypeField.setAttribute('data-empty', 'false');
        }

        if (districtField) {
          districtField.value = data.bairro;
          districtField.setAttribute('data-empty', 'false');
        }
      });
    }

    props.onChange(e, 'cnpj');
  }

  return (
    <InputMask
      name={ props.name }
      required={ props.required }
      type='tel'
      data-empty='true'
      mask='99999-999'
      onChange={ handleChange }
    />
  );
};

export default CepField;