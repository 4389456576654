import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MainContext } from '../../providers/MainProvider';
import NewsList from './NewsList';

import './news.scss';

/**
 * News
 *
 * @class News
 * @extends {Component}
 */
class News extends Component {
  /**
   * render
   *
   * @returns
   * @memberof News
   */
  render() {
    return (
      <div className="news">
        <MainContext.Consumer>
          {(context) => <NewsList {...context} />}
        </MainContext.Consumer>
      </div>
    )
  }
}

News.propTypes = {
  any: PropTypes.any,
}

export default News