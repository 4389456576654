import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './calendar-categories.scss';

/**
 * Calendar Categories
 *
 * @class CalendarCategories
 * @extends {Component}
 */
class CalendarCategories extends Component {
  /**
   * render
   *
   * @returns
   * @memberof CalendarCategories
   */
  render() {
    return (
      <div className="calendar--categories">
        <div className="wrapper">
          <ul className="calendar--categories--list">
            {Array.isArray(this.props.categories) && this.props.categories.map((item, index) => 
              <li className="calendar--categories--item" data-type={item.slug} key={index}>
                <span className="block" style={{'backgroundColor': item.color}}></span>
                <p className="text">{item.nome}</p>
              </li>)}
          </ul>
        </div>  
      </div>
    )
  }
}

CalendarCategories.propTypes = {
  categories: PropTypes.array,
}

export default CalendarCategories;