import React from 'react';

type TitleProps = {
  text: string;
};

const Title = function(props: TitleProps) {
  return (
    <h1 className='title-main'>
      <span>
        { props.text }
      </span>
    </h1>
  );
};

export default Title;