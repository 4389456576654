import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { linkTypeTargetAttr, linkToAttr } from './../../Helpers';

import './link-component.css';

/**
 * link component
 *
 * @class LinkComponent
 * @extends {Component}
 */
class LinkComponent extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof Link
   */
  render() {
    return (
      <Fragment>
        {this.props.item.tipo_link === 'externo' ?
          <a
            className={this.props.className}
            target={linkTypeTargetAttr(this.props.item)}
            href={linkToAttr(this.props.item)}>{this.props.children}</a>
          : <Link
            className={this.props.className}
            to={linkToAttr(this.props.item)}>{this.props.children}</Link> }
      </Fragment>
    )
  }
}

LinkComponent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
}

export default LinkComponent;