import React, { useState, useEffect } from 'react';
//@ts-ignore
import Fade from 'react-reveal/Fade';

import './gospel.scss';

type GospelProps = {
  data: any;
  onPageChanged: (page: number) => void;
  setTitle: (value: string) => void;
};

export default function Gospel({data, onPageChanged, setTitle}: GospelProps) {
  const [ currentPage, setCurrentPage ] = useState(1);
  
  useEffect(() => {
    setTitle('Evangelho do dia');
  }, [ setTitle ]);

  useEffect(() => {
    onPageChanged(currentPage);
  }, [ currentPage ]);

  function nextPage() {
    if (currentPage + 1 <= data.count) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prevPage() {
    if (currentPage - 1 > 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  if (!data) return <></>

  return (
    <section className="Gospel">
      { data.results.length > 0 ?
        <div className='gospel-body'>
          <header className='gospel-header'>
            <div className={`pagination prev-page ${data.previous || 'disabled'}`} onClick={ data.previous && (() => prevPage()) }>
              <i className='icon icon-arrow-left' />
            </div>

            <Fade top spy={ currentPage }>
              <h2>{ data.results[0].title || '' }</h2>
            </Fade>
      
            <div className={`pagination next-page ${data.next || 'disabled'}`} onClick={ data.next && (() => nextPage()) }>
              <i className='icon icon-arrow-right' />
            </div>
          </header>
    
          <small>{ (data.results[0].date || '').split('-').reverse().join('/') }</small>

          <hr />

          <span className='gospel-body-text' dangerouslySetInnerHTML={{ __html: data.results[0].content }}></span>
        </div>
        :
        <div className='gospel-body'>
          <p>
            Ocorreu um erro ao carregar a lista de evangelhos.<br/>Tente novamente mais tarde.
          </p>
        </div>
      }
    </section>
  );
};