import React from 'react';
import ReactDOM from 'react-dom';

import "./styles.css";

const Modal = (props) => {
  return (
    <div className="modal active">
      <div className='modal-wrapper'>
        <header className='modal-header'>
          <h3 className='modal-header-title'>{props.title}</h3>
          <button className='modal-header-closeBtn' onClick={props.onClose}></button>
        </header>

        <section className='modal-content'>
          <figure>
            {props.src !== null ? (
              <img
                className='modal-content-image'
                style={{width: props.width}}
                src={props.src}
                alt={props.alt}
              />
            ) : ''}
          </figure>
        </section>
      </div>
    </div>
  )
}

const ModalOverlay = (props) => {
  return (
    <React.Fragment>
      {
        ReactDOM.createPortal(
          <Modal
            onClose={props.onClose}
            title={props.title}
            height={props.height}
            width={props.width}
            src={props.src}
            alt={props.alt}
          />,

          document.querySelector('#modal-root')
        )
      }
    </React.Fragment>
  );
};

export default ModalOverlay;
