/**
 * Swipe Events
 * @author Julian Ibarra <yeissonibarra@gmail.com>
 */
class SwipeTouch {
  /**
   * constructor
   * @param {*} element 
   * @param {*} callback 
   */
  constructor(element, callback) {
    this.swipedir = null;
    this.startX = null;
    this.startY = null;
    this.distX = null;
    this.distY = null;
    this.threshold = 100;
    this.restraint = 100;
    this.allowedTime = 300;
    this.elapsedTime = null;
    this.startTime = null;

    if (element instanceof Object || !callback || typeof callback !== 'function') {
      this._element = element;
      this._callback = callback;

      this.addEventListenerTouch();
    } else {
      throw new Error('Please checked arguments!');
    }
  }

  /**
   * event touch
   */
  addEventListenerTouch() {
    if (this.isMobile()) {
      this._element.removeEventListener('touchstart', this.addEventListenerTouchStart.bind(this), { passive: true });
      this._element.removeEventListener('touchend', this.addEventListenerTouchSlider.bind(this), { passive: true });

      this._element.addEventListener('touchstart', this.addEventListenerTouchStart.bind(this), { passive: true });
      this._element.addEventListener('touchend', this.addEventListenerTouchSlider.bind(this), { passive: true });
    }
  }

  /**
   * listener touch
   * @param {*} event 
   */
  addEventListenerTouchStart(event) {
    let touch = event.changedTouches[0];

    this.swipedir = 'none';
    this.dist = 0;
    this.startX = touch.pageX;
    this.startY = touch.pageY;
    this.startTime = new Date().getTime();
  }
  
  /**
   * event touch slider
   * @param {*} event 
   */
  addEventListenerTouchSlider(event) {
    if (!event.changedTouches) return false;

    let touch = event.changedTouches[0];

    this.distX = touch.pageX - this.startX;
    this.distY = touch.pageY - this.startY;
    this.elapsedTime = new Date().getTime() - this.startTime;

    if (this.elapsedTime <= this.allowedTime) {
      if (Math.abs(this.distX) >= this.threshold && Math.abs(this.distY) <= this.restraint) {
        this.swipedir = (this.distX < 0) ? 'left' : 'right';
      } else if (Math.abs(this.distY) >= this.threshold && Math.abs(this.distX) <= this.restraint) {
        this.swipedir = (this.distY < 0) ? 'up' : 'down';
      }
    }

    this._callback(this.swipedir);
  }

  /**
   * is mobile
   */
  isMobile () {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export default SwipeTouch;