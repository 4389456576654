import React from 'react';
import Picture from '../components/Picture';
import dateFns from 'date-fns';

/**
 * url temp
 */
const projectUrl = process.env.REACT_APP_API_URL;

/**
 * base url API 
 */
const baseUrl = `${projectUrl}/api/v2/`;

/**
 * base url FORM
 */
// const formUrl = `https://api.csa.com.br/v1/legacy/`;
const formUrl = `${projectUrl}/api/v2/formularios/`;

/** 
 * url login
*/
const loginUrl = 'https://api.csa.com.br/v1/login';

/**
 * array insert position
 */
const arrayInsertPosition = (arr, index, ...items) => arr.slice(index, 0, ...items);

/**
 * compare object
 */
const compareObj = (a, b) => {
  if (parseInt(a.mes) < parseInt(b.mes)) {
    return -1;
  }

  if (parseInt(a.mes) > parseInt(b.mes)){
    return 1;
  }
  
  return 0;
};

/**
 * find date month
 */
const findDateMonth = (items, month = null) => {
  if (items instanceof Object === false) return false;

  const events = [];

  for (const event of items) {
    if (event instanceof Object) {
      const fixDate = event['data_inicio'].replace(/-/g, '/');
      const date = new Date(fixDate);
      const format = 'MM';

      const dateMonth = parseInt(dateFns.format(dateFns.parse(date), format));

      if (dateMonth) {
        if ((month + 1) === dateMonth) {
          events.push(event);
        }
      }
    }
  }

  return events;
};

/**
 * get width height docs
 */
const getWidthHeightDoc = () => {
  return {
    height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  };
};

/**
 * image
 *
 * @param {*} alt
 * @param {*} image
 * @returns
 * @memberof TypeOne
 */
const image = (alt = '', image) => {
  if (!image) return false;

  return <Picture className="image" alt={alt} images={[{ url: projectUrl + image.large.src, media: '' }]} />;
};

/**
 * refactory images
 * 
 * @param {*} data 
 * @param {*} data 
 */
const refactoryImages = (data) => {
  const images = [];
  const resolutions = [
    {
      key: 'fill-375x367',
      resolution: '375'
    }, {
      key: 'fill-640x374',
      resolution: '640'
    }, {
      key: 'fill-768x504',
      resolution: '768'
    }, {
      key: 'fill-1100x454',
      resolution: '1024'
    }, {
      key: 'fill-1920x845',
      resolution: '1920'
    }
  ];

  for (let resolution of resolutions) {
    const image = data[resolution.key];

    if (image instanceof Object) {
      images.push({
        media: `(max-width: ${resolution.resolution}px)`,
        url: projectUrl + image.src,
        key: resolution.key
      });
    }
  }

  return images;
};

/**
 * is mobile
 */
const isMobile = () => {
  const vendor = navigator.userAgent || navigator.vendor || window.opera;

  return !!(
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      vendor
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      vendor.substr(0, 4)
    )
  );
};

/**
 * refactory image header
 * 
 * @param data
 */
const reactoryImagesHeader = (data) => {
  const images = [];

  for (const key in data) {
    const image = data[key];

    if (image instanceof Object) {
      images.push({
        media: `(max-width: ${image.resolution}px)`,
        url: projectUrl + image.url,
        key
      });
    }
  }

  return images.reverse();
};

/**
 * link type blank attribute
 * 
 * @param {*} link 
 */
const linkTypeTargetAttr = (link) => {
  if (!link || !link instanceof Object) return false;

  return link.tipo_link === 'externo' ? '_blank' : '_self';
};

/**
 * link type select
 * 
 * @param {*} link 
 */
const linkToAttr = (item) => {
  if (!item || !item instanceof Object) return false;

  if (item.tipo_link === 'externo') {
    return item.link_externo;
  }

  let linkInterno = item.link_interno;
  let link = '';
  if ((item.tipo_link === 'interno' && linkInterno && linkInterno.url) || (linkInterno && linkInterno.url)) {
    link = linkInterno.url.slice(-1) === '/' ?
      linkInterno.url.slice(0, linkInterno.url.length-1) :
      linkInterno.url;
  }

  return link;
}

/**
 * is auth
 */
const isAuth = () => {
  if (getToken() !== null) {
    return true;
  }

  return false;
};

/**
 * get token
 */
const getToken = () => {
  return JSON.parse(localStorage.getItem('csa_token'));
};

/**
 * set token
 */
const setToken = data => {
  const jsonData = JSON.stringify(data);

  localStorage.setItem('csa_token', jsonData);
};

export { isAuth, getToken, setToken, arrayInsertPosition, baseUrl, formUrl, loginUrl, compareObj, findDateMonth, getWidthHeightDoc, image, isMobile, linkTypeTargetAttr, linkToAttr, refactoryImages, reactoryImagesHeader, projectUrl };