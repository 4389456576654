import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';

import TextOut from './TextOut';
import TypeEight from './TypeEight';
import TypeFive from './TypeFive';
import TypeFour from './TypeFour';
import TypeOne from './TypeOne';
import TypeSeven from './TypeSeven';
import TypeSix from './TypeSix';
import TypeThree from './TypeThree';
import TypeTwo from './TypeTwo';

import './history.css';

/**
 * History
 *
 * @class History
 * @extends {Component}
 */
class History extends Component {
  /**
   * Creates an instance of History.
   *
   * @param {*} props
   * @memberof History
   */
  constructor(props) {
    super(props);
    
    this.getInfo = this.getInfo.bind(this);
  }

  /**
   * get info
   *
   * @param {*} title
   * @param {*} description
   * @returns
   * @memberof History
   */
  getInfo(title = '', description = '') {
    return <TextOut resize={this.props.resize}>
      <p className="info">
        <span className="title">{title}</span>
        <span className="description">{description}</span>
      </p>
    </TextOut>;
  }

  /**
   * type
   *
   * @param {*} item
   * @returns
   * @memberof History
   */
  type (type) {
    if (!type) return false;

    switch (type) {
      case 'historia_1':
        return <TypeOne {...this.props} getInfo={this.getInfo} />;
      case 'historia_2':
        return <TypeTwo {...this.props} getInfo={this.getInfo} />;
      case 'historia_3':
        return <TypeThree {...this.props} getInfo={this.getInfo} />;
      case 'historia_4':
        return <TypeFour {...this.props} getInfo={this.getInfo} />;
      case 'historia_5':
        return <TypeFive {...this.props} getInfo={this.getInfo} />;
      case 'historia_6':
        return <TypeSix {...this.props} getInfo={this.getInfo} />;
      case 'historia_7':
        return <TypeSeven {...this.props} getInfo={this.getInfo} />;
      case 'historia_8':
        return <TypeEight {...this.props} getInfo={this.getInfo} />;
      default:
        return false;
    }
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof History
   */
  render() {
    return (
      <div className="history">
        <div className="history--item">
          <Fade bottom duration={900}>{this.type(this.props.type)}</Fade>
        </div>
      </div>
    )
  }
}

History.propTypes = {
  resize: PropTypes.object.isRequired,
}

export default History;