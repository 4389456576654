import React from 'react';
import InputMask from 'react-input-mask';
import { FieldProps } from './Field';

interface PhoneProps extends FieldProps {};

function PhoneField(props: PhoneProps) {
  return (
    <InputMask
      name={ props.name }
      required={ props.required }
      type="tel"
      data-empty="true"
      mask="99 999999999"
      maskChar=" "
      onChange={(e: any) => props.onChange(e, 'phone')}
    />
  );
};

export default PhoneField;