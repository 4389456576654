import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './text-out.css';

/**
 * Text out
 *
 * @class TextOut
 * @extends {Component}
 */
class TextOut extends Component {
  /**
   * state
   *
   * @memberof TextOut
   */
  state = {
    points: false,
  }
  
  /**
   * component did mount
   *
   * @memberof TextOut
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);
    this.checkHeight();
  }

  /**
   *
   *
   * @param {*} prevProps
   * @memberof TextOut
   */
  componentDidUpdate(prevProps) {
    if (prevProps.resize !== this.props.resize) {
      this.checkHeight();
    }
  }

  /**
   * check height
   *
   * @memberof TextOut
   */
  checkHeight() {
    const parentProps = this.element.parentElement.getBoundingClientRect();
    const elementHeight = this.getHeightText();

    if (parentProps instanceof Object) {
      this.setState({
        points: parentProps.height < (elementHeight + 60) ? true : false,
      });
    }
  }

  /**
   * get height text
   *
   * @returns
   * @memberof TextOut
   */
  getHeightText() {
    let height = 0;
    const description = this.element.querySelector('.info');

    if (description instanceof Object) {
      description.style.overflow = 'none';
      description.style.height = 'auto';
      description.style.maxHeight = 'none';

      height = description.scrollHeight;
      description.removeAttribute('style');
    }

    return height;
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof TextOut
   */
  render() {
    return (
      <span className="text-out" data-start={this.state.points}>
        {this.props.children}
        {this.state.points && <span className="text-out--points"></span>}
      </span>
    )
  }
}

TextOut.propTypes = {
  resize: PropTypes.any,
}

export default TextOut;