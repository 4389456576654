import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import anime from 'animejs';

import { getWidthHeightDoc, isMobile } from './../../Helpers';

import HeaderIconHamburguer from './HeaderIconHamburguer';
import HeaderMenu from './HeaderMenu';
import HeaderTop from './HeaderTop';

import './header.css';

/**
 * Header
 *
 * @class Header
 * @extends {Component}
 */
class Header extends Component {
  /**
   * Creates an instance of Header.
   * 
   * @param {*} props
   * @memberof Header
   */
  constructor(props) {
    super(props);

    this.state = {
      home: null,
      menu: [],
      mobile: false,
      toggle: false,
      mobileScroll: false,
    };
  }

  /**
   * component did mount
   *
   * @memberof Header
   */
  componentDidMount() {
    this.element = ReactDOM.findDOMNode(this);
  }

  /**
   * component did update
   *
   * @param {*} prevProps
   * @param {*} prevState
   * @memberof Header
   */
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.menu && prevProps.menu !== this.props.menu)
      || prevProps.resize !== this.props.resize) {
      this.createOrderItems();
    }

    if (prevState.toggle !== this.state.toggle) {
      this.animateMobile();
    }

    if (isMobile() === true) {
      if (prevProps.top !== this.props.top) {
        this.setState({
          mobileScroll: this.props.top > 40,
        });
      }
    }

    if (prevProps.location !== this.props.location) {
      this.handleLocationChange();
    }
  }

  /**
   * animate mobile
   */
  animateMobile() {
    if (getWidthHeightDoc().width <= 960) {
      const logo = this.element.querySelector('.header--link-home');

      anime({
        targets: logo,
        opacity: this.state.toggle ? 0 : 1,
        top: this.state.toggle ? 50 : 10,
        delay: 200,
        elasticity: 0.4,
        duration: 500
      });
    }
  }

  /**
   * create order items, desktop
   *
   * @returns
   * @memberof Header
   */
  createOrderItems() {
    if (!this.props.menu.items) return false;

    if (getWidthHeightDoc().width >= 960) {
      this.setState({
        menu: this.divideMenu(this.props.menu.items, (this.props.menu.meta.total_count / 2)),
        mobile: false,
      });

      this.removeAttrStyleLogo();
    } else {
      this.setState({ 
        menu: this.menu(this.props.menu.items),
        mobile: true,
      });
    }
  }

  /**
   * divide menu per middle
   *
   * @param {*} array
   * @param {*} perChunk
   * @returns
   * @memberof Header
   */
  divideMenu(array, perChunk) {
    return array.reduce((result, item, index) => { 
      const chunkIndex = Math.floor(index/perChunk)
            
      if(!result[chunkIndex]) {
        result[chunkIndex] = [];
      }
    
      if (item.meta.slug === 'home') {
        this.setState({
          home: item
        });
      } else {
        result[chunkIndex].push(item);
      }

      // FIXME: Dirty fix to receive new menu item (Pastoral)
      // This should be handled in back-end
      if (result.length > 1 && result[0].length > 3 && chunkIndex === 2) {
        result[1].push(result[0][result[0].length - 1]);
        result[0].pop();
      }
    
      return result;
    }, []);
  }

  /**
   * handle location change
   *
   * @param {*} location
   * @memberof Header
   */
  handleLocationChange() {
    if (document.body.clientWidth < 959) {
      this.setState({
        toggle: false,
      }, () => {
        document.documentElement.classList.remove('no-scroll');
        window.scrollTo(0, 0);
      });
    }
  }

  /**
   * menu items
   *
   * @param {*} items
   * @returns
   * @memberof Header
   */
  menu(items) {
    let orderMenu = [];

    for (let key in items) {
      const item = items[key];

      if (item.meta) {
        if (item.meta.slug === 'home') {
          this.setState({
            home: item
          });
        } else {
          orderMenu.push(item);
        }
      }
    }

    return orderMenu;
  }

  /**
   * remove attr style logo, mobile
   *
   * @memberof Header
   */
  removeAttrStyleLogo() {
    const logo = this.element.querySelector('.header--link-home');

    if (logo instanceof Object) {
      logo.removeAttribute('style');
    }
  }

  /**
   * toggle menu
   *
   * @memberof Header
   */
  toggleMenu() {
    this.setState(prevState => {
      return {
        toggle: !prevState.toggle
      };
    }, () => {
      if (this.state.toggle === true) {
        document.documentElement.classList.add('no-scroll');
      } else {
        document.documentElement.classList.remove('no-scroll');
      }

      this.animateMobile();
    });
  }

  /**
   * render
   *
   * @returns JSX
   * @memberof Header
   */
  render() {
    return (
      <header
        className="header"
        data-open={this.state.toggle}
        data-scroll={this.state.mobileScroll}>

        <HeaderTop
          onSearch={this.props.onSearch}
          submenu={this.props.submenu}
          resize={this.props.resize} />

        <div className="wrapper">
          <HeaderMenu
            menu={this.state.mobile ? this.state.menu : this.state.menu.slice(0, 2)}
            submenu={this.props.submenu}
            mobile={this.state.mobile}
            resize={this.props.resize}
            onSearch={this.props.onSearch} />

          {this.state.home &&
            <Link className="header--link-home" to={'/'}>
              <img src={process.env.PUBLIC_URL + '/images/logo-CSA.svg'} alt={'CSA'} />
              <span className="name">Leblon</span>
              <span className="name">CSA - Leblon</span>
            </Link>}

          {this.props.resize.width < 960 &&
            <button
              className="header--toggle"
              onClick={(e) => this.toggleMenu(e)}
              data-toggle={this.state.toggle}>
              <HeaderIconHamburguer />
            </button>}
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  location: PropTypes.any,
  menu: PropTypes.any.isRequired,
  submenu: PropTypes.array.isRequired,
  resize: PropTypes.object.isRequired,
  search: PropTypes.string,
  onSearch: PropTypes.func,
}

export default Header;