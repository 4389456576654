import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CoordinationItem from './CoordinationItem';

import './coordination.css';

/**
 * Coordination
 *
 * @class Coordination
 * @extends {Component}
 */
class Coordination extends Component {
  /**
   * render
   *
   * @returns JSX
   * @memberof Coordination
   */
  render() {
    return (
      <div className="coordination">
        <div className="wrapper">
          <h1 className="title-main default">
            <span>{this.props.titulo}</span>
          </h1>

          {this.props.lista &&
            <ul className="coordination--list">
              {this.props.lista.map((item, index) => <li className="coordination--item" key={index}>
                <CoordinationItem {...item} />
              </li>)}
            </ul>}
        </div>
      </div>
    )
  }
}

Coordination.propTypes = {
  any: PropTypes.any,
  titulo: PropTypes.string.isRequired,
}

export default Coordination;